import "./reviewContainer.scss";
import {
  Avatar,
  Button,
  Icon,
  InputInLabel,
  Toggle,
  RatingStars,
} from "@/components/ui";

import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { makeRequest } from "@/services/makeRequest";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { useForm } from "react-hook-form";

import moment from "moment";
import useLadyService from "@/services/LadyService";
import showToast from "../../../toast/Toast";
import { setComplainReviewsId } from "@/stores/slices/reviewsSlice";
import {UserBadge} from "../../../ui";

const InputForm = ({ className, answer, reviewId }) => {
  const [vis, setVis] = useState(!!answer);

  const { t } = useLadyService();

  const formSchema = object().shape({
    [`answer${reviewId}`]: string().required("надо"),
  });

  const { register, handleSubmit, setValue } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const onSubmit = (data, e) => {
    e.preventDefault();

    const answer = data[`answer${reviewId}`];

    const fetchData = async () => {
      try {
        const route = `reviews/answer/${reviewId}`;
        const method = "POST";
        const payload = {
          answer,
        };

        const result = await makeRequest({ route, method, payload });

        if (result.message === "answer_added") {
          setVis(true);
          showToast({
            message: t("success"),
            variant: "success",
          });
        }
      } catch (res) {
        showToast({
          message: t("oops"),
          variant: "error",
        });
      }
    };

    fetchData();
  };

  useEffect(() => {
    setValue(`answer${reviewId}`, answer);
  }, [answer, setValue, reviewId]);

  return !vis ? (
    <form
      className={className}
      onClick={(e) => e.stopPropagation()}
      onSubmit={handleSubmit(onSubmit, reviewId)}
    >
      <InputInLabel
        type={"input"}
        id={`answer${reviewId}`}
        placeholder={t("youranswer")}
        register={{ ...register(`answer${reviewId}`) }}
      >
        {t("youranswer")}
      </InputInLabel>
      <Button
        size={"l"}
        clazz="button--secondary"
        buttonType={"submit"}
        square={true}
      >
        <Icon size={"l"} spritePath={"send"} />
      </Button>
    </form>
  ) : (
    <form className={className}>
      <InputInLabel
        type={"input"}
        id={`answer${reviewId}`}
        placeholder={t("youranswer")}
        value={answer}
        clazz={"event-none"}
      >
        {t("youranswer")}
      </InputInLabel>
    </form>
  );
};

const ReviewContainer = ({ data }) => {
  const { t, lang, dispatch, setMediaSize } = useLadyService();

  const {
    body,
    from,
    profile,
    answer,
    created_at,
    score,
    score_charm,
    score_quality,
    score_communication,
    score_emotions,
    score_atmosphere,
    id: reviewId,
    reviewable_type,
    required,
    is_approved,
  } = data;

  const { name: nameFrom, rating: ratingFrom, tariff = {} } = from;

  const { data: tariffData } = tariff;

  const tariffFrom = !!tariffData?.length ? tariffData[0].slug : "";

  const {
    name: nameProfile,
    photos: photoProfile,
    slug: slugProfile,
  } = profile;

  const statuses = [
    { name: t("qualityservices"), value: score_quality },
    { name: t("atmosphere"), value: score_atmosphere },
    { name: t("communication"), value: score_communication },
    { name: t("attractiveness"), value: score_charm },
    { name: t("sensibility"), value: score_emotions },
  ];

  const createdMoment = moment.unix(created_at).format("DD.MM.YYYY HH:mm");

  const [status, setStatus] = useState(!!is_approved);

  const onChangeStatus = async () => {
    try {
      const route = `user/review-status/${reviewId}`;

      const method = "PUT";
      const payload = {
        status: !status,
      };
      setStatus((prev) => !prev);

      await makeRequest({ route, method, payload });

      showToast({
        message: t("success"),
        variant: "success",
      });
    } catch (error) {
      setStatus((prev) => !prev);
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  const dis = !status ? " disabled-global" : "";

  const type = {
    videoCall: "videochat",
    booking: "onlineorder",
  };

  return (
    <div className={`review__root`}>
      <div className="review__root__info">
        <div className="review__root__main">
          <div className="review__root__head">
            <Toggle
              words
              checked={status}
              disabled={required}
              onChange={onChangeStatus}
            />
            <div className="review__root__user__block">
              <UserBadge
                props={{
                  slug: tariffFrom,
                  name: nameFrom,
                  score: ratingFrom
                }}
              />

              <span className="p3 color-main">{createdMoment}</span>
            </div>
          </div>
          <span className={`p2 color-main${dis}`}>{body}</span>

          <InputForm
            className={`review__root__form__pc${dis}`}
            answer={answer?.body}
            reviewId={reviewId}
          />
        </div>
        <div className={`review__root__ratings${dis}`}>
          <div className="review__root__rating__main color-main">
            <span>{t("allrating")}</span>
            <span className="p1">{score}</span>
            <RatingStars value={score} readOnly color={'green'} />
          </div>

          {statuses.map((status) => {
            const { name, value } = status;

            return (
              <div key={name} className="p3 review__root__rating color-main">
                <span className="w-mc">{name}</span>
                <RatingStars value={value} readOnly color={'green'} />
              </div>
            );
          })}
        </div>

        <InputForm
          className={"review__root__form__mobile"}
          answer={answer?.body}
          reviewId={reviewId}
        />
        {profile.slug ? (<div className={dis}>
          <Link
              to={`/${lang}/profile/${slugProfile}`}
              className="res__ad hover-line"
              alt="ad"
          >
            <img src={setMediaSize(photoProfile?.[0], "xs")} className="res__ad__photo" alt="ad" />
            <span className="p1 mt-8 res__ad__title color-green">
              {nameProfile}
            </span>
          </Link>
        </div>) : (<></>)}
      </div>

      {!!required && profile && (
        <span>
          {t("reviewforpubl")} {t(type[reviewable_type])}{" "}
          <Link to="/lk/support" className="green-reverse" onClick={() => {
            dispatch(setComplainReviewsId({
              slug: slugProfile,
              img: photoProfile?.[0],
              name: nameProfile
            }))
            }}>
            {t("complain")}
          </Link>
        </span>
      )}
    </div>
  );
};

export default ReviewContainer;
