const BackgroundLegend = ({ periods }) => {
    return (
        <div style={{ marginTop: "20px" }} className={"d-flex flex-wrap gap-8"}>
            {periods.map((period, index) => (
                <div
                    key={index}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "5px",
                    }}
                >
                    <span
                        style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: period.color,
                            display: "inline-block",
                            marginRight: "10px",
                        }}
                    ></span>
                    {period.label}
                </div>
            ))}
        </div>
    );
};

export default BackgroundLegend;
