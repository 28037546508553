import {InputInLabel, Button, Loader} from "@/components/ui";
import {useState, useEffect} from "react";
import showToast from "../../../../toast/Toast";
import {makeRequest} from "@/services/makeRequest";
import _ from "lodash";
import {yupResolver} from "@hookform/resolvers/yup";
import {object, string} from "yup";
import {useForm} from "react-hook-form";
import useLadyService from "@/services/LadyService";
import AddressRow from "../addressRow/AddressRow";
import {convertData} from "@/helper/convertData";
import userProfileService from "../../../../../services/UserProfileService";

import "./infoForm.scss";
import useCheckAccess from "../../../../../hooks/useCheckAccess";

const EmailLabel = () => {
    const {t, emailVerifyAt} = useLadyService();

    return !emailVerifyAt ? (
        <span className="error">Email - {t("notconfirmed")}</span>
    ) : (
        <span>Email</span>
    );
};

const InfoForm = () => {
    const {t, userTypePhoto, userTypeCustomer} = useLadyService();
    const {
        citiesList,
        emailStatus,
        telegramStatus,
        phoneStatus,
        nameStatus,
        whatsappStatus,
        longitudeStatus,
        latitudeStatus,
        cityIdStatus,
        cityStatus,
        addressStatus,
    } = userProfileService();

    const resData = {
        emailStatus,
        whatsappStatus,
        telegramStatus,
        ...(userTypeCustomer && {nameStatus}),
        address: addressStatus,
    };

    const [valueLoad, setValueLoad] = useState(false);

    const [whatsappData, setWhatsappData] = useState(whatsappStatus);

    const [isActive, setIsActive] = useState(false);
    const [isActiveAddress, setIsActiveAddress] = useState(false);

    const citiesOptions = convertData(citiesList);
    const [activeAddress, setActiveAddress] = useState(0);
    const [formCity, setFormCity] = useState({
        id: "",
        title: "",
        icon: "",
    });

    const [bounds, setBounds] = useState("");

    const [currentActive, setCurrentActive] = useState("");
    const [activeCountry, setActiveCountry] = useState("cz");

    const [mapMarker, setMapMarker] = useState({
        lat: latitudeStatus || null,
        lng: longitudeStatus || null,
    });

    const formSchema = object().shape({
        email: string().email().required(t("emailisnes")),
    });

    const {register, handleSubmit, setValue, getValues} = useForm({
        mode: "onSubmit",
        resolver: yupResolver(formSchema),
    });
    const [forms, setForms] = useState([
        {id: null, city: {}, address: ""},
    ]);

    const handleAddForm = () => {
        setForms([...forms, {id: Date.now(), city: "", address: ""}]);
    };

    const handleRemoveForm = (id) => {
        setForms(forms.filter((form) => form.id !== id));
    };

    const handleChange = (id, name, value) => {
        setForms(
            forms.map((form) => (form.id === id ? {...form, [name]: value} : form))
        );
    };

    useEffect(() => {
        if (cityStatus) {
            setForms(
                forms.map((item) =>
                    ({...item, address: addressStatus, city: cityStatus})
                )
            );
        }
    }, [cityStatus]);

    const onSubmit = (data, e) => {
        e.preventDefault();

        const {email: newEmail, telegram: newTelegram, name: newName} = data;

        const equalData = {
            email: newEmail,
            whatsapp: Number(String(whatsappData).replace(/[" "+]/g, "")),
            telegram: newTelegram,
            ...(userTypeCustomer && {name: newName}),
            address: forms[activeAddress].address,
        };

        const isFilesChanged = !_.isEqual(resData, equalData);

        const fetchData = async () => {
            try {
                const route = `user`;
                const method = "PUT";
                const payload = {
                    email: newEmail,
                    whatsapp: String(whatsappData).replace(/[" "+]/g, ""),
                    telegram: newTelegram,
                    phoneStatus,
                    ...(userTypeCustomer && {name: newName}),
                    latitude: bounds.lat,
                    longitude: bounds.lng,
                    city_id: currentActive,
                };

                // Don't show toast if response is not true

                makeRequest({route, method, payload}).then((res) => {
                    setFormState({
                        changedName: false,
                        changedEmail: false,
                        changedTelegram: false,
                        changedWhatsapp: false,
                    });
                    showToast({
                        message: t("success"),
                        variant: "success",
                    });
                }).catch((error) => {
                    console.log('error', error)
                    showToast({
                        message: t("oops"),
                        variant: "error",
                    });
                })


            } catch (error) {
                const res = error.response;
                switch (res.status) {
                    case 401: {
                        showToast({
                            message: t("noauth"),
                            variant: "error",
                        });
                        break;
                    }
                    case 422: {
                        showToast({
                            message: t("oops"),
                            variant: "error",
                        });
                        break;
                    }
                    case 503: {
                        showToast({
                            message: t("oops"),
                            variant: "error",
                        });
                        break;
                    }
                }
            }
        };

      fetchData().then(r => {

      }).catch(() => {

      })
    };
    const [selectedCity, setSelectedCity] = useState(
        !!cityStatus && !!citiesOptions?.length
            ? citiesOptions
                .find((item) => activeCountry in item)
                [activeCountry].cities.filter((item) => {
                return item?.name === cityStatus || item.id === cityStatus;
            })[0]?.name
            : ""
    );

    useEffect(() => {
        setValueLoad(false);
        setFormCity({
            id: cityIdStatus,
            title: cityStatus,
            icon: activeCountry,
            name: cityStatus,
        });
        setValue("name", nameStatus);
        setValue("email", emailStatus);
        setValue("telegram", telegramStatus);
        setValue("whatsapp", whatsappData);
        setValueLoad(true);
    }, [emailStatus, setValue, telegramStatus, whatsappData, nameStatus, cityIdStatus, cityStatus]);

    useEffect(() => {
        if (!!citiesOptions?.length) {
            const filteredCity = citiesOptions
                .find((item) => activeCountry in item)
                [activeCountry].cities.filter(
                (city) => city?.name === city || city.id === city
            );
            if (filteredCity[0]) {
                setFormCity({...filteredCity[0], icon: activeCountry});
            }
        }
    }, []);

    const [formState, setFormState] = useState({
        changedName: false,
        changedEmail: false,
        changedTelegram: false,
        changedWhatsapp: false,
    });

    const updateChangeState = (field, value, originalValue) => {
        setFormState((prevState) => ({
            ...prevState,
            [field]: value !== originalValue,
        }));
    };

    const handleChangeName = (e) => updateChangeState("changedName", e.target.value, nameStatus);
    const handleChangeEmail = (e) => updateChangeState("changedEmail", e.target.value, emailStatus);
    const handleChangeTelegram = (e) => updateChangeState("changedTelegram", e.target.value, telegramStatus);
    const handleChangeWhatsapp = (e) => updateChangeState("changedWhatsapp", e.target.value, whatsappStatus);

    const isFormChanged = Object.values(formState).some((changed) => changed);

    return (
        <>
            <form
                onClick={(e) => e.stopPropagation()}
                onSubmit={handleSubmit(onSubmit)}
                className={`info-form`}
            >
                <div
                    className={`info-form__grid${(userTypeCustomer || userTypePhoto) ? ' info-form__grid--four' : ''}`}>
                    {(userTypeCustomer || userTypePhoto) && (
                        <InputInLabel
                            type={"text"}
                            id={"name_id"}
                            onChange={(e) => handleChangeName(e)}
                            placeholder={t("names")}
                            register={{...register("name")}}
                        >
                            {t("names")} ({t("nick")})
                        </InputInLabel>
                    )}

                    <InputInLabel
                        type={"email"}
                        id={"email"}
                        placeholder={"Email"}
                        onChange={(e) => handleChangeEmail(e)}
                        register={{...register("email")}}
                    >
                        <EmailLabel/>
                    </InputInLabel>

                    <InputInLabel
                        type={"text"}
                        id={"userTelegram"}
                        placeholder={"Telegram"}
                        onChange={(e) => handleChangeTelegram(e)}
                        register={{...register("telegram")}}
                    >
                        Telegram
                    </InputInLabel>

                    <InputInLabel
                        type={"tel"}
                        id={"whatsappPhone"}
                        placeholder={t("phonenumber")}
                        onChange={(e) => handleChangeWhatsapp(e)}
                        register={{...register("whatsapp")}}
                        maskValue={whatsappData}
                        setMaskValue={setWhatsappData}
                        valueLoad={valueLoad}
                    >
                        {t("whatsapp")}
                    </InputInLabel>
                </div>

                <Button
                    size={"s"}
                    disabled={!isFormChanged}
                    buttonType={"submit"}
                    clazz={"button_outline--green-accent justify-center w-160 ml-auto"}
                >
                    {t("save")}
                </Button>

                <hr className={'mt-12 mb-12'}/>

                <AddressRow
                    phone={phoneStatus}
                    email={getValues("email")}
                    mapMarker={mapMarker}
                    setMapMarker={setMapMarker}
                    onRemove={handleRemoveForm}
                    onChange={handleChange}
                    onAdd={handleAddForm}
                    addresses={forms}
                    setAddresses={setForms}
                    setIsActive={setIsActiveAddress}
                    setActiveAddress={setActiveAddress}
                    activeAddress={activeAddress}
                    activeCountry={activeCountry}
                    setActiveCountry={setActiveCountry}
                    setBounds={setBounds}
                    bounds={bounds}
                    currentActive={currentActive}
                    setCurrentActive={setCurrentActive}
                    setSelectedCity={setSelectedCity}
                    selectedCity={selectedCity}
                    setFormCity={setFormCity}
                    formCity={formCity}
                    cities={citiesList}
                    isActive={isActiveAddress}
                />
            </form>
        </>
    );
};

export default InfoForm;
