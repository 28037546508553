import axios from "axios";
import useLadyService from "./LadyService";

const useHandleLocationClick = () => {
    const {lang} = useLadyService();

    const handleLocationClick = () => {
        return new Promise((resolve, reject) => {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    async (position) => {
                        const {latitude, longitude} = position.coords;
                        try {
                            const response = await axios.get(
                                `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1&accept-language=${lang}`
                            );


                            const {
                                city: cityRes,
                                road,
                                house_number,
                                town,
                            } = response.data.address;

                            const city = cityRes || town;
                            resolve({city, road, house_number, latitude, longitude});
                        } catch (error) {
                            reject(error);
                        }
                    },
                    (error) => {
                        reject(error);
                    }
                );
            } else {
                reject(new Error("Geolocation is not supported by this browser."));
            }
        });
    };

    return handleLocationClick;
};

export default useHandleLocationClick;
