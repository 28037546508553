import "./balancePage.scss";

import { Checkbox, Dropdown, DateInput, Icon } from "@/components/ui";
import { AdBalancePopup } from "../../popups";
import { WithdrawBalancePopup } from "../../popups";
import { useState, useEffect } from "react";
import { makeRequest } from "@/services/makeRequest";
import { ButtonLink, Loader } from "../../ui";
import useLadyService from "@/services/LadyService";
import Pagination from "../../pagination/Pagination";
import {Link, useLocation} from "react-router-dom";
import { useSelector } from "react-redux";
import { getUserBalance, getIncomingTransaction  } from "@/stores/slices/userSlice";
import moment from "moment";
import { addSpacesEveryThreeDigits } from "@/helper/addSpacesEveryThreeDigits";
import DeactivateRefillPopup from "@/components/popups/deactivateRefill/DeactivateRefillPopup";
import { getUserHold } from "@/stores/slices/userSlice";
import { wordEndings } from "@/helper/wordEndings";
import { roundSecondsToMinutes } from "@/helper/roundSecondsToMinutes";
import useTitle from "@/hooks/useTitle";
import useCheckAccess from "../../../hooks/useCheckAccess";
import SearchBlock from "./components/SearchBlock";

const BalancePage = () => {
  const { t, token, navigate, windowWidth, lang, scrollToTop, setMediaSize } =
    useLadyService();
  const isTablet = windowWidth < 891;
  const isMobile = windowWidth < 767;
  const isExtraMobile = windowWidth < 479;

  const {hasAccess, AccessTitle} = useCheckAccess('balancemanage')

  const tabs = [
    { id: 1, name: t("transactionshistory") },
    { id: 2, name: t("inqueue") },
  ];

  const [tubValue, setTubValue] = useState(1);

  const [isOpenAd, setIsOpenAd] = useState(false);

  const [activeTabId, setActiveTabId] = useState(1);

  const [isOpenWithDraw, setIsOpenWithDraw] = useState(false);

  const { hash } = useLocation();

  useEffect(() => {
    let str = hash;
    str = str.replace("#", "");
    if (tubValue === 1) {
      setPage(Number(str) === 0 ? 1 : Number(str));
    }
  }, [hash, tubValue]);

  const handleTabClick = (tabId) => {
    setPage(1);
    if (tabId === 2) {
      setPagesList([]);
    }
    setActiveTabId(tabId);
    setTubValue(tabId);
  };

  const replaceMethodText = (str) => {
    if (str === "paypal") {
      return `PayPal`;
    }
    if (str === "invoice") {
      return `${t("toacc") + " " + t("inbank")}`;
    }
    if (str === "confirmo") {
      return t("i-crypto");
    }
    if (str === "eml") {
      return `${t("bycard") + " " + t("online")}`;
    }

    return str;
  };

  const options_balance = [
    {
      id: 1,
      title: t("topup"),
      name: t("topup"),
      value: "replenishment",
    },
    {
      id: 2,
      title: t("drawals"),
      name: t("drawals"),
      value: "withdrawal",
    },
    {
      id: 3,
      title: t("income"),
      name: t("income"),
      value: "income",
    },
    {
      id: 4,
      title: t("expenses"),
      name: t("expenses"),
      value: "outcome",
    },
  ];
  function formatDate(date) {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = String(date.getFullYear());

    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    if (year < 10) {
      year = "0" + year;
    }

    return `${day}.${month}.${year}`;
  }
  const currentDate = new Date();

  const formattedCurrentDate = formatDate(currentDate);
  const previousMonthDate = new Date();
  previousMonthDate.setMonth(currentDate.getMonth() - 1);
  const formattedPreviousMonthDate = formatDate(previousMonthDate);
  const [optionsBalanceIndex, setOptionsBalanceIndex] = useState([]);

  const [date, setDate] = useState(
    formattedPreviousMonthDate + " - " + formattedCurrentDate
  );


  const WrapperInfo = ({ children }) => {
    return isMobile ? (
      <div className="d-flex gap-4 w-100 date-dropdown">{children}</div>
    ) : (
      children
    );
  };

  const userBalance = useSelector(getUserBalance);

  const [data, setData] = useState([]);
  const [dataTurn, setDataTurn] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectName, setSelectName] = useState({
    name: "",
    slug: "",
  });

  const [selectSearch, setSelectSearch] = useState("");
  const [selectData, setSelectData] = useState([]);
  const [autoMonthPay, setAutoMonthPay] = useState(0);

  const DataBlock = ({ elems, turn }) => {
    if (elems.length === 0 && !isLoading) {
      return <h3 className="text-center mt-12">{t("emptyrecord")}</h3>;
    }
    if (elems.length === 0 && isLoading) {
      return <Loader />;
    }
    if (!!elems.length && !isLoading) {
      return elems.map((item) => {
        return (
          <div className="res__root balance mb-16">
            <div
              className={`d-flex fd-column ${isMobile ? "gap-4" : "gap-16"}`}
            >
              <span className="p3">
                {moment.unix(item.created_at).format("DD.MM.YYYY HH:mm")}
              </span>
              <span className="p1 color-main">
                {t(item.description)}
                {item.info?.duration ? ` ${roundSecondsToMinutes(item.info.duration)} ` + wordEndings(roundSecondsToMinutes(item.info.duration), [t('s-minit'), t('s-minits'), t('minit')]) : ""}
              </span>
            </div>
            {
              <div className="res__ad fd-row gap-12">
                {turn && item.info?.data && (
                  <div className="d-flex fd-column">
                    {item.info?.method && (
                      <p className="fz-13 color-main">
                        {replaceMethodText(item.info?.method)}
                      </p>
                    )}
                    <div className="d-flex gap-12 align-center">
                      {item.info?.amount && (
                        <p className="color-main">
                          {t("amountwithdraw") + ": "}
                          {item.info?.amount +
                            `${
                              item.info?.currency === "eur"
                                ? " €"
                                : item.info?.currency === "czk"
                                ? " Kč"
                                : item.info?.currency === "usdt"
                                ? " €"
                                : item.info?.currency === "btc"
                                ? " €"
                                : ""
                            }`}
                        </p>
                      )}
                    </div>
                  </div>
                )}
                {item.description === 'set_tariff' && (
                    <div className="d-flex fd-column">
                      <p className="color-main">
                        {item?.info?.name}
                      </p>
                    </div>
                )}
                {!turn && (
                  <p className="fz-13 color-main">
                    {replaceMethodText(item.info?.method)}
                  </p>
                )}
                {item.info.length !== 0 && (
                  <>
                    {item.info?.profile?.photo && (
                      <Link
                        to={`/profile/${item.info?.profile?.slug}`}
                        className="hover-line"
                        alt="ad"
                      >
                        <img
                          src={setMediaSize(item.info?.profile?.photo, "xs")}
                          className="res__ad__photo"
                          alt="ad"
                        />
                      </Link>
                    )}
                    {item.info?.profile?.slug && (
                      <div className="d-flex fd-column">
                        <p className="fz-13 color-main">{t("ad")}</p>
                        <Link
                          to={`/profile/${item.info?.profile?.slug}`}
                          className="hover-line"
                          alt="ad"
                        >
                          <span className="p1 color-green text-dots">
                            {item.info?.profile?.name}
                          </span>
                        </Link>
                      </div>
                    )}
                  </>
                )}
              </div>
            }

            {
              <p
                className={`p1 ${
                  item.type === "credit" || item.type === "credit_hold"
                    ? "color-green"
                    : "color-red-700"
                } currency--erocoin fw-500 text-right`}
              >
                {item.type === "credit" || item.type === "credit_hold"
                  ? "+" + item.amount
                  : "-" + item.amount}
              </p>
            }
            {/* {turn && <p
              className={`p1 ${
                item.type === "credit" ? "color-green" : "color-red-700"
              } fw-500 text-right`}
            >
              {item.type === "credit" ? "+" + item.amount : "-" + item.amount}
              {item.info?.currency && item.info?.currency === "eur" ? " €" : item.info?.currency === "czk" ? " Kč" : item.info?.currency === "usdt" ? " ₮" : item.info?.currency === "btc" ? " ₿" : "" }
            </p>} */}
          </div>
        );
      });
    }
  };

  const [debit, setDebit] = useState("");
  const [credit, setCredit] = useState("");
  const [dailyPay, setDailyPay] = useState("");
  const frozenPay = useSelector(getUserHold);
  const incomingTransaction = useSelector(getIncomingTransaction);
  const [pagesList, setPagesList] = useState([]);
  const [page, setPage] = useState(1);

  const handlePage = (e, value) => {
    e.preventDefault();
    navigate(`/${lang}/lk/balance#${value}`);
    setPage(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        scrollToTop();
        const route = `user/transactions`;
        const method = "GET";

        const resultType = optionsBalanceIndex.join(",");
        const dateResult = date.split(" - ");
        const firstDate = dateResult[0];
        const secondDate = dateResult[1];

        const payload = {
          ...(tubValue === 1 && { per_page: 24 }),
          ...(tubValue === 2 && { hold_per_page: 24 }),
          ...(tubValue === 1 && { page: page }),
          ...(tubValue === 2 && { hold_page: page }),
          ...(!!selectName.slug && { profile: selectName.slug }),
          ...(!!optionsBalanceIndex.length && { type: resultType }),
          date_from: firstDate,
          date_to: secondDate,
        };

        const data = await makeRequest({ route, method, payload });
        const { per_page, total } = data.meta;
        const result = Math.ceil(total / per_page);
        const arrayRes = Array.from({ length: result }, (_, i) => i + 1);
        if (tubValue !== 2) {
          setPagesList(arrayRes);
        }

        const resultData = data.transactions;
        const info = data.info;
        setCredit(info.outcome);
        setDebit(info.income);
        setDailyPay(info.dayly_writes_amount);
        setData(resultData);
        setDataTurn(data.transactions_hold);

        setIsLoading(false);
      } catch (error) {}
    };

    token && fetchData();
  }, [token, selectName.slug, optionsBalanceIndex, date, page, tubValue]);
  useEffect(()=>{
    setData((prev) => {
      return [incomingTransaction, ...prev];
    })},[incomingTransaction])

  const [open, setOpen] = useState(false);

  const deactivatedRefill = (e) => {
    e.preventDefault();

    setOpen(true);
  };

  useTitle(t("balance"));

  return !hasAccess ? AccessTitle : (
    <main>
      <section className={"balance-page__container"}>
        <h1>
          {t("balance")}:{" "}
          <span className="currency--erocoin">{userBalance}</span>
        </h1>
        <div
          className={`balance-page__header ${
            isTablet ? "fd-column gap-16 align-start" : ""
          }`}
        >
          <div
            className={`balance-page__info justify-start gap-28 ${
              isTablet ? "flex-wrap" : ""
            }`}
          >
            {/* <div className="d-flex fd-column gap-8"> */}
            {/* <span className="p1">{t("yourbalance")}:</span> */}
            {/* <span className="title_h3 mb-0 currency--erocoin">
                {userBalance}
              </span> */}
            {/* </div> */}

            <div
              className={`d-flex fd-column gap-4 ${
                isMobile ? "fd-row align-center" : ""
              }`}
            >
              <span className="p1">{t("dailymoney")}:</span>
              <span className="title_h3 mb-0 currency--erocoin">
                {dailyPay}
              </span>
            </div>
            <div
              className={`d-flex fd-column gap-4 ${
                isMobile ? "fd-row align-center" : ""
              }`}
            >
              <span className="p1">{t("frozenmoney")}:</span>
              <span className="title_h3 mb-0 currency--erocoin">
                {frozenPay}
              </span>
            </div>
            <div
              className={`d-flex fd-column gap-4 ${
                isMobile ? "fd-row align-center w-100" : ""
              }`}
            >
              <span className="p1">{t("autotopbal")}:</span>
              <div
                className={`d-flex   align-center ${
                  isTablet ? "w-100 justify-end gap-4" : "justify-sb gap-12"
                }`}
              >
                <span className="title_h3 mb-0 currency--erocoin">{0}</span>
                {!!autoMonthPay && (
                  <Link
                    to={"#"}
                    className={"text-underline"}
                    onClick={(e) => deactivatedRefill(e)}
                  >
                    {t("deactive")}
                  </Link>
                )}
              </div>
            </div>
          </div>

          <div className="balance-page__actions">
            <ButtonLink
              href={"/lk/topup"}
              clazz={`button--green _no-underline ${isMobile ? "w-100" : ""}`}
              size={isMobile ? "s" : "l"}
            >
              {t("topupbalance")}
            </ButtonLink>
            <ButtonLink
              href={"/lk/withdraw"}
              clazz={`button--primary _no-underline ${isMobile ? "w-100" : ""}`}
              size={isMobile ? "s" : "l"}
            >
              {t("withdraw")}
            </ButtonLink>
          </div>
        </div>
        {/* <WrapperInfo> */}
        <div className="header__tabs balance-page__tabs mt-16 mb-16">
          {tabs.map((i, index) => {
            return (
              <button
                key={index}
                className={`header__tab p2 ${
                  activeTabId === i.id ? "_active" : ""
                }`}
                onClick={() => handleTabClick(i.id)}
              >
                {i.name}
              </button>
            );
          })}
        </div>
        {/* {!isMobile && (
            <div className="balance-page__info ml-auto">
              <div className="balance-page__updown up">
                <Icon size={"s"} spritePath={"cash-up"} clazz={"_green mr-4"} />
                <span className="mr-4">{t("incomes")}</span>
                <span className="color-green currency--erocoin">{debit}</span>
              </div>
              <div className="balance-page__updown down">
                <Icon size={"s"} spritePath={"cash-down"} clazz={"_red mr-4"} />
                <span className="mr-4">{t("expences")}</span>
                <span className="color-red-700 currency--erocoin">
                  {credit}
                </span>
              </div>
            </div>
          )} */}
        {/* </WrapperInfo> */}

        <div className="balance-page__select mb-16">
          <div className="balance-page__info">
            <Dropdown
              title={!!selectName.name ? selectName.name : t("allads")}
              name={!!selectName.name ? selectName.name : t("allads")}
              size={"s"}
              hideOnCHange={true}
              count={!!selectName.name}
            >
              <SearchBlock
                selectName={selectName}
                isLoading={isLoading}
                currentName={selectName}
                setName={setSelectName}
                selectSearch={selectSearch}
                setSelectSearch={setSelectSearch}
                selectData={selectData}
                setSelectData={setSelectData}
              />
            </Dropdown>
            <Dropdown
              title={t("alloperations")}
              name={t("alloperations")}
              size={"s"}
              count={!!optionsBalanceIndex.length}
            >
              {options_balance.map((i) => {
                return (
                  <Checkbox
                    key={i.id}
                    name={i.name}
                    id={i.id}
                    title={i.title}
                    checked={optionsBalanceIndex.includes(i.value)}
                    onChange={() => {
                      if (optionsBalanceIndex.includes(i.value)) {
                        setOptionsBalanceIndex(
                          optionsBalanceIndex.filter((item) => item !== i.value)
                        );
                      } else {
                        setOptionsBalanceIndex((prev) => [...prev, i.value]);
                      }
                    }}
                  />
                );
              })}
            </Dropdown>
          </div>
          <WrapperInfo>
            <Dropdown
              title={date}
              name={"date"}
              size={isExtraMobile ? "xs" : "s"}
              iconClass={`calendar`}
              clazz={`calendar ${isExtraMobile ? "height-100" : ""}`}
            >
              <DateInput
                isMulti
                onChange={setDate}
                notAfterCurDate={true}
                notBeforeCurDate={false}
              />
            </Dropdown>

            <div
              className={`balance-page__info ml-auto ${
                isExtraMobile ? "w-50" : ""
              }`}
            >
              <div className="balance-page__updown up min-w-fit">
                {!isExtraMobile && (
                  <Icon
                    size={"s"}
                    spritePath={"cash-up"}
                    clazz={"_green mr-4"}
                  />
                )}
                {!isExtraMobile && <span className="mr-4">{t("incomes")}</span>}
                <span className="color-green currency--erocoin">
                  {"+ " + addSpacesEveryThreeDigits(debit)}
                </span>
              </div>
              <div className="balance-page__updown down min-w-fit">
                {!isExtraMobile && (
                  <Icon
                    size={"s"}
                    spritePath={"cash-down"}
                    clazz={"_red mr-4"}
                  />
                )}
                {!isExtraMobile && (
                  <span className="mr-4">{t("expences")}</span>
                )}
                <span className="color-red-700 currency--erocoin">
                  {"- " + addSpacesEveryThreeDigits(credit)}
                </span>
              </div>
            </div>
          </WrapperInfo>
        </div>

        {
          <DataBlock
            elems={tubValue === 1 ? data : Object.values(dataTurn)}
            turn={tubValue !== 1}
          />
        }

        {pagesList.length > 1 && (
          <Pagination
            pages={pagesList}
            activePage={page}
            onPageChange={handlePage}
          />
        )}
      </section>

      {isOpenAd &&
        <AdBalancePopup
          open={isOpenAd}
          setOpen={setIsOpenAd}
        />
      }

      {isOpenWithDraw &&
        <WithdrawBalancePopup
          open={isOpenWithDraw}
          setOpen={setIsOpenWithDraw}
        />
      }

      {open &&
        <DeactivateRefillPopup
          open={open}
          setOpen={setOpen}
        />
      }
    </main>
  );
};

export default BalancePage;
