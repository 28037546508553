import React, {useEffect, useState} from "react";
import {Button, ButtonLink, Checkbox, Icon} from "@/components/ui";
import {Scrollbar} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import {
    minusFavoriteCounter,
    plusFavoriteCounter,
} from "@/stores/slices/userSlice";
import {v4 as uuidv4} from "uuid";

import {
    selectAdd,
    unselectAdd,
} from "@/stores/slices/agencyFilterSlice";
import {makeRequest} from "@/services/makeRequest";
import useLadyService from "@/services/LadyService";
import GirlCardIconsAndTags from "./GirlCardIconsAndTags";
import {useSelector} from "react-redux";
import {getUserCurrentCity} from "@/stores/slices/userSlice";

const GirlCardSlider = ({props, isHover, setIsHover, checkbox = false, selected = false, linkRef}) => {
    const {
        gender,
        orientation,
        id,
        slug,
        name,
        is_healthy,
        is_verified,
        count_photos,
        count_videos,
        photos,
        is_favorite,
        has_ero,
        is_individual,
        is_new,
        tag_bdsm,
        is_pornstar,
        verified_at,
        healthy_at,
        big,
    } = props;

    const {lang, dispatch, setMediaSize, windowWidth, token, t} =
        useLadyService();
    const currentCity = useSelector(getUserCurrentCity);

    const uniqueId = uuidv4();

    const localeFavoriteSlug = localStorage.getItem("favorites") || [];

    const [favorite, setFavorite] = useState(
        token ? is_favorite : localeFavoriteSlug.includes(slug)
    );

    const toggleFavoriteCount = (slug) => {
        if (favorite) {
            if (token) {
                const fetchData = async () => {
                    try {
                        const route = `user/favorites/remove/${slug}`;
                        const method = "DELETE";

                        const data = await makeRequest({route, method});
                    } catch (error) {
                    }
                };
                fetchData();
            } else {
                let favorites = JSON.parse(localStorage.getItem("favorites")) || [];

                favorites = favorites.filter((item) => item !== slug);

                localStorage.setItem("favorites", JSON.stringify(favorites));
            }
        } else {
            if (token) {
                const fetchData = async () => {
                    try {
                        const route = `user/favorites/add/${slug}`;
                        const method = "POST";

                        const data = await makeRequest({route, method});
                    } catch (error) {
                    }
                };

                fetchData();
            } else {
                const favorites = JSON.parse(localStorage.getItem("favorites")) || [];
                if (!favorites.includes(slug)) {
                    favorites.push(slug);
                }
                localStorage.setItem("favorites", JSON.stringify(favorites));
            }
        }

        dispatch(favorite ? minusFavoriteCounter() : plusFavoriteCounter());
        setFavorite(!favorite);
    };
    const [checked, setChecked] = useState(selected);
    useEffect(() => {
        setChecked(selected)
    }, [selected])
    useEffect(() => {
        if (checked && checked === true) {
            dispatch(selectAdd(id));
        }
        if (!checked && checked === false) {
            dispatch(unselectAdd(id));
        }
    }, [checked]);

    const swiperSlideTemplate = (photos) => (
        <SwiperSlide
            onMouseLeave={() => setIsHover(false)}
            onMouseEnter={() => setIsHover(true)}
            onClick={() => linkRef.current.click()}
            className={`girl-card__slide${isHover ? " _hover" : ""}`}
        >
            <picture>
                <source media="(max-width: 599px)" srcSet={setMediaSize(photos, "s2")}/>
                <img src={setMediaSize(photos, "m")} alt={t("altphoto", {GirlName: name, City: currentCity.name})}/>
            </picture>
        </SwiperSlide>
    );
    const isJsdom = typeof window !== 'undefined' && window.navigator.userAgent.includes('jsdom');
    return !isJsdom ? (
        <Swiper
            className="girl-card__slider"
            title={t("altphoto", {GirlName: name, City: currentCity.name})}
            nested={true}
            lazy={true}
            modules={[Scrollbar]}
            spaceBetween={10}
            scrollbar={{
                hide: false,
                el: `.swiper-scrollbar_${uniqueId}`,
                draggable: true,
                dragSize: 32,
            }}
        >
            {checkbox ? (
                <Checkbox
                    id={`check_${id}`}
                    readOnly={true}
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                />
            ) : null}

            <Button
                clazz={`girl-card__favorite`}
                onClick={() => toggleFavoriteCount(slug)}
            >
                <Icon size={"m"} spritePath={favorite ? "favorite-fill" : "favorite"}/>
            </Button>

            {photos?.length > 0 && photos[0] ? swiperSlideTemplate(photos[0]) : null}
            {photos?.length > 0 && photos[1] ? swiperSlideTemplate(photos[1]) : null}
            {photos?.length > 0 && photos[2] ? swiperSlideTemplate(photos[2]) : null}

            <SwiperSlide className={"girl-card__slide"}>
                <div className="girl-card__box">
                    {count_photos ? (
                        <div className="girl-card__info-box">
                            <Icon spritePath={"image"} size={"m"}/>
                            <span>
                {count_photos} {t("photo")}
              </span>
                        </div>
                    ) : null}

                    {count_videos ? (
                        <div className="girl-card__info-box">
                            <Icon spritePath={"video-play"} size={"m"}/>
                            <span>
                {count_videos} {t("videofotograph")}
              </span>
                        </div>
                    ) : null}

                    {has_ero ? (
                        <div className="girl-card__info-box">
                            <Icon clazz={"color-green"} spritePath={"pants"} size={"m"}/>
                            <span>{t("erocontent")}</span>
                        </div>
                    ) : null}

                    <ButtonLink
                        clazz={"button--green"}
                        href={`/${lang}/profile/${slug}`}
                        size={"xs"}
                    >
                        <span>{t("goto")}</span> {t("toad")}
                    </ButtonLink>
                </div>
            </SwiperSlide>

            <div slot={"container-end"} className="girl-card__descr">
                <div className="girl-card__tags-wrapper">
                    <GirlCardIconsAndTags
                        props={{
                            gender,
                            orientation,
                            is_individual,
                            is_healthy,
                            is_verified,
                            is_pornstar,
                            is_new,
                            tag_bdsm,
                            healthy_at,
                            verified_at,
                        }}
                    />
                </div>

                <div className={`swiper-scrollbar swiper-scrollbar_${uniqueId}`}>
                    {photos?.length === 1 ? <span></span> : null}
                    {photos?.length === 2 ? (
                        <>
                            <span></span>
                            <span></span>
                        </>
                    ) : null}
                    {photos?.length === 3 ? (
                        <>
                            <span></span>
                            <span></span>
                            <span></span>
                        </>
                    ) : null}

                    <div className="swiper-scrollbar__bar"></div>
                </div>
            </div>
        </Swiper>
    ) : (<a href={`/${lang}/profile/${slug}`} title={t('titlephoto', {GirlName: name, City: currentCity.name})}>
        <picture>
            <source media="(max-width: 599px)" srcSet={setMediaSize(photos, "s2")}/>
            <img src={setMediaSize(photos, "m")} alt={t("altphoto", {GirlName: name, City: currentCity.name})}/>
        </picture>

        <div slot={"container-end"} className="girl-card__descr">
            <div className="girl-card__tags-wrapper">
                <GirlCardIconsAndTags
                    props={{
                        gender,
                        orientation,
                        is_individual,
                        is_healthy,
                        is_verified,
                        is_pornstar,
                        is_new,
                        tag_bdsm,
                        healthy_at,
                        verified_at,
                    }}
                />
            </div>
        </div>
    </a>);
};

export default GirlCardSlider;
