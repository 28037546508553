import {useSelector} from "react-redux";
import {getCountriesData, getUserCurrentCity} from "@/stores/slices/userSlice";
import React, {useState} from "react";
import {SelectCountry} from "@/components/ui";
import useLadyService from "@/services/LadyService";


const SelectCity = () => {
    const {lang} = useLadyService()
    const countriesData = useSelector(getCountriesData);
    
    const [currentActive, setCurrentActive] = useState("");
    const currentCity = useSelector(getUserCurrentCity);
    const isJsdom = typeof window !== 'undefined' && window.navigator.userAgent.includes('jsdom');
    if (!isJsdom) {
        return (
            <SelectCountry
                data={countriesData}
                name={"city-create"}
                clazz={`button button-xs select-city`}
                arrowSize={"m"}
                type={"flag"}
                defaultTitle={currentCity.title}
                currentActiveIndex={currentActive}
                isMain={true}
                activeCountry={currentCity.icon}
                setActive={setCurrentActive}
            />
        )
    } else {
        return (
            countriesData[0]['cz'].cities.map((city) => (
                <a href={`/${lang}/${city.slug}`} key={city.id}>{city.title || city.name}</a>
            ))
        )
    }
};

export default SelectCity