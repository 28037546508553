import { ButtonLink } from "../../../../ui";
import useLadyService from "../../../../../services/LadyService";

const InfoBlock = ({
  link,
  linkText,
  text,
  count,
  clazzText,
  balance,
  userHistory,
  userSubscriptionsAndFilters
}) => {
  const { t } = useLadyService();

  const returnCountStyle = (count) => {
    if(balance) {
      return <span className={"currency--erocoin color-green"}>{count}</span>
    }
    else {
      return <span className={"color-green"}>{count}</span>
    }
  }

  return (
    <div className={"account-page__link-block"}>
      <span className={`title${clazzText ? ` ${clazzText}` : ""}`}>
        {text}
        {": "}
        {returnCountStyle(count)}
      </span>

      {link && (
        <ButtonLink
          href={link}
          clazz={"td-none button_outline--green-accent wd-160"}
          size={"s"}
        >
          {t("goto")}
        </ButtonLink>
      )}
    </div>
  );
};

export default InfoBlock;
