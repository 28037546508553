import GuideIndi from "./indi/GuideIndi";
import GuideAgency from "./agency/GuideAgency";
import { useEffect, useState } from "react";
import useTitle from "../../../hooks/useTitle";
import GuideDefault from "./default/GuideDefault";
import useLadyService from "@/services/LadyService";
import GuideCustomer from "./customer/GuideCustomer";
import { useLocation } from "react-router-dom";
import {Loader} from "../../ui";
import "./guidePage.scss";

const Guide = () => {
  const { userType, windowWidth, t, scrollToHash } = useLadyService();
  const { hash } = useLocation();

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [scrolledClass, setScrolledClass] = useState("");
  useEffect(() => {
    if (windowWidth < 767.98) {
      window.addEventListener("scroll", () => {
        if (window.scrollY < 140) {
          setScrolledClass("");
        } else {
          setScrolledClass(" _fixed");
        }
      });
    }
  }, [windowWidth, window]);

  useEffect(() => {
    if(hash) {
      // костыль но работает
      setTimeout(() =>scrollToHash(), 300);
      // второй потому что первый иногда скролит вообще не туда 
      setTimeout(() =>scrollToHash(), 400);
    }
  }, []);

 

  useTitle(`${t("guidetitle")} Lady4Love.com`);

  let propses = {
    scrolledClass: scrolledClass,
    show: show,
    setShow: setShow,
  };

  switch (userType) {
    case "indi":
      return isLoading ? <GuideIndi {...propses} /> : <Loader/>;

    case "agency":
      return isLoading ? <GuideAgency {...propses} /> : <Loader/>;

    case "customer":
      return isLoading ? <GuideCustomer {...propses} /> : <Loader/>;

    default:
      return isLoading ? <GuideDefault {...propses} /> : <Loader/>;
  }
};

export default Guide;
