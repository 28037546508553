import {useEffect, useMemo, useState} from "react";
import "./services.scss";
import _ from 'lodash'
import {Button, Checkbox, InputInLabel, Loader, Select} from "@/components/ui";
import {useForm} from "react-hook-form";
import "./services.scss";

import {makeRequest} from "@/services/makeRequest";

import useLadyService from "@/services/LadyService";
import showToast from "../../../../../../toast/Toast";
import {
    AttentionBrick,
    ErrorMessage,
    LoadingButton,
} from "@/components/ui";
import LangAi from "../../langAi/LangAI";
import {useSelector} from "react-redux";
import {getPreferTranslateTo} from "@/stores/slices/translateAiSlice";
import {mergeTranslations} from "@/helper/mergeTranslations";
import {Popup} from "../../../../../../popups";
import {getCurrency, setCurrency} from "../../../../../../../stores/slices/userProfileSlice";

const PopupButtons = ({setOpen, isLoading}) => {
    const {t} = useLadyService();

    return (
        <div className="create-form__buttons">
            <Button
                size={"l-forever"}
                square={false}
                clazz="button--secondary min-w-160"
                onClick={() => setOpen(false)}
                disabled={isLoading}
            >
                {t("cancel")}
            </Button>

            <LoadingButton
                buttonType={"submit"}
                isLoading={isLoading}
                size={"l-forever"}
                square={false}
                clazz="button--green min-w-160"
            >
                {t("save")}
            </LoadingButton>
        </div>
    );
};

const DefaultButtons = ({backStep, isLoading}) => {
    const {t} = useLadyService();

    return (
        <div className="create-form__buttons">
            <Button
                size={"l-forever"}
                square={false}
                clazz="button--secondary min-w-160"
                onClick={() => backStep()}
                disabled={isLoading}
            >
                {t("back")}
            </Button>

            <LoadingButton
                buttonType={"submit"}
                size={"l-forever"}
                square={false}
                isLoading={isLoading}
                clazz="button--green min-w-160"
            >
                {t("continue")}
            </LoadingButton>
        </div>
    );
};

const MainPart = ({
                      setOpen,
                      setServicesFor,
                      data,
                      servicesFor,
                      servicesArrayOptions,
                      servData,
                      handleCheckboxChange,
                      addTag,
                      toggleComment,
                      showExtra,
                      addNoted,
                      addPrice,
                      isMobile,
                      isPreferLoad,
                      preferValue,
                      preferChange,
                      preferValueLength,
                      generateText,
                      langAi,
                      setLangAi,
                      selectedLangsAi,
                      setSelectedLangsAi,
                      formData
                  }) => {
    const {t, lang, dispatch} = useLadyService();

    const currencyOption = {
        main_title: t("currency"),
        options: [
            {id: 1, title: "EUR", value: "eur"},
            {id: 2, title: "CZK", value: "czk"},
        ],
    };

    const currency = useSelector(getCurrency)


    return (
        <div className={`create-page__form__services`}>
            <div className="form-services__top-item services-for">
                <SelectWithButtons
                    type={"checkbox"}
                    buttonValue={setServicesFor}
                    buttons={data.for}
                    withoutSelect={true}
                    defaultValue={servicesFor}
                />
                <Select
                onChange={(value) => {
                    dispatch(setCurrency(value))
                }}
                options={currencyOption.options}
                clazz={"button button-xs justify-start"}
                arrowSize={"s"}
                name={"currency"}
                defaultTitle={
                    currencyOption.options.filter(
                        (item) => item.value === currency
                    )[0]?.title || "eu"
                }
                subTitle={t("currency")}
                placeholder={t("currency")}
                withoutTitle={true}
                postApi={true}
            />
            </div>
            <span className="form-services__desc">{t("shortcomment")}</span>
            <div className="form-services__sections">
                {servicesArrayOptions.map((item) => {
                    const middleIndex = Math.ceil(item?.services.length / 2);
                    const firstColumn = item?.services.slice(0, middleIndex);
                    const secondColumn = item?.services.slice(middleIndex);

                    return (
                        <div className="form-services__section">
                            <h3>{t(`${item?.slug}`)}</h3>
                            <div className="form-services__section--checkbox">
                                <div className="form-services__section--column">
                                    {firstColumn.map((item) => {
                                        const checkedId = servData?.filter(
                                            (obj) => obj.id === item.id
                                        )[0]?.id;

                                        const checkedTag = servData?.filter(
                                            (obj) => obj.id === item.id
                                        )[0]?.as_tag;

                                        const noteData = servData?.filter(
                                            (obj) => obj.id === item.id
                                        )[0]?.note;

                                        const payData = servData?.filter(
                                            (obj) => obj.id === item.id
                                        )[0]?.additional_cost;

                                        return (
                                            <div
                                                className="form-services__section--item"
                                                key={item.id}
                                                tabIndex={0}
                                            >
                                                <Checkbox
                                                    title={t(item.slug)}
                                                    checked={!!checkedId}
                                                    onClick={() => handleCheckboxChange(item)}
                                                    important={!!checkedId && !!checkedTag}
                                                />

                                                {checkedId && (
                                                    <>
                                                        <div className={`form-services__section--extrabox`}>
                                                            <hr></hr>
                                                            <Checkbox
                                                                key={item?.title}
                                                                title={t("important")}
                                                                checked={!!checkedTag}
                                                                clazz={"services-category"}
                                                                onClick={() => addTag(item)}
                                                                disabled={!checkedId}
                                                                important
                                                            />
                                                        </div>
                                                        <span
                                                            className={`form-services__section--extra ${
                                                                !checkedId ? "disabled" : ""
                                                            }`}
                                                            onClick={(e) => toggleComment(e, item.id)}
                                                        >
                                                          {t("extra")}
                                                        </span>
                                                        <div
                                                            className={`form-services__section--comment ${
                                                                showExtra.includes(item.id) ? "_open" : ""
                                                            }`}
                                                        >
                                                            {showExtra.includes(item.id) ? (
                                                                <>
                                                                    <InputInLabel
                                                                        type={"text"}
                                                                        id={`create-comment-${item.id}`}
                                                                        placeholder={""}
                                                                        value={noteData}
                                                                        onChange={(e) => addNoted(item, e)}
                                                                    >
                                                                        {t("comment")}
                                                                    </InputInLabel>
                                                                    <InputInLabel
                                                                        type={"number"}
                                                                        id={`create-comment-price--${item.id}`}
                                                                        placeholder={""}
                                                                        value={payData}
                                                                        onChange={(e) => addPrice(item, e)}
                                                                        max={9999}
                                                                        currency={currency}
                                                                        handleCurrency={true}
                                                                        price={currency}
                                                                        multiplierStatus={true}
                                                                    >
                                                                        {t("addpay")}
                                                                    </InputInLabel>
                                                                </>
                                                            ) : null}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>

                                <div className="form-services__section--column">
                                    {secondColumn.map((item) => {
                                        const checkedId = servData?.filter(
                                            (obj) => obj.id === item.id
                                        )[0]?.id;

                                        const checkedTag = servData?.filter(
                                            (obj) => obj.id === item.id
                                        )[0]?.as_tag;

                                        const noteData = servData?.filter(
                                            (obj) => obj.id === item.id
                                        )[0]?.note;

                                        const payData = servData?.filter(
                                            (obj) => obj.id === item.id
                                        )[0]?.additional_cost;

                                        return (
                                            <div
                                                className="form-services__section--item"
                                                key={item.id}
                                                tabIndex={0}
                                            >
                                                <Checkbox
                                                    title={t(item.slug)}
                                                    checked={!!checkedId}
                                                    onClick={() => handleCheckboxChange(item)}
                                                    important={!!checkedId && !!checkedTag}
                                                />

                                                {checkedId && (
                                                    <>
                                                        <div className={`form-services__section--extrabox`}>
                                                            <hr></hr>
                                                            <Checkbox
                                                                key={item?.title}
                                                                title={t("important")}
                                                                checked={!!checkedTag}
                                                                clazz={"services-category"}
                                                                onClick={() => addTag(item)}
                                                                disabled={!checkedId}
                                                                important
                                                            />
                                                        </div>
                                                        <span
                                                            className={`form-services__section--extra ${
                                                                !checkedId ? "disabled" : ""
                                                            }`}
                                                            onClick={(e) => toggleComment(e, item.id)}
                                                        >
                                                          {t("extra")}
                                                        </span>
                                                        <div
                                                            className={`form-services__section--comment ${
                                                                showExtra.includes(item.id) ? "_open" : ""
                                                            }`}
                                                        >
                                                            {showExtra.includes(item.id) ? (
                                                                <>
                                                                    <InputInLabel
                                                                        type={"text"}
                                                                        id={`create-comment-${item.id}`}
                                                                        placeholder={""}
                                                                        value={noteData}
                                                                        onChange={(e) => addNoted(item, e)}
                                                                    >
                                                                        {t("comment")}
                                                                    </InputInLabel>
                                                                    
                                                                    <InputInLabel
                                                                        type={"number"}
                                                                        id={`create-comment-price--${item.id}`}
                                                                        placeholder={""}
                                                                        value={payData}
                                                                        onChange={(e) => addPrice(item, e)}
                                                                        max={9999}
                                                                        currency={currency}
                                                                        handleCurrency={true}
                                                                        price={currency}
                                                                        multiplierStatus={true}
                                                                    >
                                                                        {t("addpay")}
                                                                    </InputInLabel>
                                                                </>
                                                            ) : null}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <h3 className="mb-0">
                {t("prefinsex")} {t("prefondates")} ({t("prefdisp")})
            </h3>
            <LangAi
                langAi={langAi}
                setLangAi={setLangAi}
                selectedLangsAi={selectedLangsAi}
                setSelectedLangsAi={setSelectedLangsAi}
            />
            <div className="form-services__preference">
                <div
                    className={`form-services__preference--inputs ${
                        isMobile ? "fd-column" : ""
                    }`}
                >
                    {!isPreferLoad ? (
                        !(!!formData.preferences_translate_to.length && formData.preferences_translate_to.includes(langAi)) ?
                            <InputInLabel
                                type={"textarea"}
                                id={"create-AI2"}
                                placeholder={""}
                                value={preferValue[langAi]?.preferences}
                                onChange={preferChange}
                            >
                                {t("symbols") + ":"} {preferValueLength || 0}
                                /400
                                {preferValueLength === 400 && (
                                    <ErrorMessage message={`${t("maxval")} 400 ${t("symbols")}`}/>
                                )}
                            </InputInLabel> : <div className="loader-container">
                                <Loader/>
                                <span className="d-flex justify-center">{t('processing')}</span>
                            </div>
                    ) : (
                        <div className="loader__block">
                            <Loader/>
                        </div>
                    )}
                </div>
                <div className={`d-flex fd-column justify-sb gap-16`}>
                    <AttentionBrick/>
                    <span className="color-main">
            {t("gentext")} {t("yourservices")}
          </span>
                    <Button
                        size={"m"}
                        square={false}
                        clazz={`button--primary ${isMobile ? "w-100" : ""} mt-12`}
                        onClick={generateText}
                    >
                        {t("genbutton")}
                    </Button>
                </div>
            </div>
        </div>
    );
};

const SelectWithButtons = ({buttons, buttonValue, defaultValue}) => {
    const [selectedButton, setSelectedButton] = useState(defaultValue);

    const {t} = useLadyService();

    const handleButtonClick = (value) => {
        const isIdAlreadySelected = selectedButton.includes(value);
        if (isIdAlreadySelected) {
            setSelectedButton((prev) =>
                prev.filter((buttonId) => buttonId !== value)
            );
            buttonValue((prev) => prev.filter((buttonId) => buttonId !== value));
        } else {
            setSelectedButton((prev) => [...prev, value]);
            buttonValue((prev) => [...prev, value]);
        }
    };

    return (
        <div className="create-page__input-container w-100">
            <h3>{t("servicesfor")}:</h3>
            <div className="create-page__input-buttons">
                {buttons.map((i) => (
                    <label
                        className={`checkbox-button${
                            selectedButton.includes(i.value) ? " checkbox-button_checked" : ""
                        }`}
                        htmlFor={i.id}
                        tabIndex={1}
                    >
                        <input
                            onClick={() => handleButtonClick(i.value)}
                            tabIndex={0}
                            id={i.id}
                            name={i.name}
                            checked={selectedButton === i.value}
                            value={i.value}
                        />
                        {i.title}
                    </label>
                ))}
            </div>
        </div>
    );
};

const ServicesGlobal = ({
                            open,
                            setOpen,
                            formData,
                            setFormData,
                            services,
                            setAvailableSteps,
                            changeStep,
                            rootElem,
                            setFilled,
                        }) => {
    const {windowWidth, t, token, lang} = useLadyService();

    const isMobile = windowWidth < 1000.98;

    const [isLoading, setIsLoading] = useState(false);

    const [langAi, setLangAi] = useState(lang);
    const updatedTranslation = useSelector(getPreferTranslateTo);

    useEffect(() => {
        if (!!formData.preferences_translate_to.length) {
            setFormData(prevFormData => {
                const updatedLanguages = prevFormData.preferences_translate_to.filter(lang =>
                    !updatedTranslation.some(translation => translation.hasOwnProperty(lang))
                );

                const newFormData = {...prevFormData};
                const result = mergeTranslations(newFormData.description, updatedTranslation);

                return {
                    ...newFormData,
                    description: result,
                    preferences_translate_to: updatedLanguages,
                };
            });
        }
    }, [updatedTranslation]);

    const data = {
        for: [
            {id: 65, title: t("men"), value: "man"},
            {id: 64, title: t("women"), value: "women"},
            {id: 63, title: t("couples"), value: "couples"},
            {id: 62, title: t("groups"), value: "groups"},
        ],
    };

    const {
        services: servicesArrayNew,
        for_couples,
        for_groups,
        for_men,
        for_women,
    } = formData;

    const [servicesFor, setServicesFor] = useState(() => {
        const types = [];
        if (for_men) {
            types.push("man");
        }
        if (for_women) {
            types.push("women");
        }
        if (for_couples) {
            types.push("couples");
        }
        if (for_groups) {
            types.push("groups");
        }
        return types;
    });

    const backStep = () => {
        changeStep((prev) => prev - 1);
        rootElem.scrollIntoView({
            behavior: "smooth",
        });
    };

    const {handleSubmit} = useForm({
        mode: "onSubmit",
    });

    const [isPreferLoad, setIsPreferLoad] = useState(false);
    const [preferValue, setPreferValue] = useState(
        !!formData?.description
            ? formData?.description
            : {en: {preferences: null,}, ru: {preferences: null,}, cz: {preferences: null,}}
    );
    const [selectedLangsAi, setSelectedLangsAi] = useState(() => {
        return _.map(preferValue, (value, key) => {
            if (!value?.preferences && key !== langAi) {
                return key
            }
            return null
        })
    });
    const [preferValueLength, setPreferValueLength] = useState(
        !!formData.description[lang]?.preferences ? formData.description[lang]?.preferences.length : 0
    );

    const preferChange = (e) => {
        const value = e.target.value;
        const limitedValue = value.substring(0, 400);
        setPreferValueLength(limitedValue.length);
        let newPreferValue = {...preferValue}
        if (!newPreferValue[langAi]) {
            newPreferValue[langAi] = {description: ''};
        }
        newPreferValue[langAi].preferences = limitedValue
        setPreferValue(newPreferValue);
    };

    const createServicesArray = (incomingArray) => {
        const servicesArray = [];

        incomingArray.forEach((category) => {
            category?.services?.forEach((service) => {
                const newService = {
                    id: service.id,
                };

                newService.as_tag = !!service.tag ? service.tag : false;

                if (service.note !== null) {
                    newService.note = service.note;
                }

                if (service.additional_cost !== null) {
                    newService.additional_cost = Number(service.additional_cost);
                }

                servicesArray.push(newService);
            });
        });

        return servicesArray;
    };

    const servicesArrayData = createServicesArray(servicesArrayNew);

    const [servData, setServData] = useState(servicesArrayData);
    const count = servData.filter((item) => item.as_tag === true).length;

    const [dataImportant, setDataImportant] = useState(count);

    const generateText = () => {
        const fetchData = async () => {
            setIsPreferLoad(true);

            const route = `user/profile/${formData.slug}`;

            const method = "PUT";
            const payload = {
                services: servData,
                for_men: servicesFor.includes("man") ? 1 : 0,
                for_women: servicesFor.includes("women") ? 1 : 0,
                for_couples: servicesFor.includes("couples") ? 1 : 0,
                for_groups: servicesFor.includes("groups") ? 1 : 0,
            };

            const resultPut = await makeRequest({route, method, payload});

            if (resultPut.message === "updated") {
                try {
                    const route = `user/${formData.slug}/get-preferences/${langAi}`;

                    const method = "GET";

                    const result = await makeRequest({route, method});
                    if (result) {
                        const value = result["preferences"];
                        const limitedValue = value.substring(0, 400);
                        setPreferValueLength(limitedValue.length);
                        setPreferValue(limitedValue);
                        let newPreferValue = {...preferValue}
                        if (!newPreferValue[langAi]) {
                            newPreferValue[langAi] = {description: ''};
                        }
                        newPreferValue[langAi].preferences = limitedValue
                        setPreferValue(newPreferValue);
                    }
                } catch (error) {
                }

                setIsPreferLoad(false);
            }
        };

        fetchData();
    };

    const servicesArrayOptions =
        !!Object.entries(services) &&
        Object.keys(services).map((key) => {
            const item = services[key];
            return {
                title: item.title,
                slug: item.slug,
                services: Object.keys(item.services).map((serviceKey) => {
                    const service = item.services[serviceKey];
                    return {
                        name: service.name,
                        id: parseInt(serviceKey),
                        slug: service.slug,
                    };
                }),
            };
        });

    const newServData = servData.filter((i) => {
        return !!i.note || !!i.additional_cost;
    });

    const servDataExtra = newServData.map((i) => i.id);

    const [showExtra, setShowExtra] = useState(servDataExtra);

    const toggleComment = (e, id) => {
        e.stopPropagation();
        const index = showExtra.indexOf(id);

        if (index === -1) {
            setShowExtra((prev) => [...prev, id]);
        } else {
            const newValue = [...showExtra];
            newValue.splice(index, 1);
            setShowExtra(newValue);
        }
    };

    const addNoted = (item, e) => {
        const value = e.target.value;

        setServData((prevServData) => {
            const objIndex = prevServData.findIndex((obj) => obj.id === item.id);

            if (objIndex !== -1) {
                const updatedObject = {...prevServData[objIndex], note: value};
                return prevServData.map((obj) =>
                    obj.id === item.id ? updatedObject : obj
                );
            }

            return prevServData;
        });
    };

    const addPrice = (item, value) => {

        setServData((prevServData) => {
            const objIndex = prevServData.findIndex((obj) => obj.id === item.id);

            if (objIndex !== -1) {
                const updatedObject = {
                    ...prevServData[objIndex],
                    additional_cost: value?.target ? value.target.value : value,
                };
                return prevServData.map((obj) =>
                    obj.id === item.id ? updatedObject : obj
                );
            }

            return prevServData;
        });
    };

    const handleCheckboxChange = (item) => {
        setServData((prevServData) => {
            const index = prevServData.findIndex((obj) => obj.id === item.id);

            if (index !== -1) {
                setDataImportant((prev) => prev - 1);
                return prevServData.filter((obj) => obj.id !== item.id);
            } else {
                return [...prevServData, {id: item.id}];
            }
        });
    };


    const addTag = (item) => {
        setServData((prevServData) => {
            const objIndex = prevServData.findIndex((obj) => obj.id === item.id);

            if (objIndex !== -1) {
                const updatedObject = {...prevServData[objIndex]};

                if (!updatedObject.as_tag && dataImportant >= 4) {
                    showToast({
                        message:
                            t("maxval") +
                            " " +
                            t("posslible") +
                            " " +
                            t("highlight") +
                            " 4 " +
                            t("services").toLowerCase(),
                        variant: "info",
                    });
                    return [...prevServData];
                }

                if (updatedObject.as_tag) {
                    setDataImportant((prev) => prev - 1);
                    updatedObject.as_tag = false;
                } else {
                    setDataImportant((prev) => prev + 1);
                    updatedObject.as_tag = true;
                }

                return prevServData.map((obj) =>
                    obj.id === item.id ? updatedObject : obj
                );
            } else {
                return [...prevServData, {id: item.id, as_tag: true}];
            }
        });
    };

    const onSubmit = () => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const route = `user/profile/${formData.slug}`;

                const method = "PUT";
                const payload = {
                    isFull: true,
                    services: servData,
                    for_men: servicesFor.includes("man") ? 1 : 0,
                    for_women: servicesFor.includes("women") ? 1 : 0,
                    for_couples: servicesFor.includes("couples") ? 1 : 0,
                    for_groups: servicesFor.includes("groups") ? 1 : 0,
                    ...({...preferValue}),
                    preferences_translate_to: selectedLangsAi.filter(lang => lang) || []
                };

                const result = await makeRequest({route, method, payload});

                if (result.status) {
                    setIsLoading(false);

                    !!setAvailableSteps && setAvailableSteps((prev) => [...prev, 5]);
                    !!changeStep && changeStep((prev) => prev + 1);
                    setFormData(() => ({
                        ...result.profile,
                    }));
                    !!setOpen && setOpen(false);
                    !!setFilled && setFilled(result.profile.completion);
                    !!rootElem &&
                    rootElem.scrollIntoView({
                        behavior: "smooth",
                    });
                }
            } catch (error) {
                showToast({
                    message: t("oops"),
                    variant: "error",
                });
                setIsLoading(false);
            }
        };

        token && fetchData();
    };

    const findServicesById = (categories, serviceTags) => {
        const serviceIds = new Set(serviceTags.map((tag) => tag.id));

        return categories
            .map((category) => {
                const matchedServices = category.services
                    .filter((service) => serviceIds.has(service.id))
                    .map((service) => {
                        const tag = serviceTags.find((tag) => tag.id === service.id);
                        return {
                            ...service,
                            tag: tag?.as_tag || false,
                            additional_cost: tag?.additional_cost || null,
                            note: tag?.note || null,
                        };
                    });

                if (matchedServices.length > 0) {
                    return {
                        category_id: categories.indexOf(category) + 1,
                        category_slug: category.slug,
                        category_name: category.title,
                        services: matchedServices,
                    };
                }

                return null;
            })
            .filter((category) => category !== null);
    };

    const matchedServices = useMemo(
        () => findServicesById(servicesArrayOptions, servData),
        [servData]
    );

    useEffect(() => {
        setFormData((prev) => ({
            ...prev,
            services: matchedServices,
            for_men: servicesFor.includes("man") ? 1 : 0,
            for_women: servicesFor.includes("women") ? 1 : 0,
            for_couples: servicesFor.includes("couples") ? 1 : 0,
            for_groups: servicesFor.includes("groups") ? 1 : 0,
            preferences: preferValue,
        }));
    }, [matchedServices, preferValue, servicesFor, setFormData]);

    const handleClose = () => {
        setOpen(false)
    }

    if (!!setOpen) {
        return (
            <>
                {isLoading && (
                    <div className="loader__overlay">
                        <Loader height={100}/>
                    </div>
                )}
                <Popup
                    open={open}
                    setOpen={handleClose}
                    onSubmit={handleSubmit(onSubmit)}
                    clazz={'popup-form__body__edit'}
                >
                    <h2>{t("services")}</h2>
                    <MainPart
                        langAi={langAi}
                        setLangAi={setLangAi}
                        selectedLangsAi={selectedLangsAi}
                        setSelectedLangsAi={setSelectedLangsAi}
                        setOpen={setOpen}
                        setServicesFor={setServicesFor}
                        data={data}
                        servicesFor={servicesFor}
                        servicesArrayOptions={servicesArrayOptions}
                        servData={servData}
                        handleCheckboxChange={handleCheckboxChange}
                        addTag={addTag}
                        toggleComment={toggleComment}
                        showExtra={showExtra}
                        addNoted={addNoted}
                        addPrice={addPrice}
                        isMobile={isMobile}
                        isPreferLoad={isPreferLoad}
                        preferValue={preferValue}
                        preferChange={preferChange}
                        preferValueLength={preferValueLength}
                        generateText={generateText}
                        formData={formData}
                    />
                    <PopupButtons setOpen={setOpen} isLoading={isLoading}/>
                </Popup>
            </>
        );
    }

    return (
        <>
            <h2>{t("services")}</h2>{" "}
            <form
                onSubmit={handleSubmit(onSubmit)}
                onClick={(e) => e.stopPropagation()}
            >
                <MainPart
                    langAi={langAi}
                    setLangAi={setLangAi}
                    selectedLangsAi={selectedLangsAi}
                    setSelectedLangsAi={setSelectedLangsAi}
                    setOpen={setOpen}
                    setServicesFor={setServicesFor}
                    data={data}
                    servicesFor={servicesFor}
                    servicesArrayOptions={servicesArrayOptions}
                    servData={servData}
                    handleCheckboxChange={handleCheckboxChange}
                    addTag={addTag}
                    toggleComment={toggleComment}
                    showExtra={showExtra}
                    addNoted={addNoted}
                    addPrice={addPrice}
                    isMobile={isMobile}
                    isPreferLoad={isPreferLoad}
                    preferValue={preferValue}
                    preferChange={preferChange}
                    preferValueLength={preferValueLength}
                    generateText={generateText}
                    formData={formData}
                />
                <DefaultButtons backStep={backStep} isLoading={isLoading}/>
            </form>
        </>
    );
};

export default ServicesGlobal;
