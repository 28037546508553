import { DndProvider } from "react-dnd-cjs";
import HTML5Backend from "react-dnd-html5-backend-cjs";
import { Icon, InputInLabel, Loader, Select} from "@/components/ui";
import PhotoUpload from "../UploadZone";
import { useEffect, useState } from "react";
import { getIdsFromUrls } from "../changeOrder/changeOrder";
import LangAi from "@/components/pages/accounts/createProfile/pageComponent/langAi/LangAI";
import useLadyService from "@/services/LadyService";
import _ from "lodash";
import { useSelector } from "react-redux";
import { getDescEroTranslateTo } from "@/stores/slices/translateAiSlice";
import { mergeTranslations } from "@/helper/mergeTranslations";
import {getCurrency, setCurrency} from "../../../../../../../../stores/slices/userProfileSlice";
const ErrorMessage = ({ message }) => {
  return <span className="error create-error">{message}</span>;
};

export const Ero = ({
  data,
  setData,
  dataVideo,
  setDataVideo,
  show,
  setFiles,
  setPrice,
  eroPrice,
  errorPrice,
  setError,
  setDesc,
  eroDesc,
  eroPriceRef,
  formData,
  setFormData,
  setResultLangsAi
}) => {
  const { t, lang, dispatch } = useLadyService();

  const updatedTranslation = useSelector(getDescEroTranslateTo);

  useEffect(() => {
    if (!!formData.ero?.data?.description_translate_to.length) {
      
      setFormData(prevFormData => {
        const updatedLanguages = prevFormData.ero.data.description_translate_to.filter(lang =>
          !updatedTranslation.some(translation => translation.hasOwnProperty(lang))
        );
    
        const newFormData = { ...prevFormData };

        const result = mergeTranslations(newFormData.ero.data.description, updatedTranslation);
        
        return {
          ...newFormData,
          ero: {
            data: {
              description: result,
              description_translate_to: updatedLanguages,
              price: newFormData.ero.data.price,
              hide: newFormData.ero.data.hide,
              photo: newFormData.ero.data.photo,
              video: newFormData.ero.data.video,
              photo_count: newFormData.ero.data.photo_count,
            },
          },
        };
      });
    }
  }, [updatedTranslation]);

  const ImportantBlock = () => {
    return (
      <div className="attention-box important mt-16">
        <span className="error">{t("important") + "!"}</span>
        {t("i-eroprovit") + ""} <b>{t("i-only")}</b> {" " + t("i-eroprovit2")}
      </div>
    );
  };
  const movePhoto = (dragIndex, hoverIndex) => {
    const draggedPhoto = data.files[dragIndex];
    const newPhotos = [...data.files];
    newPhotos.splice(dragIndex, 1);
    newPhotos.splice(hoverIndex, 0, draggedPhoto);
    setData({ files: newPhotos });
    setFormData(prev => ({
      ...prev,
      ero: {
        data: {
          ...prev.ero?.data,
          photo: {
            data: newPhotos
          }
        }
      }
    }))
    getIdsFromUrls(newPhotos, formData.slug);
  };
  const movePhotoVideo = (dragIndex, hoverIndex) => {
    const draggedPhoto = data.files[dragIndex];
    const newPhotos = [...data.files];
    newPhotos.splice(dragIndex, 1);
    newPhotos.splice(hoverIndex, 0, draggedPhoto);
    setDataVideo({ files: newPhotos });
    setFormData(prev => ({
      ...prev,
      ero: {
        data: {
          ...prev.ero?.data,
          video: {
            data: newPhotos
          }
        }
      }
    }))
    getIdsFromUrls(newPhotos, formData.slug);
  };

  const updateEroPriceValue = (e) => {
    const value = e.target.value;
    setPrice(value);
    setError(false);
  };
  const [descValueLength, setDescValueLength] = useState(formData.ero?.data?.description[lang]?.description ? formData.ero?.data?.description[lang]?.description.length : 0);

  

  const updateEroDescValue = (e) => {
    const value = e.target.value;
    const limitedValue = value.substring(0, 120);
    setDescValueLength(limitedValue.length);
    let newDescValue = { ...eroDesc };
    if (!newDescValue[langAi]) {
      newDescValue[langAi] = { description: "" };
    }
    newDescValue[langAi].description = limitedValue;
    setDesc(newDescValue);
  };

  const [langAi, setLangAi] = useState(lang);
  const [selectedLangsAi, setSelectedLangsAi] = useState(() => {
    return _.map(eroDesc, (value, key) => {
      if (!value?.description && key !== langAi) {
        return key;
      }
      return null;
    });
  });

  useEffect(() => {
    setResultLangsAi(selectedLangsAi)
  }, [selectedLangsAi]);

  useEffect(() => {
    setDescValueLength(eroDesc[langAi]?.description?.length || 0)
  }, [langAi])

  const currencyOption = {
    main_title: t("currency"),
    options: [
      {id: 1, title: "EUR", value: "eur"},
      {id: 2, title: "CZK", value: "czk"},
    ],
  };

  const currency = useSelector(getCurrency)

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        {show === "ero" && (
          <>
            <h3 id="#erocontentupload">{t("erophoto")}</h3>
            <PhotoUpload
              movePhoto={movePhoto}
              data={data}
              setData={setData}
              accept={{
                "image/jpeg": [".jpg", ".jpeg", ".JPG", ".JPEG"],
                "image/png": [".png", ".PNG"],
                "image/webp": [".webp", ".WEBP"],
              }}
              type="photo"
              addText={false}
              mode={"ero"}
              maxSize={10 * 1024 * 1024}
              limit={10}
              formData={formData}
              setFormData={setFormData}
              eroDesc={eroDesc}
              eroPrice={eroPrice}
            />
            <h3>{t("erovid")}</h3>
            <PhotoUpload
              movePhoto={movePhotoVideo}
              data={dataVideo}
              setData={setDataVideo}
              accept={{
                "video/mp4": [".mp4", ".MP4"],
                "video/quicktime": [".mov", ".MOV"],
                "video/x-ms-wmv": [".wmv", ".WMV"],
                "video/x-msvideo": [".avi", ".AVI"],
                "video/x-matroska": [".mkv", ".MKV"],
              }}
              type="video"
              video={true}
              addText={false}
              mode={"ero"}
              maxSize={20 * 1024 * 1024}
              limit={10}
              formData={formData}
              setFormData={setFormData}
              eroDesc={eroDesc}
              eroPrice={eroPrice}
            />
            <ImportantBlock />
            {(!!data.files?.length ||
              !!dataVideo.files?.length ||
              !!data.length ||
              !!dataVideo.length) && (
              <div className="upload-ero__info" ref={eroPriceRef}>
                <h3>{t("accessprice")}</h3>
                <div className="upload-ero__recommendation">
                  <Select
                      onChange={(value) => {
                        dispatch(setCurrency(value))
                      }}
                      options={currencyOption.options}
                      clazz={"button button-xs justify-start"}
                      arrowSize={"s"}
                      name={"currency"}
                      defaultTitle={
                          currencyOption.options.filter(
                              (item) => item.value === currency
                          )[0]?.title || "eu"
                      }
                      subTitle={t("currency")}
                      placeholder={t("currency")}
                      withoutTitle={true}
                      postApi={true}
                  />
                  <InputInLabel
                    price={"erocoin"}
                    type={"number"}
                    id={"upload-price"}
                    value={eroPrice}
                    placeholder={"5"}
                    required
                    clazz={`${errorPrice ? "_error" : ""} min-w-160`}
                    onChange={(e) => updateEroPriceValue(e)}
                    max={9999}
                    currency={currency}
                    multiplierStatus={true}
                  >
                    {t("summ")}
                    {errorPrice && (
                      <span className="error create-error">
                        {t("required")}
                      </span>
                    )}
                  </InputInLabel>
                  <div className="d-flex fd-column">
                    <p className="color-green">
                      {t("thisamount")} <Icon size={"s"} spritePath={"info"} />
                      <span className="upload-ero__watch-notification">
                        {t("vipfree")}
                      </span>
                    </p>
                    <span className="color-main">
                      {t("recommendedprice")}: 5-10 €{" "}
                      <span className="color-600">{t("maxviews")}</span>
                    </span>
                  </div>
                </div>

                <h3>{t("descero")}</h3>
                <span className="mb-16 color-600">{t("adddescero")}</span>
                <LangAi
                  langAi={langAi}
                  setLangAi={setLangAi}
                  selectedLangsAi={selectedLangsAi}
                  setSelectedLangsAi={setSelectedLangsAi}
                />
                {!(!!formData.ero?.data?.description_translate_to?.length && formData.ero?.data.description_translate_to.includes(langAi)) ? (
                  <InputInLabel
                    type={"textarea"}
                    id={"upload-desc"}
                    // value={eroDesc[langAi]?.description}
                    value={eroDesc[langAi]?.description || ""}
                    clazz={"mt-16"}
                    placeholder={""}
                    onChange={updateEroDescValue}
                  >
                    {t("symbols") + ":"} {descValueLength}/120
                    {descValueLength === 120 && (
                      <ErrorMessage
                        message={`${t("maxval")} 120 ${t("symbols")}`}
                      />
                    )}
                  </InputInLabel>
                ) : (
                  <div className="loader-container mt-16">
                    <Loader />
                    <span className="d-flex justify-center">
                      {t("processing")}
                    </span>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </DndProvider>
    </>
  );
};
