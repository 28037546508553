import {fetcher} from "./apiFetch";
import showToast from "../components/toast/Toast";

export const makeRequest = async ({route, method = "GET", payload = {}, t = false, navigate = false}) => {
    return await fetcher([route, method, payload]).catch(async (error) => {
        const res = error.response;
        switch (res?.status) {
            case 401: {
                showToast({
                    message: t("noauth"),
                    variant: "error",
                });
                localStorage.removeItem("user");
                break;
            }
            case 403: {
                showToast({
                    message: t("noAuthAction"),
                    variant: "error",
                });
                break;
            }
            case 404: {
                if (navigate) {
                    navigate("/404");
                }
                break;
            }
            case 422: {
                showToast({
                    message: t("oops"),
                    variant: "error",
                });
                break;
            }
            default: {
                showToast({
                    message: t("oops"),
                    variant: "error",
                });
                break;
            }
        }
    });
};
