import { isFilterSearch } from "@/stores/slices/filterSlice";
import { getTitle } from "@/stores/slices/titleSlice";
import { Button, Icon } from "@/components/ui";
import { useDispatch, useSelector } from "react-redux";

import "./girlMap.scss";
import { setStoriesMap } from "@/stores/slices/popupSlice";
import { getUserCurrentCity } from "@/stores/slices/userSlice";
import { useTranslation } from "react-i18next";

const GirlMap = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation("translation");

  const isFilterSelected = useSelector(isFilterSearch);
  // const title = useSelector(getTitle);
  const currentCity = useSelector(getUserCurrentCity);
  // const postCity = t("incity")?.replace(
  //   /%([^%]+)%/g,
  //   currentCity.title ? currentCity.title : ""
  // );
  const postTitle = t("h3main", {City: currentCity.title ? currentCity.title : ""})
  // const titleString =
  //   title.length > 0
  //     ? title
  //         .filter((item) => !!item)
  //         .map((item) => t(item))
  //         .join(", ")
  //     : "";
  // const titleParams = titleString ? `${titleString + " " + postCity}` : postTitle;

  const postFindnear = t("findnear")?.replace(/%([^%]+)%/g, currentCity.title);
  return (
    <section className={"girl-map__container"} id="models-map">
      <div className="girl-map__inner">
        <div className={"girl-map__content"}>
          <h2>
            {!isFilterSelected ? postTitle : t("matching")}
          </h2>
          <h3>{postFindnear}</h3>
          <Button
            size={"s"}
            clazz={`button--primary`}
            onClick={() => {dispatch(setStoriesMap(true));}}
          >
            {t("onmap")}
            <Icon size={"l"} spritePath={"map"} />
          </Button>
        </div>
      </div>
    </section>
  );
};

export default GirlMap;
