import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "../ui";
import "./pagination.scss";

const Pagination = ({ pages = [], activePage, onPageChange = false }) => {
  const activePageLast = activePage === 0 ? 1 : activePage;
  const totalPages = typeof pages === "number" ? pages : pages.length;
  const delta = 2;

  const range = () => {
    const rangeWithDots = [];

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= activePageLast - delta && i <= activePageLast + delta)
      ) {
        rangeWithDots.push(i);
      } else if (rangeWithDots[rangeWithDots.length - 1] !== "...") {
        rangeWithDots.push("...");
      }
    }

    return rangeWithDots;
  };

  const pagesToDisplay = range();

  return (
    <div className={"pagination"}>
      {activePageLast > 1 && (
        <Link
          className={"pagination__arrow"}
          to={`?page=${activePageLast - 1}`}
          onClick={(e) => onPageChange(e, Number(activePageLast) - 1)}
        >
          <Icon size={"l"} spritePath={"chevrone-left"} />
        </Link>
      )}

      {pagesToDisplay.map((i, index) => {
        return i === "..." ? (
          <span key={index} className="pagination__dots">
            ...
          </span>
        ) : (
          <Link
            key={index}
            to={i === 1 ? '' : `?page=${i}`}
            className={`pagination__num${
              activePageLast === i ? " _active" : ""
            }`}
            onClick={(e) => onPageChange(e, i)}
          >
            {i}
          </Link>
        );
      })}

      {activePageLast < totalPages && (
        <Link
          className={"pagination__arrow"}
          to={`?page=${activePageLast + 1}`}
          onClick={(e) => onPageChange(e, Number(activePageLast) + 1)}
        >
          <Icon size={"l"} spritePath={"chevrone-right"} />
        </Link>
      )}
    </div>
  );
};

export default Pagination;
