import useLadyService from "@/services/LadyService";
import useCheckAccess from "../../../hooks/useCheckAccess";
import { useEffect, useState } from "react";
import { DateInput, Dropdown, Icon, Loader } from "../../ui";
import SearchBlock from "../balancePage/components/SearchBlock";
import { makeRequest } from "../../../services/makeRequest";
import { UpAd } from "@/components/pages/accounts/model/profiles/card/components";
import GirlCard from "@/components/cards/girlCard/GirlCard.js";
import { AddStoriesPopup } from "@/components/popups";
import "./statistic-page.scss";
import StatisticTable from "./components/StatisticTable/StatisticTable";
import showToast from "../../toast/Toast";
import StatisticChart from "./components/StatisticChart/StatisticChart";
import { useParams } from "react-router-dom";

const StatisticPage = () => {
  const { t, token, navigate } = useLadyService();

  const params = useParams();


  const { hasAccess, AccessTitle } = useCheckAccess("statsaccess");

  const [activeTabId, setActiveTabId] = useState(1);
  const tabs = [
    { id: 1, name: t("viewtable") },
    { id: 2, name: t("viewchart") },
  ];
  const handleTabClick = (tabId) => {
    setActiveTabId(tabId);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [selectName, setSelectName] = useState({
    name: params.name ?? '',
    slug: params.slug ?? '',
  });

  function formatDate(date) {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = String(date.getFullYear());

    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    if (year < 10) {
      year = "0" + year;
    }

    return `${day}.${month}.${year}`;
  }

  const now = new Date();
  const currentDate = new Date();
  currentDate.setDate(now.getDate() - 1);
  const formattedCurrentDate = formatDate(currentDate);
  const previousMonthDate = new Date();
  previousMonthDate.setMonth(now.getMonth() - 1);
  const formattedPreviousMonthDate = formatDate(previousMonthDate);
  const [optionsBalanceIndex, setOptionsBalanceIndex] = useState([]);

  const [statistic, setStatistic] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [promoPeriods, setPromoPeriods] = useState([]);
  const [promoPeriodsDates, setPromoPeriodsDates] = useState([]);
  const [elitePeriods, setElitePeriods] = useState([]);
  const [elitePeriodsDates, setElitePeriodsDates] = useState([]);
  const [selectData, setSelectData] = useState([]);
  const [selectSearch, setSelectSearch] = useState("");
  const [profile, setProfile] = useState({});
  const [elite, setElite] = useState(profile?.is_elite);
  const [top, setTop] = useState(false);
  const [hasEro, setHasEro] = useState(false);
  const [hasStories, setHasStories] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);

  const [date, setDate] = useState(
    formattedPreviousMonthDate + " - " + formattedCurrentDate
  );

  

  // const [dateChanged, setDateChanged]= useState(false)

  // stories set logic
  const [stories, setStories] = useState(profile?.stories);
  const [isOpenStory, setIsOpenStory] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const route = `user/statistic`;
        const method = "GET";

        const resultType = optionsBalanceIndex.join(",");
        const dateResult = date.split(" - ");
        const firstDate = dateResult[0];
        const secondDate = dateResult[1];

        const payload = {
          ...(!!selectName.slug && { profile: selectName.slug }),
          ...(!!optionsBalanceIndex.length && { type: resultType }),
          date_from: firstDate,
          date_to: secondDate,
        };

        const { result } = await makeRequest({ route, method, payload });
        setStatistic(result.statistic);
        setTransactions(result.transactions);
        setPromoPeriodsDates(result.promotionPeriodDates);
        setPromoPeriods(result.promotionPeriods);
        setElitePeriodsDates(result.eliteStatusPeriodsDates);
        setElitePeriods(result.eliteStatusPeriods);
        setProfile(() => result?.profile?.data);
        setIsEnabled(() =>
          result?.profile?.data?.is_enabled ?? result?.profile?.data?.is_enabled
            ? true
            : false
        );
        setElite(result?.profile?.data?.is_elite ?? false);
        setTop(result?.profile?.data?.is_top ?? false);
        setHasEro(result?.profile?.data?.has_ero ?? false);
        setHasStories(
          result?.profile?.data?.stories?.length > 0 ? true : false
        );
      } catch (error) {
        showToast({ message: t("oops"), variant: "error" });
      } finally {
        setIsLoading(false);
      }
    };

    token && fetchData();
  }, [token, selectName.slug, optionsBalanceIndex, date]);

  const transformDateToISO = (date) => {
    const [day, month, year] = date.trim().split("."); // Разделяем строку по точке
    return `${year}-${month}-${day}`; // Формируем строку в формате гггг-мм-дд
  };
  const generateLabels = (startDate, endDate) => {
    const labels = [];
    let currentDate = new Date(transformDateToISO(startDate));
    const lastDate = new Date(transformDateToISO(endDate));
    while (currentDate <= lastDate) {
      const day = String(currentDate.getDate()).padStart(2, "0");
      const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Месяц 0-индексный
      const year = currentDate.getFullYear();

      labels.push(`${year}.${month}.${day}`);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return labels;
  };

  const [startDate, endDate] = date.split("-");
  const labels = generateLabels(startDate, endDate);

  const handleAddEro = () => {
    navigate(`/lk/create/${profile.slug}#addEro`);
  };
  const handleAddStories = () => {
    setIsOpenStory(true);
  };


  useEffect(() => {
    setSelectName({
      name: params.name,
      slug: params.slug,
    });
  }, [params]);

  const resetSearch = () => {
    setSelectName({
      name: "",
      slug: "",
    });
  };

  // useEffect(() => {
  //   setDateChanged(true);
  // }, [setDate, date])

  // const resetDates = ( ) =>{
  //   setDate(formattedPreviousMonthDate + " - " + formattedCurrentDate);
  //   setDateChanged(false);
  // }


  return !hasAccess ? (
    AccessTitle
  ) : isLoading ? (
    <Loader height={100} />
  ) : (
    <main>
      <section className={"statistic-page__container "}>
        <div className="statistic-page__header fd-col">
          <h1>{t("mystat")}</h1>

          <div className="statistic-page__search">
            <Dropdown
              title={!!selectName.name ? selectName.name : t("allads")}
              name={!!selectName.name ? selectName.name : t("allads")}
              size={"s"}
              hideOnCHange={true}
              count={!!selectName.name}
              clazz={"select__button"}
            >
              <SearchBlock
                currentName={selectName}
                setName={setSelectName}
                selectSearch={selectSearch}
                setSelectSearch={setSelectSearch}
                selectData={selectData}
                setSelectData={setSelectData}
                oneOnlySelect={true}
              />
            </Dropdown>

            {selectName.slug && selectName.slug !== "" && (
              <span onClick={resetSearch}>{t("reset")}</span>
            )}

            <div className={"d-flex gap-8 align-center"}>
              <span className={"d-none-tablet-small color-main"}>
                {t("forperiod")}
              </span>
              <Dropdown
                title={date}
                name={"date"}
                size={"s"}
                iconClass={`calendar`}
                clazz={`select__button`}
              >
                <DateInput
                  isMulti
                  onChange={setDate}
                  curDate={currentDate}
                  notAfterCurDate={true}
                  notBeforeCurDate={false}
                />
              </Dropdown>
              {/* {dateChanged && <span onClick={resetDates}>{t('reset')}</span>} */}
            </div>
          </div>
        </div>

        <div className="statistic-page__info-block">
          <div className="info-block-wrapper">
            <div className="statistic-numbers-container__block">
              <div className="statistic-numbers-container__item1">
                <div>
                  <Icon size={"s"} spritePath={"eye"} />
                  <span className="mr-auto">{t("profileshows")}</span>
                </div>
                <span className="">
                  {statistic?.forperiod?.profileshows ?? 0}
                </span>
              </div>
              <div className="statistic-numbers-container__item1">
                <div>
                  <Icon size={"s"} spritePath={"call"} />
                  <span>{t("viewcontact")}</span>
                </div>
                <span>{statistic?.forperiod?.viewcontact ?? 0}</span>
              </div>
              <div className="statistic-numbers-container__item1">
                <div>
                  <Icon size={"s"} spritePath={"arrow-up-right"} />
                  <span>{t("viewprofile")}</span>
                </div>
                <span>{statistic?.forperiod?.viewprofile ?? 0}</span>
              </div>
              <div className="balance-page__updown up">
                <div>
                  <Icon
                    size={"s"}
                    spritePath={"cash-up"}
                    clazz={"_green mr-8"}
                  />
                  <span>{t("incomes")}</span>
                </div>

                <span className="color-green currency--erocoin">
                  {transactions?.income?.forperiod ?? 0}
                </span>
              </div>
            </div>

            <div className="statistic-numbers-container__block">
              <div className="statistic-numbers-container__item2">
                <div>
                  <Icon size={"s"} spritePath={"pants"} />
                  <span className="mr-auto">{t("erocontentaccess")}</span>
                </div>

                <span className="numbers">
                  {statistic?.forperiod?.erocontentaccess ?? 0}{" "}
                </span>
                {transactions?.income?.erocontentaccess ? (
                  <span
                    className={"ml-8 color-green currency--erocoin numbers"}
                  >
                    {`+${transactions?.income?.erocontentaccess}`}
                  </span>
                ) : (
                  <span
                    className={"ml-8 color-green currency--erocoin numbers"}
                  >
                    {`+0`}
                  </span>
                )}
              </div>
              <div className="statistic-numbers-container__item2">
                <div>
                  <Icon size={"s"} spritePath={"video"} />
                  <span className="mr-auto">{t("minofvideo")}</span>
                </div>

                <span className="numbers">
                  {statistic?.forperiod?.minofvideo ?? 0}
                </span>

                {transactions?.income?.videochat ? (
                  <span
                    className={"ml-4 color-green currency--erocoin numbers"}
                  >
                    {`+${transactions?.income?.videochat}`}
                  </span>
                ) : (
                  <span
                    className={"ml-4 color-green currency--erocoin numbers"}
                  >
                    {`+0`}
                  </span>
                )}
              </div>
              <div className="statistic-numbers-container__item2">
                <div>
                  <Icon size={"s"} spritePath={"pay-content"} />
                  <span className="mr-auto">{t("g-preorders")}</span>
                </div>

                <span className="numbers">
                  {statistic?.forperiod?.["g-preorders"] ?? 0}
                </span>
                {transactions?.income?.orderonline ? (
                  <span
                    className={"ml-4 color-green currency--erocoin numbers"}
                  >
                    {`+${transactions?.income?.orderonline}`}
                  </span>
                ) : (
                  <span
                    className={"ml-4 color-green currency--erocoin numbers"}
                  >
                    {`+0`}
                  </span>
                )}
              </div>
              <div className="balance-page__updown down">
                <div>
                  <Icon
                    size={"s"}
                    spritePath={"cash-down"}
                    clazz={"_red mr-8"}
                  />

                  <span className="mr-4">{t("expences")}</span>
                </div>

                <span className="color-red-700 currency--erocoin numbers">
                  {transactions?.expenses?.forperiod ?? 0}
                </span>
              </div>
            </div>
          </div>
          {!!selectName.slug && (
            <div className="statistics-model-preview-container">
              <div className="statistics-model-preview-container__block">
                <div className="girlcard">
                  <GirlCard
                    props={profile !== undefined ? profile : []}
                    edit
                    isCheckbox={false}
                    status
                    infoCard={true}
                  />
                  {/* <div className="steps__progressbar">
                    <span className="steps__progressbar-text">
                    {t("fullon")}
                    <span className="progressbar">{profile?.completion}%</span>
                  </span>
                  <div className="progressbar-value">
                    <span
                      className="progressbar-current"
                      style={{ width: `${profile?.completion}%` }}
                    ></span>
                  </div>
                </div> */}
                </div>
              </div>
              <div className="statistics-model-preview-container__block">
                <UpAd
                  status={isEnabled}
                  address={profile?.address}
                  current_position={profile?.current_position}
                  is_elite={elite}
                  elite_to={profile?.elite_to}
                  is_top={top}
                  city={profile?.city}
                  slug={profile?.slug}
                  name={profile?.name}
                  city_id={profile?.city_id}
                  setElite={setElite}
                  id={profile?.id}
                  main_photo={profile?.main_photo}
                  position_price={profile?.position_price}
                  promotion_to_at={profile?.promotion_to_at}
                  setData={profile?.setData}
                />
                {isEnabled ? (
                  <div className="buttons">
                    {/* {elite ? null: <button onClick={handleBuyElite} type="button" class="button button-s button_outline--spec-yellow">
                  <span className="elite">{`${t('buy')} Elite`}</span><span>{`+ ${125}% ${t('toviews')}`}</span>
                </button> }
                {top ? null : <button onClick={handlePromote} type="button" class="button button-s button_outline--black">
                  <span>{t('upad')}</span><span className="green">{`+${350}% ${t('toviews')}`}</span>
                </button>} */}
                    {hasEro ? null : (
                      <button
                        onClick={handleAddEro}
                        type="button"
                        class="button button-s button_outline--black"
                      >
                        <span>{t("addero")}</span>
                        <span className="green">{`+${15}% ${t(
                          "toviews"
                        )}`}</span>
                      </button>
                    )}
                    {hasStories > 0 ? null : (
                      <button
                        onClick={handleAddStories}
                        type="button"
                        class="button button-s button_outline--black"
                      >
                        <span>{t("addstory")}</span>
                        <span className="green">{`+${10}% ${t(
                          "toviews"
                        )}`}</span>
                      </button>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </div>

        <h2>{t("i-detailedstat")}</h2>

        <div className="header__tabs balance-page__tabs mt-16 mb-16">
          {tabs.map((i, index) => {
            return (
              <button
                key={index}
                className={`header__tab p2 ${
                  activeTabId === i.id ? "_active" : ""
                }`}
                onClick={() => handleTabClick(i.id)}
              >
                {i.name}
              </button>
            );
          })}
        </div>

        {activeTabId === 1 && (
          <StatisticTable
            profileFilter={selectName.slug}
            statistic={statistic}
            transactions={transactions}
            promoPeriods={promoPeriodsDates}
            elitePeriods={elitePeriodsDates}
          />
        )}
        {activeTabId === 2 && (
          <StatisticChart
            profileFilter={selectName.slug}
            labels={labels}
            statistic={statistic}
            transactions={transactions}
            date={date}
            promoPeriods={promoPeriods}
            elitePeriods={elitePeriods}
          />
        )}

        {isOpenStory && (
          <AddStoriesPopup
            open={isOpenStory}
            setOpen={setIsOpenStory}
            slug={profile?.slug}
            setStories={setStories}
            stories={stories}
          />
        )}
      </section>
    </main>
  );
};

export default StatisticPage;
