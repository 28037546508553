import showToast from "../../../../toast/Toast";
import useUserProfileService from "../../../../../services/UserProfileService";
import useLadyService from "../../../../../services/LadyService";
import {makeRequest} from "../../../../../services/makeRequest";
import {Toggle} from "../../../../ui";
import {setSalonStatus} from "../../../../../stores/slices/userProfileSlice";

const ToggleSalon = () => {
  const { t, dispatch } = useLadyService();
  const { salonStatus } = useUserProfileService();

  const handleActivateSalon = async () => {
    try {
      const route = `user/set-salon-status`;
      const method = "PUT";
      const payload = {
        status: !salonStatus,
      };

      dispatch(setSalonStatus(!salonStatus))

      await makeRequest({ route, method, payload });

      showToast({
        message: t("success"),
        variant: "success",
      });
    } catch (error) {
      dispatch(setSalonStatus(!salonStatus))
      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };


  return (
    <div className="account-page__toggles">
      <div className="account-page__activate">
        <h3>{t("brothel")}</h3>
        <Toggle
          id={"isActiveSalon"}
          checked={!!salonStatus}
          onChange={handleActivateSalon}
        />
      </div>
      <span>{t("brocheck")}</span>
    </div>
  )
}

export default ToggleSalon