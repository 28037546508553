import useLadyService from "../../../../../services/LadyService";

const CustomLegend = ({ datasets, visibleDatasets, setVisibleDatasets }) => {
    const {t} = useLadyService()
    return (
        <div className={"d-flex justify-center flex-wrap"}>
            {datasets.map((dataset, index) => (
                <label
                    key={dataset.label}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "15px",
                        cursor: "pointer",
                    }}
                >
                    <input
                        type="checkbox"
                        checked={visibleDatasets[dataset.label]}
                        onChange={() =>
                            setVisibleDatasets((prev) => ({
                                ...prev,
                                [dataset.label]: !prev[dataset.label],
                            }))
                        }
                    />
                    <span
                        style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: dataset.borderColor,
                            display: "inline-block",
                            marginLeft: "5px",
                            marginRight: "5px",
                        }}
                    ></span>
                    {t(dataset.label)}
                </label>
            ))}
        </div>
    );
};

export default CustomLegend