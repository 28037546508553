import {Button, Toggle} from "@/components/ui";
import {ActivateProfilePopup, DeleteProfilePopup} from "../../../../popups";
import showToast from "@/components/toast/Toast";
import useUserProfileService from "@/services/UserProfileService";
import useLadyService from "@/services/LadyService";
import {setActivateProfileStatus, setDeleteProfileStatus} from "@/stores/slices/popupSlice";
import {capitalizeFirstLetter} from "@/helper/capitalizeFirstLetter";
import {setProfileStatus} from "@/stores/slices/userProfileSlice";
import {makeRequest} from "@/services/makeRequest";


const AccountActions = () => {
  const {t, userTypeCustomer, dispatch, windowWidth} = useLadyService()

  const {profileStatus} = useUserProfileService()

  const handleActivate = async () => {
    try {
      const route = `user/set-profiles-status`;
      const method = "PUT";

      const payload = {
        status: !profileStatus,
      };

      dispatch(setDeleteProfileStatus(true))

      await makeRequest({route, method, payload});

      showToast({
        message: t("success"),
        variant: "success",
      });
    } catch (error) {
      dispatch(setProfileStatus (state => !state))

      showToast({
        message: t("oops"),
        variant: "error",
      });
    }
  };

  const isActiveProfile = windowWidth < 420 ? t("activate") : t("activatepro");

  const handleOpenActivate = () => {
    dispatch(setActivateProfileStatus(true))
  };

  const handleOpenDelete = () => {
    dispatch(setDeleteProfileStatus(true))
  };

  return (
    <>
      <div className="account-page__actions-page">
        {userTypeCustomer ? null : (
          <div className="account-page__activate">
            <h3>{isActiveProfile}</h3>
            <Toggle
              id={"isActiveProfile"}
              checked={!!profileStatus}
              onClick={profileStatus ? handleOpenActivate : handleActivate}
            />
          </div>
        )}

        <Button
          type="button"
          clazz={"button--error ml-auto"}
          size={"l"}
          onClick={handleOpenDelete}
        >
          {capitalizeFirstLetter(t("deleteprofile"))}
        </Button>
      </div>

      <DeleteProfilePopup
        isActiveProfile={isActiveProfile}
        handleActivate={handleActivate}
      />

      <ActivateProfilePopup/>
    </>
  )
}

export default AccountActions