import { SwiperSlide } from "swiper/react";
import { ButtonLink } from "@/components/ui";
import { useSelector } from "react-redux";
import AgencyCard from "../../cards/agencyCard/AgencyCard";
import { getAllSorted } from "@/stores/slices/modelsState";
import { filterPlace, isFilterSearch } from "@/stores/slices/filterSlice";
import { useEffect, useState } from "react";
import Slider from "../Slider";
import { getUserCurrentCity } from "@/stores/slices/userSlice";
import { showFilter } from "@/stores/slices/showFilterSlice";
import { makeRequest } from "@/services/makeRequest";
import useLadyService from "@/services/LadyService";

import "./BestCityAgencySlider.scss";
import { useTranslation } from "react-i18next";
import {getAgencies} from "../../../stores/slices/agencySlice";

const BestCityAgencySlider = () => {
  const { region, lang } = useLadyService();

  const { t } = useTranslation("translation");
  const agencies = useSelector(getAgencies)
  const valueNavigation = useSelector(getAllSorted);
  const isFilterValue = useSelector(isFilterSearch);
  const valuePlace = useSelector(filterPlace);
  const [isRenderElem, setIsRenderElem] = useState(true);

  const cityId = useSelector(getUserCurrentCity).id;

  const isBigFilterShow = useSelector(showFilter);

  const [page, setPage] = useState(1);

  const [data, setData] = useState(agencies ? agencies?.agencies : []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = `agencies`;
        const method = "GET";
        const payload = {page};

        const data = await makeRequest({ route, method, payload });
        if (data) {
          const result = data.agencies;
          setData(result);
        }
      } catch (error) {}
    };

    lang && cityId && !isBigFilterShow && fetchData();
  }, [cityId, lang]);


  useEffect(() => {
    if (valueNavigation.length > 0 || valuePlace.length > 0 || isFilterValue) {
      setIsRenderElem(false);
    } else {
      setIsRenderElem(true);
    }
  }, [valueNavigation, isFilterValue]);
  const isJsdom = typeof window !== 'undefined' && window.navigator.userAgent.includes('jsdom');
  if (isRenderElem) {
    return (
      <div className={"best-agency__container"}>
        <h2 className="title_h2">{t("h2main2", {Country: ''})}</h2>
        {!isJsdom ? (
        <Slider type={"agency"}>
          {data?.length > 0 &&
            data.map((item) => {
              return (
                <SwiperSlide key={item.id}>
                  <AgencyCard props={item} />
                </SwiperSlide>
              );
            })}
        </Slider>
        ) : (
            <div className={"agencies__container"}>
              <div className="grid-cards">
                {data?.length > 0 &&
                    data.map((data) => <AgencyCard props={data} key={data.id} />)}
              </div>
            </div>
        )}

        <ButtonLink
          href={`/${lang}/agencies`}
          clazz={"button--secondary best-agency__button td-none"}
          size={"l"}
        >
          {t("allagencies")}
        </ButtonLink>
      </div>
    );
  }
};

export default BestCityAgencySlider;
