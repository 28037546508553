import useLadyService from "@/services/LadyService";
import {Button, Icon, InputInLabel, SelectCountry, Checkbox, ErrorMessage, Loader} from "@/components/ui";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {object, string} from "yup";
import { LangAi } from "@/components/pages/accounts/createProfile/pageComponent";
import { MediaZone } from "@/components/pages/accounts/agency/mediaZone/MediaZone.js";
import GeoInput from "@/components/ui/input/GeoInput";
import { useState } from "react";
import {convertData} from "@/helper/convertData";
import {useSelector} from "react-redux";
import {getAddressStatus} from "@/stores/slices/userProfileSlice";
import useHandleLocationClick from "@/services/HandleLocationClick";
import userProfileService from "@/services/UserProfileService";
import { useEffect } from "react";
import { Map } from "../accounts/createProfile/pageComponent";


const ChangePartyPage = (
) => {

    const {
        citiesList,
        emailStatus,
        telegramStatus,
        phoneStatus,
        nameStatus,
        whatsappStatus,
        longitudeStatus,
        latitudeStatus,
        cityIdStatus,
        cityStatus,
        addressStatus,
      } = userProfileService();

    const userAddress = useSelector(getAddressStatus)

    


    const { t, lang } = useLadyService();
    const citiesOptions = convertData(citiesList);

    const [langAi, setLangAi] = useState(lang);
    const [selectedLangsAi, setSelectedLangsAi] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [cityError, setCityError] = useState("");
    const [geoError, setGeoError] = useState(false);
    const [activeCountry, setActiveCountry] = useState("cz");
    const [isAddressActive, setIsAddressActive] = useState(false);
    const [primaryAddress, setPrimaryAddress] = useState({})
    const [mapMarker, setMapMarker] = useState({
        lat: latitudeStatus || null,
        lng: longitudeStatus || null,
    });
    const [selectedCity, setSelectedCity] = useState(
      !!cityStatus && !!citiesOptions?.length
        ? citiesOptions
          .find((item) => activeCountry in item)
          [activeCountry].cities.filter((item) => {
          return item?.name === cityStatus || item.id === cityStatus;
        })[0]?.name
        : ""
    );
    const currentCity = citiesList[activeCountry]?.citiesList?.find((city) => city?.name === selectedCity)
    const [formCity, setFormCity] = useState({
        id: "",
        title: "",
        icon: "",
    });
    const [forms, setForms] = useState([
        {id: null, city: {}, address: ""},
    ]);
    const [bounds, setBounds] = useState("");
    const [currentActive, setCurrentActive] = useState("");
    const [currentAddress, setCurrentAddress] = useState(userAddress?.length > 0 ? {...userAddress} : [{
        address: "",
        city: currentCity?.name || '',
        city_id: currentCity?.id
    }])
    const [currentDescription, setCurrentDescription] = useState("")
    const [cover,setCover] = useState('')
    const [interior, setInterior] = useState([])

    const handleChangeLangAi = async (newLang) => {
        const [currentDescription] = await Promise.all([watch(`description`)]);
        setValue(`description`, currentDescription || "");
        if (selectedLangsAi.includes(newLang)) {
            setSelectedLangsAi(selectedLangsAi.filter(lang => lang !== newLang))
        }
        setLangAi(newLang)
    }

    const changeCity = (city) => {
        let userAddress = [...currentAddress];
        userAddress[0] = {
            ...userAddress[0],
            city: city.name,
            city_id: city.id,
            address: "",
            latitude: "",
            longitude: "",
        };
        setCurrentAddress(userAddress);
    }


    const setAddress = async (newAddress) => {
        // if (typeof newAddress === "string") {
        //     const geocoder =  new window.google.maps.Geocoder();
        //     geocoder.geocode({address: newAddress}, (results, status) => {
        //         if (status === "OK" && results[0]) {
        //             newAddress = results[0];
        //             let userAddress = [...currentAddress];
        //             userAddress[primaryIndex] = {
        //                 ...userAddress[primaryIndex],
        //                 address: newAddress.formatted_address,
        //                 latitude: newAddress.geometry.location.lat(),
        //                 longitude: newAddress.geometry.location.lng(),
        //             };
        //             setCurrentAddress(userAddress);
        //             setIsActive(true);
        //         }
        //     });
        // } else {

        //     let userAddress = [...currentAddress];
        //     userAddress[primaryIndex] = {
        //         ...userAddress[primaryIndex],
        //         address: newAddress.formatted_address,
        //         latitude: newAddress.geometry.location.lat(),
        //         longitude: newAddress.geometry.location.lng(),
        //     };
        //     setCurrentAddress(userAddress);
        //     setIsActive(true);
        // }
    };

    const handleChange = (e, id) => {
        const {name} = e;
        if (e === 'location-create') {
            setAddress(id)
        }
        setForms(
            forms.map((form) => (form.id === id ? {...form, [name ? "city" : "address"]: name || e} : form))
          );
    };

    // media

    const removeMedia = (link, callback = () => {}) => {
        // axios
        //   .delete(link, {
        //     headers: {
        //       Authorization: token ? `Bearer ${token}` : "",
        //     },
        //   })
        //   .then(() => {
        //     callback(link);
        //   })
        //   .catch((error) => {
        //     const res = error.response;
        //     switch (res?.status) {
        //       case 401: {
        //         showToast({
        //           message: t("noauth"),
        //           variant: "error",
        //         });
        //         break;
        //       }
        //       case 404: {
        //         callback(link);
        //         break;
        //       }
        //       case 422: {
        //         showToast({
        //           message: t("oops"),
        //           variant: "error",
        //         });
        //         break;
        //       }
        //     }
        //   });
      };
    const uploadMedia = (file, type) => {
        // const formData = new FormData();
        // formData.append("file", file);
        // formData.append("file_name", file.name);
        // formData.append("type", type);
        // return axios.post(baseUrl + `agency/media`, formData, {
        //   headers: {
        //     Authorization: token ? `Bearer ${token}` : "",
        //     "Content-Type": "multipart/form-data",
        //   },
        // });
      };
    

    const handleLocationClick = useHandleLocationClick(
        setFormCity,
        setActiveCountry,
        setBounds,
        setCurrentActive,
        setSelectedCity,
        activeCountry,
        citiesList,
        setIsAddressActive,
        setMapMarker
    );

    const throwError = (e) => {
        if (
            !e.target.classList.contains("icon--location") &&
            !e.target.classList.contains("button--tetriary")
        ) {
            if (!selectedCity) {
                setGeoError(true);

                setTimeout(() => {
                    setGeoError(false);
                }, 2000);
            }
        }
    };


    const onSubmit = () => {

    }


    const formSchema = object().shape({
        // phone: string()
            // .min(14, `${t("phonenumerrequired")}`)
            // .test("phone-is-empty", t("phonenumerrequired"), function () {
                // return !!phoneValue;
            // }),
        // address: string().required(t("required")),
        // book_at: string().min(5, t("required")).required(t("required")),
    });

    const handleSubmitChangeParty = () => {

    }

    const handleCancelChangeParty = () => {

    }

    const handleChangeEmail = (e) => {
        e.preventDefault();
    }

    const handleDescription = (e) => {
        setCurrentDescription(`description.${langAi}.description`, e.target.value, {shouldDirty: true});
    }
 
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: {errors},
        clearErrors,
        watch
    } = useForm({
        mode: "onSubmit",
        resolver: yupResolver(formSchema),
    });

    const EmailLabel = () => {
        const {t, emailVerifyAt} = useLadyService();
      
        return !emailVerifyAt ? (
          <span className="error">Email - {t("notconfirmed")}</span>
        ) : (
          <span>Email</span>
        );
      };

    useEffect(() => {
      setFormCity({
        id: cityIdStatus,
        title: cityStatus,
        icon: activeCountry,
        name: cityStatus,
      });
      
    }, [cityIdStatus, cityStatus]);

    
    

    return (<>
    <h1 className="container justify-start" style={{padding: "150px 12px 0 12px", width: "100%"}}>{t('createorchangeparty')}</h1>
    <div className="container fd-row gap-16" style={{padding: "0 12px 0 12px"}}>
        <div className="left-side" style={{width: "65%"}}>
            <form
                className="fd-column gap-16"
                onClick={(e) => e.stopPropagation()}
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="d-flex gap-16">
                    {/* Party name */}
                    <InputInLabel
                        type="text"
                        id="name_id"
                        placeholder={t("entername")}
                        register={{...register("name")}}
                        onChange={(e) => {
                                setValue(`name`, e.target.value, {shouldDirty: true});
                        }}
                        clazz="w-100"
                        >
                            {t("name")}
                    </InputInLabel>
        
                    {/* Party date */}
                    <InputInLabel
                        clazz={`${errors.date ? " error" : ""}`}
                        type={"date"}
                        id={"orderDate"}
                        register={{...register("date")}}
                        required
                        // onChange={onChangeDate}
                        // value={newDate}
                    >
                        {errors.date ? (
                            <span className={"input-label__error"}>{t("date")}</span>
                        ) : (
                            t("date")
                        )}
                    </InputInLabel>
          
                    
                    {/* Party Time */}
                    <InputInLabel
                        clazz={`${errors.book_at ? " error" : ""}`}
                        register={{...register("book_at")}}
                        registerName={"book_at"}
                        setValue={setValue}
                        id={"orderTime"}
                        placeholder={"18:00"}
                        type={"time"}
                        required
                    >
                        {errors.book_at ? 
                        (
                            <span className={"input-label__error"}>{t("time")}</span>
                        ) : (
                            t("time")
                        )}
                    </InputInLabel>
        
        
                
                </div>
    
                {/* Address  */}


                <div className="fd-row gap-16">

                    <SelectCountry
                        data={citiesList}
                        name={"create-city"}
                        clazz={`${cityError ? "_error" : ""} size-l-forever justify-start select`}
                        clazzSvg={`select__flag ${formCity?.icon || ""}`}
                        arrowSize={"m"}
                        type={"flag"}
                        placeholder={t("city")}
                        defaultTitle={currentAddress.city}
                        subTitle={t("city")}
                        withoutIcon={!currentAddress.city}
                        setValue={setSelectedCity}
                        currentActiveIndex={currentActive}
                        setActiveCountry={setActiveCountry}
                        activeCountry={activeCountry}
                        setActive={setCurrentActive}
                        error={cityError}
                        errorMessage={<ErrorMessage message={cityError}/>}
                        onChange={(e) => {
                            changeCity(e)
                        }}
                        setError={setCityError}
                        setIsAddressActive={setIsAddressActive}
                    />
                    
                    
                    
                    <div className={`search filter__map-input ${
                            currentAddress.city ? "" : "geo-error"
                        }`}
                             onClick={(e) => {
                                 if (isActive) {
                                     throwError(e);
                                 }
                             }}
                        >
                            <span className={"filter__map-title"}>{t("fulladdress")}</span>

                            <label
                                htmlFor="geosuggest__input-create"
                                className="search__label header__search"
                            >
                                <GeoInput
                                    value={currentAddress.address}
                                    setValue={handleChange}
                                    name={"location-create"}
                                    id="geosuggest__input-create"
                                    clazz={`header__search-input ${geoError ? "_error" : ""}`}
                                    placeholder={t("fulladdress")}
                                    bounds={bounds}
                                    isAgency={true}
                                />

                                {geoError && <ErrorMessage message={t("cityfirst")}/>}
                            </label>
                    </div>

                    <Button
                        title={t("chekmyplace")}
                        onClick={()=>{handleLocationClick()
                            }}
                        name={"location"}
                        square={true}
                        size={"l"}
                        clazz={isAddressActive ? "button--tetriary bg-color-500" : "button--tetriary"}
                    >
                        <Icon size={"xl"} spritePath={"location"}/>
                    </Button>
                    
                </div>


                {(primaryAddress && citiesList[activeCountry]?.name) ? (
                    <Map
                        setMarkerActive={setIsAddressActive}
                        markerActive={isAddressActive}
                        region={citiesList[activeCountry].name}
                        city={primaryAddress.city}
                        setBounds={setBounds}
                        coords={{lat: primaryAddress.latitude, lng: primaryAddress.longitude}}
                        setAddress={setAddress}
                    />
                ) : <Loader height={300} decimal={'px'}/>}
        
                <div className="d-grid grid-tp-col-4 gap-16">
                    {/* Phone */}
                    <InputInLabel
                        type={"tel"}
                        id={"create-phone"}
                        placeholder={"+XXX XXX XXX XXX"}
                        register={{ ...register("phone") }}
                        autocomplete={"tel"}
                        required
                        clazz={errors.phone?.message ? "_error" : ""}
                        // maskValue={phoneValue}
                        // setMaskValue={setPhoneValues}
                        // valueLoad={isLoading}
                        // setError={setError}
                        errorType={"phone"}
                        clearErrors={clearErrors}
                        // isModal={isModal}
                    >
                        {t("phonenumber")}
                        {/* {errors.phone && <ErrorMessage message={errors.phone.message} />} */}
                    </InputInLabel>
        
                    <InputInLabel
                        type={"email"}
                        id={"email"}
                        placeholder={"Email"}
                        onChange={(e) => handleChangeEmail(e)}
                        register={{...register("email")}}
                    >
                        <EmailLabel/>
                    </InputInLabel>
        
                    {/* Whatsup */}
                    <InputInLabel
                        type={"tel"}
                        id={"create-whatsapp"}
                        placeholder={""}
                        clazz={errors.whatsapp?.message ? "_error" : ""}
                        register={{ ...register("whatsapp") }}
                        autocomplete={"whatsapp"}
                        // maskValue={waValue}
                        // setMaskValue={setWaValues}
                        // setError={setError}
                        errorType={"whatsapp"}
                        clearErrors={clearErrors}
                    >
                        {"Whatsapp"}
                        {/* {errors.whatsapp && <ErrorMessage message={errors.whatsapp.message} />} */}
                    </InputInLabel>
            
                    {/* telegramm */}
                    <InputInLabel
                        type={"text"}
                        id={"create-telegram"}
                        placeholder={""}
                        register={{ ...register("telegram") }}
                        autocomplete={"telegram"}
                        // value={formData?.telegram || tg}
                        // onChange={(e) => setTg(e.target.value)}
                    >
                        {t("telegram")}
                    </InputInLabel>
                </div>
        
                <h3>{t("partydesc")}</h3>
        
                {/* Party Description */}
                <InputInLabel
                    clazz="gap-16"
                    type="textarea"
                    id="agency-lang"
                    placeholder={t('partydesc')}
                    value={currentDescription}
                    onChange={(e) => {
                        handleDescription(e)
                    }}
                >
                    {t("symbols")}: {currentDescription.length}/400
                    {currentDescription.length === 400 && (
                        <ErrorMessage

                        message={`${t("maxval")} 400 ${t("symbols")}`}
                        />
                    )}
                </InputInLabel>
        
                {/* ai translator */}   
                <div className="d-flex gap-16 align-center mt-16">
                    <LangAi
                        langAi={langAi}
                        setLangAi={handleChangeLangAi}
                        selectedLangsAi={selectedLangsAi}
                        setSelectedLangsAi={setSelectedLangsAi}
                    />
        
                    <Button
                        size="s"
                        // disabled={!isDirty}
                        // buttonType={isDirty ? "submit" : "button"}
                        clazz="button_outline--green-accent justify-center ml-auto w-160"
                    >
                            {t("save")}
                        </Button>
                </div>

                <MediaZone
                  h3={t("agenсycover")}
                  title={t("addaagenсycover")}
                  subtitle={t("prefersize") + " 1440х385 px"}
                  setMedia={setCover}
                  removeMedia={(media) => {
                    removeMedia(media, () => {
                      setCover([]);
                    });
                  }}
                  media={cover}
                  isBigPhoto
                />

                <MediaZone
                    h3={t("interiorphoto")}
                    title={t("interdesc")}
                    subtitle={t("showyourinter")}
                    setMedia={setInterior}
                    removeMedia={(media) => {
                      removeMedia(media, () => {
                        let newInterior = [...interior];
                        newInterior = newInterior.filter((item) => item !== media);
                        setInterior(newInterior);
                      });
                    }}
                    media={interior}
                    isBigPhoto
                    interior={true}
                    isMulti={true}
                  />
        
            </form>
        
                
        
            <div>
   
                  <div className="bottom-buttons mt-12 fd-row gap-16 justify-end">
                    <Button 
                        size="s"
                        clazz="button button--green justify-center w-160"
                        onClick={handleSubmitChangeParty}>
                        {t('save')}
                    </Button>
                    <Button
                        size="s"
                        clazz="button_outline--green-accent justify-center w-160"
                        onClick={handleCancelChangeParty}>
                        {t('cancel')}
                    </Button>
                  </div>
            </div>
        </div>
        <div className="right-side">
             <form>
                 <h3>{t("priceandrules")}</h3>
                 <div className="prices-block fd-row gap-8">
                    <InputInLabel />
                    <InputInLabel />
                 </div>
                 <h3>{t("partyfeature")}</h3>
                 <InputInLabel
                    clazz="gap-16 mb-8"
                    type="name"
                    id="agency-lang"
                    placeholder="feature name"
                    // value={currentDescription}
                    // onChange={(e) => {
                        // setValue(`description.${langAi}.description`, e.target.value, {shouldDirty: true});
                    // }}
                 >
                    {/* {t("symbols")}: {descriptionLength}/400
                    {descriptionLength === 400 && (
                        <ErrorMessage 
                            message={`${t("maxval")} 400 ${t("symbols")}`}
                        />
                    )}
                </InputInLabel>
                <InputInLabel
                    clazz="gap-16 mb-16"
                    type="textarea"
                    id="agency-lang"

                    placeholder="feature description"
                    // value={currentDescription}
                    // onChange={(e) => {
                        // setValue(`description.${langAi}.description`, e.target.value, {shouldDirty: true});
                    // }}
                 >
                    {/* {t("symbols")}: {descriptionLength}/400
                    {descriptionLength === 400 && (
                        <ErrorMessage 
                            message={`${t("maxval")} 400 ${t("symbols")}`}
                        />
                    )} */}
                </InputInLabel>
                <Button
                        size="s"
                        // disabled={!isDirty}
                        // buttonType={isDirty ? "submit" : "button"}
                        clazz="button_outline--green-accent justify-center ml-auto w-160"
                    >
                        {t("addposition")}
                </Button>
                <div className="party-features-list">
                    {/* features.map((feature)=>{
                            return 
                                <div className="party-feature" key={feature.id}>
                                    <h3>{feature.name}</h3>
                                    <p>{feature.description}</p>
                                    <Button onClick={delteFeature(feature.id)}>{t('delete')}</Button>
                                </div>
                        }) */}
                </div>
             </form>

        </div>
    </div>
    </>)
}

export default ChangePartyPage;
