import { useCallback, useEffect, useRef, useState } from "react";
import MultiRangeSlider from "multi-range-slider-react";
import { useDispatch, useSelector } from "react-redux";
import {
  defaultRangeState,
  getIsRangeLoad,
  rangeChange,
  rangeState,
  resetRange,
} from "@/stores/slices/filterSlice";
import "./inputRange.scss";
import { showFilter } from "@/stores/slices/showFilterSlice";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import {
  rangeChangeForModel,
  getPriceForModels,
  getAgeForModels,
  getWeightForModels,
  getHeightForModels,
} from "@/stores/slices/modelsState";
import { isFilterSearch } from "@/stores/slices/filterSlice";
import { getIsMainPage } from "../../../stores/slices/modelsState";
import useLadyService from "../../../services/LadyService";

const InputRangeFilter = (props) => {
  const {
    min,
    max,
    step,
    inputIdLeft,
    inputIdRight,
    title,
    subtitle,
    resetButton = true,
    clazz,
    name,
    price,
  } = props;

  const { lang, city, dispatch, navigate, t, windowWidth } = useLadyService();

  const rangeValue = useSelector(rangeState);
  const defaultRangeValue = useSelector(defaultRangeState);
  const filterOpen = useSelector(showFilter);
  const isRangeLoad = useSelector(getIsRangeLoad);
  const [minValue, setMinValue] = useState(rangeValue[name].min);
  const [maxValue, setMaxValue] = useState(rangeValue[name].max);

  const [maxInput, setMaxInput] = useState(maxValue);
  const [minInput, setMinInput] = useState(minValue);

  const [isResetShow, setIsResetShow] = useState(false);

  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    if (windowWidth !== 0 && windowWidth < 998) {
      setIsHover(true);
    }
  }, [windowWidth]);

  function allValueIsNull(obj) {
    for (let key in obj) {
      if (obj[key].min !== 0 && obj[key].max !== 0) {
        return true;
      }
    }
    return false;
  }

  const updatedPrice = useSelector(getPriceForModels);
  const updatedAge = useSelector(getAgeForModels);
  const updatedWeight = useSelector(getWeightForModels);
  const updatedHeight = useSelector(getHeightForModels);
  const searchActive = useSelector(isFilterSearch);
  const isMainPage = useSelector(getIsMainPage);

  useEffect(() => {
    setMinValue(rangeValue[name].min);
    setMaxValue(rangeValue[name].max);
    setMinInput(rangeValue[name].min);
    setMaxInput(rangeValue[name].max);
    if (searchActive) {

      if (
        name === "price" &&
        updatedPrice.min !== 0 &&
        updatedPrice.max !== 0
      ) {
        setMinValue(updatedPrice.min);
        setMaxValue(updatedPrice.max);
        setMinInput(updatedPrice.min);
        setMaxInput(updatedPrice.max);
      }
      if (name === "age" && updatedAge.min !== 0 && updatedAge.max !== 0) {
        setMinValue(updatedAge.min);
        setMaxValue(updatedAge.max);
        setMinInput(updatedAge.min);
        setMaxInput(updatedAge.max);
      }
      if (
        name === "weight" &&
        updatedWeight.min !== 0 &&
        updatedWeight.max !== 0
      ) {
        setMinValue(updatedWeight.min);
        setMaxValue(updatedWeight.max);
        setMinInput(updatedWeight.min);
        setMaxInput(updatedWeight.max);
      }
      if (
        name === "height" &&
        updatedHeight.min !== 0 &&
        updatedHeight.max !== 0
      ) {
        setMinValue(updatedHeight.min);
        setMaxValue(updatedHeight.max);
        setMinInput(updatedHeight.min);
        setMaxInput(updatedHeight.max);
      }
    }
  }, [filterOpen, rangeValue]);

  const [edit, setEdit] = useState(false);
  const updateRangeValue = useCallback(
    debounce((value) => {
      if (value.minValue !== value.maxValue) {
        dispatch(
          rangeChange({ name, min: value.minValue, max: value.maxValue })
        );
        dispatch(
          rangeChangeForModel({
            name,
            min: value.minValue,
            max: value.maxValue,
          })
        );
        if ((name === "price" || name === "age") && !isMainPage && !filterOpen) {
          if (lang === "en" && city === "czechia") {
            navigate("/");
          } else {
            navigate(`/${lang}/${city}`);
          }
        }
      }
    }, 750),
    []
  );

  const handleInput = useCallback(
    (e) => {
      if (!edit) {
        setMaxInput(e.maxValue);
        setMinInput(e.minValue);
      }
      if (
        !edit &&
        isRangeLoad &&
        allValueIsNull(defaultRangeValue) &&
        isHover
      ) {
        updateRangeValue(e);
      }
      if (bodyRef.current && e.minValue + step >= max) {
        bodyRef.current.querySelector(".thumb-left").style.zIndex = 5;
        bodyRef.current.querySelector(".thumb-right").style.zIndex = 1;
      }
      if (bodyRef.current && e.maxValue - step <= min) {
        bodyRef.current.querySelector(".thumb-left").style.zIndex = 1;
        bodyRef.current.querySelector(".thumb-right").style.zIndex = 5;
      }
      setMinValue(e.minValue);
      setMaxValue(e.maxValue);
      setIsResetShow(minValue > min || maxValue < max ? true : false);
    },
    [minValue, maxValue]
  );

  const resetValues = () => {
    dispatch(resetRange({ name, min, max }));
    setIsResetShow(false);
  };

  const isDisabled = min || max ? false : true;
  const [newMinValue, setNewMinValue] = useState("");
  const [editMinValue, setEditMinValue] = useState(false);
  const updateMinValue = useCallback(
    debounce((inputValue, rangeValue) => {
      const capitalizedStr = name.charAt(0).toUpperCase() + name.slice(1);
      const currentMaxValue =
        bodyRef.current.querySelector(`#max${capitalizedStr}`)?.value ||
        bodyRef.current.querySelector(`#max${capitalizedStr}Filter`).value;
      setEditMinValue(false);
      dispatch(rangeChange({ name, min: inputValue, max: currentMaxValue }));
      dispatch(
        rangeChangeForModel({ name, min: inputValue, max: currentMaxValue })
      );
    }, 750),
    []
  );
  const setMinValueInput = (e) => {
    const value = e.target.value;
    setEdit(true);

    const capitalizedStr = name.charAt(0).toUpperCase() + name.slice(1);

    const currentMaxValue =
      bodyRef.current.querySelector(`#max${capitalizedStr}`)?.value ||
      bodyRef.current.querySelector(`#max${capitalizedStr}Filter`).value;
    setEditMinValue(true);
    setNewMinValue(value);
    if (Number(value) > maxInput) {
      // если мин значение введено больше максимально
      updateMinValue(
        Number(currentMaxValue) - step,
        Number(currentMaxValue) - step
      );
      bodyRef.current.querySelector(".thumb-left").style.zIndex = 5;
      return;
    }
    if (Number(value) > min) {
      e.target.parentElement.classList.add("_active");
    }
    if (Number(value) <= min) {
      e.target.parentElement.classList.remove("_active");
    }
    if (Number(value) < min) {
      // если мин значение введено меньше мин
      updateMinValue(min, value);
      return;
    }
    updateMinValue(value, value);
    setMinInput(value);
    setMinValue(value);

    bodyRef.current.querySelector(".thumb-left").style.zIndex = 1;
  };
  const [newMaxValue, setNewMaxValue] = useState("");
  const [editMaxValue, setEditMaxValue] = useState(false);
  const updateMaxValue = useCallback(
    debounce((inputValue, rangeValue) => {
      const capitalizedStr = name.charAt(0).toUpperCase() + name.slice(1);
      const currentMinValue =
        bodyRef.current.querySelector(`#min${capitalizedStr}`)?.value ||
        bodyRef.current.querySelector(`#min${capitalizedStr}Filter`).value;
      setEditMaxValue(false);
      dispatch(rangeChange({ name, min: currentMinValue, max: inputValue }));
      dispatch(
        rangeChangeForModel({ name, min: currentMinValue, max: inputValue })
      );
    }, 750),
    []
  );
  const setMaxValueInput = (e) => {
    const value = e.target.value;
    setEdit(true);

    setEditMaxValue(true);
    setNewMaxValue(value);
    const capitalizedStr = name.charAt(0).toUpperCase() + name.slice(1);

    const currentMinValue =
      bodyRef.current.querySelector(`#min${capitalizedStr}`)?.value ||
      bodyRef.current.querySelector(`#min${capitalizedStr}Filter`).value;
    if (Number(value) > max) {
      // если максимальное значение введено больше максимального
      updateMaxValue(max, max);
      bodyRef.current.querySelector(".thumb-right").style.zIndex = 1;
      return;
    }
    if (Number(value) < max) {
      e.target.parentElement.classList.add("_active");
    }
    if (Number(value) >= max) {
      e.target.parentElement.classList.remove("_active");
    }
    if (Number(value) < minInput) {
      // если максимальное значение меньше минимального
      updateMaxValue(
        Number(currentMinValue) + step,
        Number(currentMinValue) + step
      );
      bodyRef.current.querySelector(".thumb-right").style.zIndex = 5;
      return;
    }
    updateMaxValue(value, value);
    setMaxInput(value);
    setMaxValue(value);
  };

  const onBlurMinInput = (e) => {
    setEdit(false);
  };

  const onBlurMaxInput = (e) => {
    setEdit(false);
  };

  const handleKeyPressMax = (event) => {
    if (event.key === "Enter") {
      onBlurMaxInput();
    }
  };

  const handleKeyPressMin = (event) => {
    if (event.key === "Enter") {
      onBlurMinInput();
    }
  };

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const bodyRef = useRef(null);
  return (
    <div className={`range${clazz ? ` ${clazz}` : ""}`}>
      <div
        ref={bodyRef}
        className={"range__body"}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className={"range__head"}>
          <div className={"range__title"}>
            {title}
            {subtitle ? <span>{subtitle}</span> : ""}
          </div>
          {resetButton && isResetShow ? (
            <button
              type="button"
              onClick={resetValues}
              className={"range__default"}
            >
              {t("reset")}
            </button>
          ) : null}
        </div>
        {isRangeLoad && (
          <MultiRangeSlider
            min={min}
            max={max}
            step={step}
            stepOnly={!!step}
            className={`range__input ${min && max && min === max && "equal"}`}
            ruler={false}
            label={false}
            disabled={isDisabled}
            minValue={minValue}
            maxValue={maxValue}
            onInput={(e) => {
              if (isHover) {
                handleInput(e);
              }
            }}
          />
        )}
        <div className={"range__inputs"}>
          <label className={`${price ? "input-price" : ""} ${lang === "cz" ? "czk" : ""}`} htmlFor={inputIdLeft}>
            <input
              id={inputIdLeft}
              type="tel"
              step={step}
              value={editMinValue ? newMinValue : minInput}
              autoComplete="off"
              name={name}
              disabled={isDisabled}
              onChange={(e) => setMinValueInput(e)}
              onBlur={(e) => onBlurMinInput(e)}
              onKeyDown={(e) => handleKeyPressMin(e)}
            />
            {editMinValue ? newMinValue : minInput}
          </label>

          <label className={`${price ? "input-price" : ""} ${lang === "cz" ? "czk" : ""}`} htmlFor={inputIdRight}>
            <input
              id={inputIdRight}
              type="tel"
              step={step}
              value={editMaxValue ? newMaxValue : maxInput}
              autoComplete="off"
              name={name}
              disabled={isDisabled}
              onChange={(e) => setMaxValueInput(e)}
              onBlur={(e) => onBlurMaxInput(e)}
              onKeyDown={(e) => handleKeyPressMax(e)}
            />
            {editMaxValue ? newMaxValue : maxInput}
          </label>
        </div>
      </div>
    </div>
  );
};
// type optionsType = {
//     maxValue: number
//     minValue: number
//     setMaxValue: any
//     setMinValue: any
//     size: string
//     color: string
//     min: number
//     max: number
// }
//
// type propsType = {
//     options: optionsType
//     children: React.ReactNode
// }
export default InputRangeFilter;
