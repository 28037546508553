import {Icon, Video} from "@/components/ui";
import {Swiper, SwiperSlide} from "swiper/react";
import {HashNavigation, Navigation, Thumbs} from "swiper/modules";
import React, {useEffect, useRef, useState} from "react";
import {
  setAuthorisationAccessType,
  setAuthorizationStatus,
  setErocontentAccessStatus,
  setGalleryMediaIndex,
  setGalleryStatus,
} from "@/stores/slices/popupSlice";
import useLadyService from "@/services/LadyService";
import {Button} from "../../../ui";
import EroMedia from "../../../pages/accounts/createProfile/pageComponent/stepSection/media/EroMedia";
import EroVideo from "../../../pages/accounts/createProfile/pageComponent/stepSection/media/EroVideo";
import ShowMoreContent from "../../../pages/modelPage/components/showMoreContent/ShowMoreContent";

const TabSliderModel = ({clazz, name, media, ero, setIsOpenEdit, setVisualElem, edit}) => {
  const {dispatch, windowWidth, userType, setMediaSize, t} = useLadyService();

  const sliderRef = useRef(null);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const [sliderIndex, setSliderIndex] = useState(0);

  const [nameValue, setNameValue] = useState(null);
  useEffect(() => {
    setNameValue(name);
  }, [name]);



  const handleClick = (index) => {
    if (userType === "default") {
      dispatch(setAuthorizationStatus(true));
      dispatch(setAuthorisationAccessType("ero"));
    } else if (!ero?.data?.hide) {
      dispatch(setGalleryMediaIndex(index));
      dispatch(setGalleryStatus(true));
    } else {
      dispatch(setErocontentAccessStatus(true));
    }
  };

  const sliderParams = {
    className: `${clazz}__slider tab-slider`,
    slidesPerView: 1,
    spaceBetween: 10,
    modules: [Thumbs, Navigation, HashNavigation],
    hashNavigation: {
      watchState: true,
      replaceState: true,
    },
    ref: sliderRef,
    thumbs: {
      swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
    },
    navigation: {
      nextEl: `.${clazz}__button-next`,
      prevEl: `.${clazz}__button-prev`,
    },

    onActiveIndexChange: function () {
      let index = this.realIndex;
      setSliderIndex(index);
      let current_data = this.slides[index].dataset.hash;
      setCurrentIndex(current_data);
    },

    onTransitionStart: function () {
      if (windowWidth < 599.98) {
        let videos = document.querySelectorAll(
          ".model-main__slider .stories__slide .video video"
        );
        Array.prototype.forEach.call(videos, function (video) {
          video.pause();
        });
      }
    },

    onTransitionEnd: function () {
      if (windowWidth < 599.98) {
        let index = this.realIndex;
        let slide = document.getElementsByClassName("stories__slide")[index];
        let slideVideo = slide.getElementsByTagName("video")[0];
        if (slideVideo != null || slideVideo !== undefined) {
          slideVideo.load();
        }
      }
    },
  };

  const tabsBtns = [
    {
      title: t("photo"),
      icon: "image",
      link: "photo",
    },

    {
      title: t("videofotograph"),
      icon: "film",
      link: "video",
    },

    {
      title: t("interior"),
      icon: "home-main",
      link: "interior",
    },

    {
      title: t("erocontent"),
      icon: "pants",
      ero: true,
      link: "ero",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(tabsBtns[0].link);

  const tabsBtnsTemplate = (btn) => (
    <a
      href={`#${btn.link}`}
      className={`tabs__nav-btn${
        currentIndex === btn.link ? " tabs__nav-btn--active" : ""
      }${btn.ero ? " tabs__nav-btn--erocontent" : ""}`}
    >
      <Icon size={"s"} spritePath={btn.icon}/>

      {btn.ero
        ? windowWidth > 767.98
          ? btn.title
          : btn.title.substring(0, 3)
        : btn.title}
    </a>
  );

  const MediaTemplate = (media, index, hash, type) => {
    const result = () => {
      dispatch(setGalleryMediaIndex(sliderIndex));
      dispatch(setGalleryStatus(true));
    };

    return (
      <SwiperSlide
        className={`model-main__slider stories__slide`}
        data-hash={hash}
        key={index}
        onClick={
          windowWidth > 599.98
            ? () => result()
            : null
        }
      >
        {!!edit && (
          <Button
            square={true}
            size={"s"}
            clazz={`girl-card__remove button--green`}
            onClick={(e) => {
              e.stopPropagation();
              setIsOpenEdit(true);
              if (type === "video") {
                setVisualElem('video');
              } else if (type === "interior") {
                setVisualElem('interior');
              } else {
                setVisualElem('photo');
              }
            }}
          >
            <Icon size={"xl"} spritePath={"edit-2"}/>
          </Button>
        )}

        {type === "video" ? (
          <>
            {windowWidth > 599.98 ? (
              <Icon size={"5xl"} spritePath={"play"}/>
            ) : null}
            <Video
              playButton={windowWidth < 599.98}
              controls={windowWidth < 599.98}
              src={media}
              clazz={"model-main__slider-video"}
              title={`${t("altvideoprofile")} ${nameValue}`}
              alt={`${t("altvideoprofile")} ${nameValue}`}
            />
          </>
        ) : (
            <picture>
              <source media="(max-width: 599px)" srcSet={setMediaSize(media, "m")}/>
              <img src={setMediaSize(media, "l")} alt={t("altphotoprofile", {GirlName: nameValue})}/>
            </picture>
        )}
      </SwiperSlide>
    );
  };

  const MediaTemplateEro = (data, index, hash, type, hide) => {
    const result = () => {
      dispatch(setGalleryMediaIndex(sliderIndex));
      dispatch(setGalleryStatus(true));
    };

    return (
      <SwiperSlide
        className={`model-mail__slider stories__slide${hide ? " model-main__private-slide" : ""}`}
        data-hash={hash}
        key={index}
        onClick={
          hide
            ? () => handleClick()
            : windowWidth > 599.98
              ? () => result()
              : null
        }
      >
        {!!edit && (
          <Button
            square={true}
            size={"s"}
            clazz={`girl-card__remove button--green`}
            onClick={(e) => {
              e.stopPropagation();

              setIsOpenEdit(true);
              setVisualElem('ero');
            }}
          >
            <Icon size={"xl"} spritePath={"edit-2"}/>
          </Button>
        )}

        {hide ? (
          <ShowMoreContent/>
        ) : null}

        {type === "video" ?
          (hide) ? (
            <EroMedia
              src={setMediaSize(data, "l")}
              clazz={"img-cover"}
              title={t("altphotoprofile", {GirlName: nameValue})}
              alt={t("altphotoprofile", {GirlName: nameValue})}
            />
          ) : (
            <>
              {windowWidth > 599.98 ? (
                <Icon size={"5xl"} spritePath={"play"}/>
              ) : null}

              <EroVideo
                playButton={windowWidth < 599.98}
                controls={windowWidth < 599.98}
                clazz={"model-main__slider-video"}
                title={`${t("altvideoprofile")} ${nameValue}`}
                alt={`${t("altvideoprofile")} ${nameValue}`}
                url={data}
              />
            </>
          ) : (
            <>
              <EroMedia
                src={setMediaSize(data, "l")}
                clazz={"img-cover"}
                title={t("altphotoprofile", {GirlName: nameValue})}
                alt={t("altphotoprofile", {GirlName: nameValue})}
              />
            </>
          )}

        {edit ?
          data?.photo_moderated || data?.video_moderated ? null : (
            !ero?.photo_moderated && <span className="ero-status p1 color-main text-center">{t('moderated24hour')}</span>
          ) : null}
      </SwiperSlide>
    );
  };

  const ThumbTemplate = (data, index, type) => (
    <SwiperSlide key={index + 1} className={"stories__slide"}>
      {type === "video" ? (
        <>
          <Icon size={"xl"} spritePath={"play"}/>
          <Video
            playButton={false}
            clazz={"model-main__slider-video"}
            src={data}
            title={`${t("altvideoprofile")} ${nameValue}`}
            alt={`${t("altvideoprofile")} ${nameValue}`}
          />
        </>
      ) : (
        <img
          src={setMediaSize(data, "xs")}
          className={"img-cover"}
          title={t("altphotoprofile", {GirlName: nameValue})}
          alt={t("altphotoprofile", {GirlName: nameValue})}
        />
      )}
    </SwiperSlide>
  );

  const ThumbTemplateEro = (data, index, type) => {
    return (
      <SwiperSlide key={index + 1} className={"stories__slide"}>
        {type === "video" && <Icon size={"xl"} spritePath={"play"}/>}

        <EroMedia
          src={setMediaSize(data, "xs")}
          clazz={"img-cover"}
          title={t("altphotoprofile", {GirlName: nameValue})}
          alt={t("altphotoprofile", {GirlName: nameValue})}
        />
      </SwiperSlide>
    );
  };

  return (
    <div className="tabs">
    <div className="tabs__nav mb-8">
        {!!media?.photo?.length && tabsBtnsTemplate(tabsBtns[0])}

        {!!media?.video?.length && tabsBtnsTemplate(tabsBtns[1])}

        {!!media?.interior?.length && tabsBtnsTemplate(tabsBtns[2])}

        {( !!ero?.data?.video?.length || !!ero?.data?.photo?.length) && tabsBtnsTemplate(tabsBtns[3])}
      </div>

      <Swiper {...sliderParams}>
        {!!media?.photo?.length &&
          media.photo.map((data, index) => MediaTemplate(data, index, tabsBtns[0].link, "photo"))
        }

        {!!media?.video?.length &&
          media.video.map((data, index) => MediaTemplate(data, index, tabsBtns[1].link, "video"))
        }

        {!!media?.interior?.length &&
          media.interior.map((data, index) => MediaTemplate(data, index, tabsBtns[2].link, "interior"))
        }

        {!!ero?.data?.photo?.length &&
          ero.data.photo.map((data, index) => MediaTemplateEro(data, index, tabsBtns[3].link, "image", ero.data.hide))
        }

        {!!ero?.data?.video?.length &&
          ero.data.video.map((data, index) => MediaTemplateEro(data, index, tabsBtns[3].link, "video", ero.data.hide))
        }
      </Swiper>

      {(
           !!media?.photo?.length
        || !!media?.video?.length
        || !!media?.interior?.length
        || !!ero?.data?.photo?.length
        || !!ero?.data?.video?.length
        ) && (
          <div className={`${clazz}__thumb`}>
            <button className={`swiper-button-prev ${clazz}__button-prev`}></button>

            <Swiper
              className={"swiper-thumbs"}
              modules={[Thumbs]}
              watchSlidesProgress
              onSwiper={setThumbsSwiper}
              slidesPerView={"auto"}
              spaceBetween={5}
            >
              {!!media?.photo?.length &&
                media.photo.map((data, index) => ThumbTemplate(data, index))
              }

              {!!media?.video?.length &&
                media.video.map((data, index) => ThumbTemplate(data, index, "video"))
              }

              {!!media?.interior?.length &&
                media.interior.map((data, index) => ThumbTemplate(data, index))
              }

              {!!ero?.data?.photo?.length &&
                ero.data.photo.map((data, index) => ThumbTemplateEro(data, index, "image"))
              }

              {!!ero?.data?.video?.length &&
                ero.data.video.map((data, index) => ThumbTemplateEro(data, index, "video"))
              }
            </Swiper>

            <button className={`swiper-button-next ${clazz}__button-next`}></button>
          </div>
        )}
    </div>
  );
};

export default TabSliderModel;