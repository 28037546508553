import example1 from "@/assets/img/examplePhotos/verExample.svg";
import {Icon} from "@/components/ui";
import {useEffect, useState} from "react";
import "./Verification.scss"
import "@/components/pages/accounts/model/profiles/pageComponets/verAds/verAds.scss";

import {ExampleImage, UploadImage} from "../../pages/accounts/model/profiles/pageComponets";
import PhotoUploadSuccess
    from "../../pages/accounts/model/profiles/pageComponets/photoUploadSuccess/PhotoUploadSuccess";
import Popup from "../Popup";
import useLadyService from "../../../services/LadyService";
import {MediaZone} from "../../pages/accounts/agency/mediaZone/MediaZone";
import axios from "axios";
import showToast from "../../toast/Toast";
import baseUrl from "../../../services/apiConfig";
import {Button} from "../../ui";

const Verification = ({
                          open,
                          setOpen,
                          popup = true,
                          verified_at,
                          verifyMedia,
                          setVerifyMedia,
                          verifyStatus,
                          setVerifyStatus,
                          slug,
                          setCurrentType = false,
                          setCurrentSlug = false,
                          isBoosted = false,
                          setIsBoosted = false
                      }) => {

    const {t, windowWidth, token} = useLadyService()

    const isMobile = windowWidth < 767.98;
    const mediaData = verifyMedia?.media ? verifyMedia.media.data || {} : {};
    const {link} = mediaData;

    const [isVerificationExist, setIsVerificationExist] = useState(verifyStatus && isBoosted && verifyStatus === 1 && isBoosted === 1);
    const [verifyImageFile, setVerifyImageFile] = useState(false);
    const [verifyImage, setVerifyImage] = useState(link && verifyStatus !== 3 ? [link] : []);
    const [verifyImageUploaded, setVerifyImageUploaded] = useState(link && verifyStatus !== 3);


    const uploadMedia = async (file, type) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("file_name", file.name);
        formData.append("type", type);
        formData.append('profile', slug)
        try {
            await axios.post(baseUrl + `agency/media`, formData, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : "", "Content-Type": "multipart/form-data",
                }
            }).then(r => {
                setVerifyStatus(1)
                setVerifyImage([r.data.link])
                setVerifyMedia({media: {data: {link: r.data.link}}})
                setVerifyImageUploaded(true)
            }).catch(() => {
                setVerifyImageUploaded(false)
            })
            showToast({message: t('success'), variant: 'success'})
        } catch (e) {
            showToast({message: t('oops'), variant: 'error'})
        }
    }


    useEffect(() => {
        if (verifyImageFile && verifyImageFile.length && typeof verifyImageFile[0] === "object") {
            const file = verifyImageFile[0];
            const reader = new FileReader();
            reader.onload = function (e) {
                setVerifyImage([e.target.result]);
            };
            reader.readAsDataURL(file);
        } else if (verifyImageFile === undefined || verifyImageFile === null) {
            setVerifyImage([])
            setVerifyImageFile(false)
            setIsBoosted(false)
        }
    }, [verifyImageFile]);
    
    const removeMedia = (link, callback = () => {
    }) => {
        axios.delete(link, {
            headers: {
                Authorization: token ? `Bearer ${token}` : ""
            }
        }).then(() => {
            callback(link)
            setIsBoosted(false)
        }).catch((error) => {
            const res = error.response;
            switch (res?.status) {
                case 401: {
                    showToast({
                        message: t("noauth"),
                        variant: "error",
                    });
                    break;
                }
                case 404: {
                    callback(link)
                    break;
                }
                case 422: {
                    showToast({
                        message: t("oops"),
                        variant: "error",
                    });
                    break;
                }
                default:{
                    break;
                }
            }
        })
    }

    const Body = () => {
        return (
            <div id={"verification"} className={`${isMobile ? "" : "verads"} p-24`}>
                <div className="verads__title mt-16 mb-32">
                    <div className="d-flex fd-column">
                        <h3 className="mt-0 mb-0">{t("adsver")}</h3>
                        <span className="p1">{t("chekingphoto")}</span>
                    </div>

                    <span
                        title={t("photosverified")}
                        className={`${isMobile ? "" : "ml-auto"} model__icon`}
                    >
                        <Icon spritePath={"verify-fill"} size={"l"}/>
                        {t("verification")}
                        </span>{" "}</div>
                        {!verifyImageFile && (!verifyImage?.length || verifyStatus === 3) ? (
                    <div className="verads__photo mb-32">

                        <div className="verads__examplePhoto text-center">
                            {t("example")}
                            <br></br>
                            {t("idphoto")}
                            <img src={example1} alt={t("example")}/>
                        </div>

                        <MediaZone
                            isH3={false}
                            title={t("idphotoupload")}
                            subtitle={t("photodisplay")}
                            setMedia={setVerifyImageFile}
                            removeMedia={(media) => {
                                removeMedia(media, () => {
                                    setVerifyImageFile(false)
                                    setVerifyImage([])
                                })
                            }}
                            media={verifyImage}
                            isBigPhoto
                            verAdIndiPopup = {true}
                        />
                    </div>
                ) : (
                    <div className="verads__photo mb-32">
                        <ExampleImage
                            type={"superId"}
                            image={verifyImage}
                            setImage={(val) => {
                                if (val === null) {
                                    setVerifyImage([])
                                    setVerifyImageFile(false)
                                }
                            }}
                            removeCallback={() => {
                                setVerifyMedia([])
                                setVerifyImage([])
                                setVerifyStatus(0)
                                setIsBoosted(false)
                            }}
                            removeLink={verifyImage}
                        />
                        {verifyImageUploaded ? (
                            <PhotoUploadSuccess
                                isCreatePage={false}
                                moderatedVerify={verifyStatus}
                                type={"photo"}
                                at={verified_at}
                                setCurrentSlug={setCurrentSlug}
                                setCurrentCallback={() => {
                                    if (setIsBoosted) {
                                        setIsBoosted(true)
                                    }
                                }}
                                setCurrentType={setCurrentType}
                                slug={slug}
                                setIsVerificationExist={setIsVerificationExist}
                                isVerificationExist={isVerificationExist}
                                isBoosted={isBoosted}
                                setIsBoosted={setIsBoosted}
                            />
                        ) : (
                            <div className="upload__zone cursor-default">
                                <div className="d-flex gap-8 align-center">
                                    <i title="" className="icon icon-l icon--check color-green"></i>
                                    <div className="d-flex gap-4 fd-column"><span
                                        className="p1 color-green">{t("idphoto") + " " + t("uploaded")}</span></div>
                                </div>
                                <Button
                                    title={t("sendtomod")}
                                    clazz={"button--green justify-center min-w-160 mt-12"}
                                    size={"m"}
                                    onClick={() => {
                                        uploadMedia(verifyImageFile[0], 'verify')
                                    }}
                                >
                                    {t("sendtomod")}
                                </Button>
                            </div>
                        )}
                    </div>
                )}

                <div className={"verads__secure mt-0 mb-0 p2"}>
                    <Icon size={"4xl"} spritePath={"secure"}/>
                    {t("confyourimg")}
                </div>
            </div>
        )
    }
    return popup ? (
        <Popup
            open={open}
            setOpen={setOpen}
            clazz={'gap-16 verify-modal'}
        >
            <Body/>
        </Popup>
    ) : (<Body/>)
}

export default Verification;
