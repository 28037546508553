import "./agencyList.scss";
import AgencyCard from "../../cards/agencyCard/AgencyCard";
import { ButtonLink, Icon } from "@/components/ui";
import Notifications from "../../notifications/Notifications";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getUserCountry,
  getUserCurrentCity,
  getUserLang,
} from "@/stores/slices/userSlice";
import { useTranslation } from "react-i18next";
import { makeRequest } from "@/services/makeRequest";
import { getAgencies } from "../../../stores/slices/agencySlice";
import useTitle from "@/hooks/useTitle";
import {Helmet} from "react-helmet";
import useLadyService from "../../../services/LadyService";




const AgencyList = () => {

  const agencies = useSelector(getAgencies)
  const { t } = useTranslation("translation");
  const lang = useSelector(getUserLang);
  const [page, setPage] = useState(1);
  const [data, setData] = useState(agencies);
  const [metaData, setMetaData] = useState(null);
  const {region} = useLadyService()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const route = "agencies";
        const method = "GET";
        const payload = {
          page,
        };

        const data = await makeRequest({ route, method, payload });
        const result = data.agencies;
        const metaData = data.meta;
        if (result) {
          setData(result);
          setMetaData(metaData);
        }
      } catch (error) {}
    };

    lang && fetchData();
  }, []);

  useTitle(t("titleAgencies", {Country: region}));

  return (
    <main className={"agencies"}>
      <Helmet>
        <meta name="description" content={t("descAgencies", {Country: region})} />
      </Helmet>
      <section className={"agencies__container"}>
        <div className="navigation__top">
          <h1>
            {t("h1Agiencies", {Country: region})}
          </h1>
          <div className={"navigation__top-wrapper"}>
            <ButtonLink
              clazz={`button_outline--black _no-underline agencies__map`}
              size={"s"}
            >
              <span>{t("onmap")}</span> <Icon size={"m"} spritePath={"map"} />
            </ButtonLink>
            <Notifications />
          </div>
        </div>

        <div className="grid-cards">
          {data?.length > 0 &&
            data.map((data) => <AgencyCard props={data} key={data.id} />)}
        </div>
      </section>
    </main>
  );
};

export default AgencyList;
