import {Icon, Button} from "@/components/ui";

import useLadyService from "@/services/LadyService";

import {setFastVerStatus} from "@/stores/slices/popupSlice";
import moment from "moment";
import {setRequestVerStatus} from "@/stores/slices/popupSlice";
import ".././waitingAdmin/waitingAdmin.scss";

const PhotoUploadSuccess = ({
                                moderatedHealthy = false,
                                moderatedVerify = false,
                                isHealthBoosted,
                                type,
                                at = null,
                                setCurrentType = false,
                                setCurrentCallback = false,
                                setCurrentSlug = false,
                                slug = false,
                                healthy_reason = "",
                                isCreatePage = false,
                                setIsVerificationExist,
                                isVerificationExist,
                                isBoosted,
                                setIsBoosted
                            }) => {
    const {t, dispatch, userType} = useLadyService();

    const isTypeAgency = userType === "agency";
    const isTypeAnaliz = type === "analiz";
    const sentenceAnaliz = !isHealthBoosted
        ? t("fastmoderatedinfo")
        : t("fastedmoder") + " ~ 1 " + t("hour");
    const sentencesArray = sentenceAnaliz.split(". ");
    const firstSentence = sentencesArray[0];
    const secondSentence = sentencesArray[1];

    const isPhotoDecline = isTypeAgency ? moderatedHealthy === 3 || moderatedVerify === 3 : moderatedHealthy === 2 || moderatedVerify === 2;

    


    // const verified = moment
    //     .unix(at || moment().add(1, "year").unix())
    //     .utc()
    //     .format("DD.MM.YYYY");

    const verified = moment
        .unix(at || moment().unix()).add(1, 'year')
        .utc()
        .format("DD.MM.YYYY");

    const sentence =
        type === "photo"
            ? t("idphoto") + " " + t("uploaded")
            : t("superidphoto") + " " + t("uploaded");

    return (
        <div className="speedup-moderation p-24">
            <div className="d-flex gap-8 align-center">
                {!isTypeAnaliz && !isPhotoDecline && (
                    <Icon
                        title={""}
                        spritePath={"check"}
                        size={"l"}
                        clazz={"color-green"}
                    />
                )}
                {isTypeAnaliz && at && at !== null && (
                    <Icon
                        title={""}
                        spritePath={"check"}
                        size={"l"}
                        clazz={"color-green"}
                    />
                )}
                <div className="d-flex gap-4 fd-column">
                    {!isTypeAnaliz && !isPhotoDecline && (
                        <span className="p1 color-green">{sentence}</span>
                    )}
                    {!isPhotoDecline && isTypeAnaliz && !at && (
                        <span className="p1 color-green">{t("tested")}</span>
                    )}
                    {isTypeAnaliz && at && at !== null && (
                        <span className="p1 color-green">{t("publishedono")}</span>
                    )}
                    {isPhotoDecline && (
                        <span className="p1 color-red-700 text-center">{t("declinedbymoderator")}</span>)}
                    {!isTypeAgency && !isTypeAnaliz && !isPhotoDecline && (
                        <span className="p1 color-main">{t("valid") + " " + t("until") + " " + verified}</span>)}
                    {!isTypeAgency && isTypeAnaliz && at && at !== null && (
                        <span className="p1 color-main">{t("valid") + " " + t("until") + " " + verified}</span>)}
                </div>
            </div>
            {isTypeAgency && (
            <div className="d-flex gap-4 fd-column text-center">
                {moderatedVerify === 2 && (<span className="p1 color-main mt-12">{t("valid") + " " + t("until") + " " + verified}</span>)}
                {/* { !isTypeAnaliz && moderatedVerify === 0 && !isBoosted ? (
                    <span className="p1 color-main mt-12">{t("waitforapprovalShort")}</span>
                ) : null}
                {!isTypeAnaliz && moderatedVerify === 0 && !isBoosted ? (
                    <span className="p1 color-main mt-12">{t("fastmoderatedinfoShort")}</span>
                ) : null} */}
                {!isTypeAnaliz && moderatedVerify === 1 && isBoosted ? (
                    <span className="p1 color-main mt-12">{t("fastedmoder") + ` ~ 1 ` + t("hour")}</span>
                ) : null}
            </div>
            )}

            {/* {!isPhotoDecline && isTypeAnaliz && at === null && !isPhotoDecline && (
                <p className="color-main">{firstSentence}</p>
            )}
            {isTypeAnaliz && at === null && !isHealthBoosted && !isPhotoDecline && (
                <p>{secondSentence}</p>
            )} */}
            {isPhotoDecline && (
                <div className="text-center">
                    <p className="color-700">{healthy_reason}</p>
                    <span>{t("fixandtry")}</span>
                </div>
            )}

            {!isPhotoDecline && isTypeAnaliz && at === null && !isHealthBoosted && !isTypeAgency && (
                <Button
                    clazz={`ver__block button--primary`}
                    size="s"
                    onClick={() => {
                        if (setCurrentType) {
                            setCurrentType("healthy");
                        }
                        dispatch(setFastVerStatus(true));
                    }}
                >
                    {t("fasttrack")}
                </Button>
            )}

            {isTypeAgency && !isBoosted && (

                <>
                    <span className="p1 text-center">{t('waitforapproval')}</span>
                    <Button
                        clazz={`ver__block button--primary mt-12`}
                        size="s"
                        onClick={() => {
                            if (setCurrentType) {
                                setCurrentSlug(slug)
                                if (setCurrentCallback) {
                                    setCurrentCallback()
                                }
                                setCurrentType(type === 'photo' ? "verification" : "healthy");
                            }
                            dispatch(setFastVerStatus(true));
                        }}
                    >
                        {t("fasttrack")}
                    </Button>
                </>
            )}
            {!isTypeAgency && !isTypeAnaliz && !isPhotoDecline && (
                <p className="text-center">{t("nowyoucan")}</p>
            )}
            {isCreatePage && !isBoosted ? (
                <Button
                    clazz={`${isVerificationExist ? "button_outline--green-accent" : "button--green"}`}
                    size="s"
                    onClick={() => {
                        if (!isVerificationExist) {
                            setIsVerificationExist(true)
                            dispatch(setRequestVerStatus(true));
                        } else {
                            dispatch(setFastVerStatus(true));
                            setIsBoosted(true)
                        }
                    }}
                >
                    {isVerificationExist || isTypeAgency ? t('fasttrack') : t("request ")}
                </Button>
            ) : null}
            {isTypeAnaliz && at && at !== null && (
                <p className="text-center">{t("autohelth")}</p>
            )}
        </div>
    );
};

export default PhotoUploadSuccess;
