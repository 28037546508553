// import { Link } from "react-router-dom";

// import img from "../../../assets/img/agency/banner-cover-mob.webp";

// import "./partyCard.scss";
// import { Icon } from "@/components/ui";
// import { useDispatch } from "react-redux";
// import { setPartyAccess } from "@/stores/slices/popupSlice";
// import useLadyService from "@/services/LadyService";
// import { useTranslation } from "react-i18next";
// const PartyCard = ({ props, promo }) => {
//   const { lang } = useLadyService();

//   const {
//     privat_party,
//     description,
//     models_count,
//     image = img,
//     address,
//     price,
//     date,
//     agency_id,
//     coordinates,
//     end_time,
//     id,
//     includes,
//     name,
//     prepayment,
//     start_time,
//     preview
//   } = props;

//   console.log('data', props)

//   const dispatch = useDispatch();

//   const { t } = useTranslation("translation");

//   const View = (props) => (
//     <>
//       {(image || preview) && (
//         <div title={name} className="agency-card__img">
//          {promo ? <img src={preview} alt={name} /> : <img src={image} alt={name} />}
//         </div>
//       )}


//       <div className="agency-card__content">
//         <div className="agency-card__heading">
//           <div className={"party-card__info"}>
//             <span className="party-card__time">
//               {!privat_party ? start_time : "******"}

//               {!privat_party ? (
//                 <span className="agency-card__date">{date}</span>
//               ) : (
//                 <span className="agency-card__date">******</span>
//               )}
//             </span>
//             <span className="party-card__address">
//               {!privat_party ? address : "******"}

//               {/*<span className="party-card__country">*/}
//               {/*     <Icon type={'flag'} size={'s'} spritePath={'CZ'}/>*/}
//               {/*    /!*{city}*!/*/}
//               {/* </span>*/}
//             </span>
//           </div>

//           {name && <span className="title_h3">{name}</span>}
//         </div>

//         <div className="agency-card__counts">
//           {models_count ? <div className="agency-card__count">
//             <Icon size={"l"} spritePath={"body2"} />
//             <div className="agency-card__questionnaires">
//               {/*{models_count} */}
//               <span>{t("modeles")}</span>{models_count || 0}
//             </div>
//           </div>: null}
//           {price && (
//             <span className="p1">{`${t("participation")} - ${price?.substring(
//               0,
//               price.length - 3
//             )}€`}</span>
//           )}
//         </div>

//         {description && <p>{description}</p>}
//       </div>
//     </>
//   );

//   switch (privat_party) {
//     case true:
//       return (
//         <div
//           className={"agency-card party-card party-card--private"}
//           onClick={() => dispatch(setPartyAccess(true))}
//         >
//           {View()}
//         </div>
//       );

//     default:
//       return (
//         <Link to={promo ? `#placeholder` : `/party/${id}`} className={"agency-card party-card"}>
//           {View()}
//         </Link>
//       );
//   }
// };

// export default PartyCard;


import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPartyAccess } from "@/stores/slices/popupSlice";
import useLadyService from "@/services/LadyService";
import { Icon, Button } from "@/components/ui";
import React from "react";
import showToast from "../../toast/Toast";
import Gallery from "../../popups/gallery/Gallery";
import {setGalleryStatus} from "@/stores/slices/popupSlice";



const PartyCard = ({party}) => {
    const dispatch = useDispatch();
    const { t, navigate } = useLadyService();
   

    const {
        name, 
        cover, 
        start_time, 
        date, 
        address, 
        price, 
        description, 
        private_party, 
        models_count, 
        id, 
        banner,
        preview
    } = party

   

  

    const View = ( ) => {
        return(
        <>
            <a href="#" title={name} className={`party-card__img`}>
              {<img src={cover} alt={name} />}
            </a>
    
            
            <div className="party-card__content"> 
             <div className="party-card__heading"> 
               <div className={"party-card__info"}> 
                 <span className="party-card__time"> 
                   {!private_party ? start_time : "******"} 
      
                   {!private_party ? ( 
                    <span className="party-card__date">{date}</span>
                  ) : (
                    <span className="party-card__date">******</span>
                  )}
                 </span> 
                 {/* <span className="party-card__address"> 
                   {!private_party ? address : "******"} 
      
                    
                 </span>  */}
               </div> 
      
               {name  && <h3>{name}</h3>} 
             </div> 
      
             <div className="party-card__counts"> 
               <div className="party-card__count color-main"> 
                 <Icon size={"l"} spritePath={"body2"} /> 
                 <div className="party-card__questionnaires"> 
                   
                   <span>{`${models_count ?? 0} ${t("modeles")}`}</span> 
                 </div> 
               </div> 
               {price > 0 && ( 
                <span className="color-main ">{`${t("participation")} - `}
                    <span className="color-green currency--erocoin">{price}</span>
                </span>
              )}
             </div>
      
            
               {description && <p className="color-main mt-20">{description}</p>} 
  
               <Button 
                clazz={'button button-s button--primary w-100 bottom-0'}
                onClick={handlePartyAccess}>
                   {t('moreinfo')}
               </Button>
            

            </div>

        </>)
    }
    


    const handlePartyAccess = () => {
       dispatch(setPartyAccess(true));
    }
    
    switch (party.private_party) {
        case true: 
            return (<>
                <div
                  className={"party-card party-card--private"}
                >
                   {View(party)} 
                  
                 </div>
            </>)
    default:{
        return (<>
            <div
              className={"party-card"}
            >
                   {View(party)}
            </div>
        </>)
    }
    }
}


export default PartyCard