import { useDispatch } from "react-redux";
import React from "react";
import {setGalleryStatus} from "@/stores/slices/popupSlice";


const PromoCard = ({promo, setGalleryMedia}) => {
    const dispatch = useDispatch();

    const {
        name, 
        banner,
        preview
    } = promo

    const galleryMedia = {
      photo: promo ? (banner ? [...banner] : [...preview]) : []
    }


    const openPromo = ( ) => {
      setGalleryMedia(galleryMedia)
      dispatch(setGalleryStatus(true))
    }

    const View = ( ) => {
        return(
          <a title={name} className={`d-block party-card__img promo`} onClick={openPromo}>
            {<img src={preview[0]} alt={name} />}
          </a>           
        )
    }
    
    
  return (
    <div
      className={"party-card"}
    >
      {View(promo)}
    </div>)
}


export default PromoCard