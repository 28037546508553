import Navigation from "../navigation/Navigation";
import GirlMap from "../girlMap/GirlMap";
import AboutService from "../aboutService/AboutService";
import BenefitsBlock from "../benefitsBlock/BenefitsBlock";
import StoriesComponent from "../stories/StoriesComponent";
import {useDispatch, useSelector} from "react-redux";
import {getStories} from "@/stores/slices/modelsState";
import {useEffect, useState} from "react";
import useLadyService from "@/services/LadyService";
import {setVideoCallRoom} from "@/stores/slices/videoCallSlice";
import {useParams} from "react-router-dom";
import {getProfilesCount} from "@/stores/slices/mapModelSlice";
import useTitle from "@/hooks/useTitle";
import {getUserCurrentCity, getUserRegion} from "@/stores/slices/userSlice";
import {getTitle} from "@/stores/slices/titleSlice";
import {Helmet} from "react-helmet";
import {getAllSorted} from "../../stores/slices/modelsState";
import {filterPlace, getFastFilters, isFilterSearch} from "../../stores/slices/filterSlice";
import BestCityAgencySlider from "../sliders/bestCityAgencySlider/BestCityAgencySlider";
import {getCountrySlug} from "../../stores/slices/userSlice";
import {Selection} from "./index";

const MainPage = () => {
    const dispatch = useDispatch();
    const {t, userCity} = useLadyService();
    const {room} = useParams();
    const stories = useSelector(getStories);
    const [isDataEmpty, setIsDataEmpty] = useState(false);
    const profilesCountValue = useSelector(getProfilesCount);
    const currentCity = useSelector(getUserCurrentCity);
    const title = useSelector(getTitle);
    const region = useSelector(getUserRegion);

    useEffect(() => {
        if (room) {
            dispatch(setVideoCallRoom({room_id: room}));
        }
    }, [dispatch, room]);
    const countrySlug = useSelector(getCountrySlug)
    let postTitle = t("title", {City: currentCity.title ? currentCity.title : "", CountryCode: 'cz'});
    let postDesc = t("description", {City: currentCity.title, Country: region})
    if (countrySlug) {
        postTitle = t("titlecountry", {Country: currentCity.title, CountryCode: 'cz'});
        postDesc = t("descriptioncountry", {Country: currentCity.title})
    }
    const titleString = !!title.length
        ? title
            .filter((item) => !!item)
            .map((item) => t(item))
            .join(", ")
        : "";

    const titleParams = titleString
        ? `${userCity.title} - ${t("escortTitle")}: ${titleString}`
        : postTitle;

    useTitle(titleParams);

    if (titleString) {
        const descCity = t("incity", {City: currentCity.title ?? ""})
        postDesc = `${t('titleselect')}: ${titleString} - ${descCity}`
    }

    const valueNavigation = useSelector(getAllSorted);
    const valuePlace = useSelector(filterPlace);
    const isFilterValue = useSelector(isFilterSearch);
    const fastFilters = useSelector(getFastFilters);
    const [isRenderAbout, setIsRenderAbout] = useState(!fastFilters);
    useEffect(() => {
        const isRes = !!valueNavigation.length || !!valuePlace.length || isFilterValue;
        setIsRenderAbout(!isRes);
    }, [valueNavigation, isFilterValue, valuePlace]);
    useTitle(titleParams)
    const isJsdom = typeof window !== 'undefined' && window.navigator.userAgent.includes('jsdom');
    return (
        <>
            <main>
                <Helmet>
                    <meta name="description" content={postDesc}/>
                </Helmet>
                <Navigation setIsDataEmpty={setIsDataEmpty} isDataEmpty={isDataEmpty} mainPage={true}/>
                {/*<NewGirlsSlider />*/}
                {(!isDataEmpty && !!profilesCountValue) || isJsdom ? ( <GirlMap/>) : null}
                <BestCityAgencySlider/>
                {(countrySlug) && <AboutService/>}
                {(countrySlug) && <BenefitsBlock/>}
                {(!countrySlug) && <Selection isPage={false}/>}
            </main>

            {!!stories.length && (
                <StoriesComponent
                    favoriteButton
                    descriptionText
                    profileLink
                    stories={stories}
                />
            )}
        </>
    );
};

export default MainPage;
