import {
    BurgerMenu,
    DropdownLanguage,
    FavoriteButton,
    Filters,
    Logo,
    MenuNavBar,
    SelectCity,
} from "../components";
import ShortFilter from "@/components/shortFilter/ShortFilter";
import React from "react";
import {Burger, Button, ButtonLink, Dropdown, Icon, SwitchTheme, SelectCountry} from "@/components/ui";
import {setAuthorisationAccessType, setAuthorizationStatus} from "@/stores/slices/popupSlice";
import useLadyService from "@/services/LadyService";
import {useSelector} from "react-redux";
import {getScrolledClass} from "@/stores/slices/headerSlice";

const Default = () => {
    const {
        dispatch,
        userType,
        t,
        windowWidth,
        lang
    } = useLadyService();

    const scrolledClass = useSelector(getScrolledClass);

    const infoLinks = [
        {id: 1, link: `/${lang}/about`, name: t("about")},
        {id: 2, link: `/${lang}/guide`, name: t("guide")},
        // { id: 3, link: `/faq`, name: t("faq") },
        {id: 4, link: `/lk/support`, name: t("support")},
    ];
    const isJsdom = typeof window !== 'undefined' && window.navigator.userAgent.includes('jsdom');
    const LogInButton = () => (
        <Button
            onClick={() => dispatch(setAuthorizationStatus(true))}
            size={"s"}
            clazz="button--green user__log-in"
        >
            {t("login")}
        </Button>
    );

    if (windowWidth > 1199.98 || isJsdom)
        return (
            <>
                <div className="header__top">

                    <RegistrationLinkIndi
                        buttonSize={'xs'}
                        iconSize={'s'}
                    />

                    <Dropdown
                        buttonClass={"_no-fill"}
                        size={"xs"}
                        title={t("forusers")}
                    >
                        {infoLinks.map((i) => {
                            if (i.id === 4 && userType === "default") {
                                return (
                                    <ButtonLink
                                        onClick={(e) => {
                                            e.preventDefault();
                                            dispatch(setAuthorisationAccessType("support"));
                                            dispatch(setAuthorizationStatus(true));
                                        }}
                                        href={i.link}
                                        clazz={"language"}
                                        title={i.name}
                                        size={"xs"}
                                        key={i.id}
                                        children={i.name}
                                    />
                                );
                            }

                            return (
                                <ButtonLink
                                    href={`${i.link}`}
                                    clazz={"language"}
                                    title={i.name}
                                    size={"xs"}
                                    key={i.id}
                                    children={i.name}
                                />
                            );
                        })}
                    </Dropdown>

                    <DropdownLanguage/>

                    <SwitchTheme type={"switch"}/>
                </div>

                <div className="header__bottom">
                    <Logo scrolledClass={scrolledClass}/>

                    <SelectCity/>

                    <Filters/>

                    <div className="user__wrapper">
                        {!isJsdom ? <FavoriteButton size={"s"}/> : null}

                        <LogInButton/>
                    </div>
                </div>
            </>
        );

    if (windowWidth > 767.98)
        return (
            <div className="header__bottom">
                <Logo scrolledClass={scrolledClass}/>

                <SelectCity/>

                <Filters/>

                <div className="user__wrapper">
                    <FavoriteButton size={"s"}/>

                    <RegistrationLinkIndi
                        buttonSize={'s'}
                        iconSize={'l'}
                        square
                    />

                    <LogInButton/>

                    <DropdownLanguage size={"s"}/>

                    <Burger/>

                    <SwitchTheme type={"switch"}/>

                    <BurgerMenu/>
                </div>
                <ShortFilter/>
            </div>
        );

    return (
        <div className="header__top">
            <div className="user__wrapper">
                <Burger/>

                <DropdownLanguage/>
            </div>

            <Logo scrolledClass={scrolledClass}/>

            <div className="user__wrapper">
                <RegistrationLinkIndi square/>

                <SwitchTheme type={"switch"}/>
            </div>

            <BurgerMenu/>

            <MenuNavBar/>

            <ShortFilter/>
        </div>
    );
};

const RegistrationLinkIndi = ({buttonSize = "xs", iconSize = 'l', square}) => {
    const {t} = useLadyService();
    return (
        <ButtonLink
            href={`/registration#indi`}
            title={t("add")}
            size={buttonSize}
            clazz={"add-form button_outline--green _no-underline"}
            square={square}
        >
            {!square && t("add")}

            <Icon size={iconSize} spritePath={"plus"}/>
        </ButtonLink>
    )
}

export default Default