import React, { useEffect, useState } from "react";
import Header from "../../header/Header";
import { Banner, Breadcrumbs, ButtonLink, ShowMoreText } from "@/components/ui";
import { useSelector } from "react-redux";
import axios from "axios";
import { getSelectedCount } from "@/stores/slices/agencyFilterSlice";
import {
  setGalleryMediaIndex,
  setGalleryStatus,
} from "@/stores/slices/popupSlice";
import baseUrl from "@/services/apiConfig";
import { SwiperSlide } from "swiper/react";
import "../agency/agencyPage.scss";
import "./partyPage.scss";
import useLadyService from "@/services/LadyService";
import { useParams } from "react-router-dom";
import { makeRequest } from "@/services/makeRequest";
import { useTranslation } from "react-i18next";
import { Button, Icon, Loader, Toggle } from "@/components/ui";
import PartyCard from "../../cards/partyCard/PartyCard";

const PartyPage = (props) => {
  const { dispatch, lang, city, navigate, isJsdom } = useLadyService();
  const { t } = useTranslation("translation");
  const parties = []
  const party = {}

  const {
    name, 
    image, 
    start_time, 
    date, 
    address, 
    price, 
    description, 
    private_party, 
    cover,
    models_count, 
    id,
    prepayment, 
    end_time,
    includes, 
    media={}
  } = party;

  const PartiesWrapper = ({parties}) => {
    return (
     <div className="parties-slider-wrapper">
           {parties && parties.map((party) =>{
             return <PartyCard party={party}/>
           })}
     </div>)
 }


  const breadcrumbsList = [
    {
      id: 1,
      title: t('mainpage'),
      link: "/",
    },
    {
      id: 2,
      title: t('events'),
      link: "#",
    },
    {
      id: 3,
      title: name ?? 'palceholder',
      link: "",
    },
  ];
  const { partyId } = useParams();
  const [onePartyData, setOnePartyData] = useState(null);

  const fetchParty = async (partyId) => {
    try {
      const route = `parties/${partyId}`;
      const method = "GET";

      const data = await makeRequest({ route, method });
      if (!data) {
        navigate("/404");
      }
      if (data) {
        const result = data.data;
        setOnePartyData(result);
        console.log('party', result);
      }
    } catch (error) {}
  };

  const fetchParties = async () => {
    //const resultJson = JSON.parse(dataJson);
    //console.log("parse", resultJson?.data);
    // console.log("datajson: ", dataJson);
    try {
      const route = `parties`;
      const method = "GET";

      const data = await makeRequest({ route, method });
      if (!data) {
        navigate("/404");
      }
      if (data) {
        //const result = data.parties;
        //setParties(result);
        //console.log('parties', result);
      }
    } catch (error) {}
    
  }



  useEffect(() => {
    

    partyId && lang && fetchParty();
  }, [partyId, lang]);

  const [data, setData] = useState([]);
  const { partySlug } = useParams();
  useEffect(() => {
    if (lang) {
      
      axios
        .get(`${baseUrl}profiles/new/1&lang=${lang}`)
        .then((response) => setData(response.data.profiles));
      // axios.get(`${baseUrl}parties/0?lang=${lang}`)
      //   .then((response) => setData(response.data.profiles));
    }
  }, [lang, city.id]);

  
  

  const selectedModelsCount = useSelector(getSelectedCount);

  // const {
  //   id = onePartyData?.id,
  //   name = onePartyData?.name,
  //   description = onePartyData?.description,
  //   includes = onePartyData?.includes,
  //   agency = onePartyData?.agency,
  //   date = onePartyData?.date,
  //   start_time = onePartyData?.start_time,
  //   end_time = onePartyData?.end_time,
  //   address = onePartyData?.address,
  //   coordinates = onePartyData?.coordinates,
  //   price = onePartyData?.price,
  //   prepayment = onePartyData?.prepayment,
  //   profiles = onePartyData?.profiles,
  // } = props;


  //mocked data


  const tabsBtns = [
    {
      title: "Фото",
      icon: "image",
      link: "photo",
    },

    {
      title: "Видео",
      icon: "film",
      link: "video",
    },
  ];

  




  return (
    <>

      <main className={"agency-party"}>
        <Breadcrumbs data={breadcrumbsList} clazz={"__container mb-20"} />

        {/* Parties page Header for customer ? */}

        {/* <div className="party-page__header">
          <h1>{t('myparty')}</h1>
          <Button
            size={"l"}
            clazz={"button-l button--green"}
            onClick={() =>{}}
          >
            <Icon size={"l"} spritePath={"plus-1"} />
            {t("addparty")}
          </Button>
        </div> */}

        {/* mock props */}
        <Banner props={{
          title: name,
          img: cover,
          logoImg: cover,
          date: date,
          model_total: models_count,
        }} />

        <section className="agency__top __container d-flex flex-wrap-no">
        {!isJsdom ? 
          (<>
            {media.interior?.length > 0 ?
              (<>
                <section className="agency__media__interior __container">
                  <h3 className="mt-8">
                    <Icon size={"s"} spritePath={tabsBtns[1].icon}/>
                    {` ${tabsBtns[1].title}`}
                  </h3>
                  <SwiperSlide
                      className={`agency-tabs__slide mt-16`}
                      data-hash={tabsBtns[1].link}
                  >
                          {media.interior.slice(0, 6).map((image, index) => {
                              const result = () => {
                                  dispatch(setGalleryMediaIndex(index));
                                  dispatch(setGalleryStatus(true));

                          };
                          return (
                              <img
                                  src={image}
                                  onClick={() => result()}
                                  alt=""
                              />
                          );
                          })}
                  </SwiperSlide>
                </section>
              </>) : null
            }
          </>) : null}
          <div className="agency-party__description">

            {description ? (
              <ShowMoreText clazz="mb-24">{description}</ShowMoreText>
            ) : null}

            <h2 className="mb-24 mb-16-mob">{t("dateandplace")}</h2>
            <div className="d-flex flex-wrap gap-24 w-100 mb-32 mb-24-mob">
              {address ? (
                <a
                  href="#"
                  className="model-header__address underline"
                  title={t('viewonmap')}
                >
                  <i className="icon icon-m icon--map"></i>
                  {address}
                </a>
              ) : null}

              {date || (start_time && end_time) ? (
                <a
                  href="#"
                  className="model-header__address underline"
                  title="Добавить в календарь"
                >
                  {date}, {t("from")} {start_time} {t("until")} {end_time}
                  <i className="icon icon-m icon--calendar"></i>
                </a>
              ) : null}
            </div>


            <div className="d-flex gap-12 w-100 justify-center mb-32 mb-24-mob">
              <div className="start-videochat__box w-100">
                  <div>
                    <p className="p2">{t("price")}</p>
                    <span className="title_h3">
                      {price && !(price === 0) ? <span className="currency--erocoin">{price}</span> : t('no')}
                    </span>
                  </div>
              </div>
              <div className="start-videochat__box w-100">
                
                  <div>
                    <p className="p2">{t("prepayment")}</p>
                    <span className="title_h3">
                      {prepayment && !(prepayment === 0) ? <span className="currency--erocoin">{prepayment}</span> : t('no')}
                    </span>
                  </div>
                
              </div>
            </div>

            <button
              data-open-popup="takePart"
              type="button"
              className="button button-l button--green justify-center w-100 mb-32 mb-24-mob"
            >
              {t("signmeup")}
            </button>

            <h2 className="mb-24 mb-16-mob">{t("contacts")}</h2>

            {'phone' || 'whatsapp' || 'telegram' ?
              <div className="agency__buttons">
                  {'phone' ?
                    <ButtonLink size={'l'} href="tel:+422589996655" clazz="button--primary td-none w-100">
                        {'phone'}
                    </ButtonLink>
                    : null}
                  {'whatsapp' ?
                    <ButtonLink square={true} size={'l'} href={`https://wa.me/${'whatsapp'}`} clazz="button--primary">
                        <i className="icon icon-xl icon--whatsapp"></i>
                    </ButtonLink>
                    : null}

                  {'telegram' ?
                    <ButtonLink square={true} size={'l'} href={`https://t.me/${'telegram'}`} clazz="button--primary">
                        <i className="icon icon-xl icon--telegram"></i>
                    </ButtonLink>
                    : null}
              </div>
              : null}
          </div>
        </section>

        <section className="agency-party__list __container">

          <h2>{t('priceincludes')}</h2>
          <ul className="model__serfices-box p1 color-main">
          {includes?.map((option)=>{
             return (
              <li>
                <h3 className="mt-0 mb-0">{option.title}</h3>
                <p className="color-600">{option.description}</p> 
              </li>
              )
          })}
          </ul>
        </section>

        <section className="agency-party__models __container">
          <h3>{models_count ?? 0} {t('modelsparticipate')}</h3>
        </section>

        {/* slider for models */}
        {/* {profiles.length ? 
          <Navigation 
            profiles_statistic={profiles_statistic} 
            agencyId={agencyId} 
            checkbox={false} 
            mainPage={false}
            setIsDataEmpty={setIsDataEmpty} 
            isDataEmpty={isDataEmpty} 
            /> : null} */}
        <div className="__container">
          <PartiesWrapper parties={parties} />
        </div>
      </main>


      {/* {!!girls_slider_db.length && (
        <StoriesComponent
          // stories={stories}
          stories={girls_slider_db.slice(0, 8)}
          descriptionText={true}
          favoriteButton={true}
        />
      )} */}

      {/* <Gallery id={'Interior'} data={interior_db}/> */}
    </>
  );
};

export default PartyPage;
