import Footer from "../../footer/Footer";
import Header from "../../header/Header";
import Helmet from "react-helmet";
import {Outlet, useLocation} from "react-router-dom";
import { Favicon, Loader } from "@/components/ui";
import { useSelector } from "react-redux";
import { getMapStatus } from "@/stores/slices/popupSlice";
import MapFilter from "../../mapFilter/MapFilter";
import {useEffect, useState} from "react";
import useLadyService from "@/services/LadyService";
import { fetchRanges } from "@/stores/slices/filterSlice";
import {makeRequest} from "@/services/makeRequest";
import {
  setActionsCountStatus,
  setAddressStatus,
  setAgency,
  setBlockedCountriesList,
  setCitiesListStatus,
  setCityIdStatus,
  setNotifyPartiesStatus,
  setCityStatus,
  setEmailStatus,
  setEmailVerifiedAtStatus,
  setFiltersCountStatus,
  setLatitudeStatus,
  setLongitudeStatus,
  setNotifyEmailStatus,
  setNotifyTelegramStatus,
  setNotifyWhatsappStatus,
  setPhoneStatus,
  setProfilesCountStatus,
  setProfileStatus,
  setSalonStatus,
  setSettingsOperatorStatus,
  setTelegramStatus,
  setWhatsappStatus,
  setPreviewPrice,
  setPreviewStatus,
} from "../../../stores/slices/userProfileSlice";
import {setUserInfoAlreadyExist} from "@/stores/slices/userSlice";
import userProfileService from "@/services/UserProfileService";
import {setElitePrice, setUserInfo} from "../../../stores/slices/userSlice";

const LayoutLk = ({ titleParams, postDesc }) => {
  const mapPopupStatus = useSelector(getMapStatus);
  const {
    dispatch,
    userCity,
    userFilterStatus,
    lang,
    userTypeIndi,
    token
  } = useLadyService();

  const { settignsOperator } = userProfileService();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if(userTypeIndi){
      if (userCity.id && !userFilterStatus) {
        dispatch(fetchRanges(userCity.id, lang));
      }
    }
  }, [userCity.id, userFilterStatus]);

  useEffect(() => {
    const getInfo = async () => {
      try {
        setIsLoading(true);

        const data = await makeRequest({route: `user/info`, method: "GET"});

        if (data) {
          const result = data.data;
          if(result.settings){
            dispatch(setSettingsOperatorStatus(result?.settings))
          }
          if (result.phone) {
            dispatch(setUserInfo({stateName: "phone", value: result.phone}));
          }
          if (result.email) {
            dispatch(setUserInfo({stateName: "email", value: result.email}));
          }
          await dispatch(setProfileStatus(result.profiles_status))

          await dispatch(setFiltersCountStatus(result.filters_count))
          await dispatch(setProfilesCountStatus(result.profiles_count))
          await dispatch(setActionsCountStatus(result.actions_count))

          await dispatch(setNotifyEmailStatus(result.notify_email))
          await dispatch(setNotifyTelegramStatus(result.notify_telegram))
          await dispatch(setNotifyWhatsappStatus(result.notify_whatsapp))

          await dispatch(setTelegramStatus(result.telegram))
          await dispatch(setWhatsappStatus(result.whatsapp))
          await dispatch(setEmailStatus(result.email))
          await dispatch(setEmailVerifiedAtStatus(result.email_verified_at))
          await dispatch(setPhoneStatus(result.phone))
          await dispatch(setSalonStatus(result.salon))

          await dispatch(setLatitudeStatus(result.latitude));
          await dispatch(setLongitudeStatus(result.longitude));
          await dispatch(setCityStatus(result.city));
          await dispatch(setCityIdStatus(result.city_id));
          await dispatch(setNotifyPartiesStatus(result.new_parties_notify));
          await  dispatch(
              setElitePrice({
                oldPrice: result.old_elite_price,
                currentPrice: result.elite_price,
              })
          );
          if(result.agency) {
            await dispatch(setAgency(result.agency.data))
            await dispatch(setAddressStatus(result.agency.data.address))
            await dispatch(setPreviewStatus(result.agency.data.settings.preview_status))
            await dispatch(setPreviewPrice(result.agency.data.settings.preview_price))
          } else {
            await dispatch(setAddressStatus(result.address))
          }
          await dispatch(setBlockedCountriesList(result.blocked_countries))
          await dispatch(setUserInfoAlreadyExist());
        }
      } catch (error) {
        console.log(error)
      }
    };

    const citiesData = async () => {
      try {
        const { cities } = await makeRequest({
          route: `services/all`,
          method: "GET",
          payload: { lang },
        });
        if (cities) {
          await dispatch(setCitiesListStatus(cities));
        }
      } catch (error) {}
    };

    if (token) {
      getInfo()
        .then(citiesData)
        .finally(() => setIsLoading(false));
    }
  }, [token]);


  return (
    <>
      <Favicon />
      <Helmet>
        <title>{titleParams}</title>
        <meta name="description" content={postDesc} />
      </Helmet>

      <Header isLk/>

      {isLoading ? <Loader height={100}/> : <Outlet />}

      <Footer />

      {(!!mapPopupStatus && userTypeIndi) && <MapFilter />}
    </>
  );
};

export default LayoutLk;
