import React from 'react';
import {createRoot, hydrateRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {store} from './stores/store';
import {BrowserRouter} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {Suspense} from 'react';
import {Loader} from './components/ui';
import './styles/index.scss';
import App from './components/app/App';
import './styles/inlineStyles.scss';
import 'react-toastify/dist/ReactToastify.css';
import i18n from './helper/i18n';
import {I18nextProvider} from 'react-i18next';
import withErrorBoundary from "./components/errorBoundary/withErrorBoundary";
const rootElement = document.getElementById('root');
const SafeApp = withErrorBoundary(App);

if (rootElement) {
    const AppElement = (
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <Suspense fallback={<Loader height={100}/>}>
                    <BrowserRouter>
                        <ToastContainer/>
                        <SafeApp/>
                    </BrowserRouter>
                </Suspense>
            </I18nextProvider>
        </Provider>
    );

    if (typeof window !== 'undefined' && window.navigator.userAgent.includes('jsdom')) {
        hydrateRoot(rootElement, AppElement);
    } else {
        const root = createRoot(rootElement);
        root.render(AppElement);
    }
}
