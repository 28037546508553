import {Tooltip} from "react-tippy";

import './tippy.scss'

const Tippy = (props) => {
    const {
        title,
        children,
        followCursor,
        className,
        html,
        open,
        position = "bottom",
        quote = false,
        arrow = true
    } = props

    return (
        <Tooltip
            title={title}
            className={`tippy ${quote ? 'quote' : ""} ${className ? className: ''}`}
            position={position}
            arrow={arrow}
            sticky={true}
            trigger="mouseenter"
            followCursor={!!followCursor}
            html={html}
            open={open}
        >
            {children}&nbsp;
        </Tooltip>
    );
}
export default Tippy