import React, {useEffect, useState} from "react";
import {Button, ErrorMessage, Icon, Loader, SelectCountry, Checkbox, Tippy} from "@/components/ui";
import useLadyService from "@/services/LadyService";
import GeoInput from "@/components/ui/input/GeoInput";
import {convertData} from "@/helper/convertData";
import {Map} from "../../createProfile/pageComponent";
import showToast from "../../../../toast/Toast";
import {makeRequest} from "@/services/makeRequest";
import {useSelector} from "react-redux";
import {getAddressStatus} from "@/stores/slices/userProfileSlice";
import useHandleLocationClick from "../../../../../services/HandleLocationClick";

const AddressRow = ({
                        onChange,
                        cities = [],
                        isActive,
                        formCity,
                        setFormCity,
                        selectedCity,
                        setSelectedCity,
                        currentActive,
                        setCurrentActive,
                        setBounds,
                        bounds,
                        activeCountry,
                        setActiveCountry,
                        setIsActive,
                        setMapMarker
                    }) => {
    const {t, userTypeAgency} = useLadyService();

    const userAddress = useSelector(getAddressStatus)

    const currentCity = cities[activeCountry]?.cities?.find((city) => city?.name === selectedCity)
    const [currentAddress, setCurrentAddress] = useState(userAddress?.length > 0 ? {...userAddress} : [{
        address: "",
        city: currentCity?.name || '',
        city_id: currentCity?.id
    }])
    const [primaryAddress, setPrimaryAddress] = useState({})
    const [cityBounds, setCityBounds] = useState(null)
    const [primaryIndex, setPrimaryIndex] = useState(0)
    useEffect(() => {
        if (currentAddress.length <= 1) {
            setPrimaryAddress({...currentAddress[0]})
        } else if (currentAddress.length) {
            const index = currentAddress.findIndex((item) => item.primary)
            setPrimaryAddress({...currentAddress[index]})
            setPrimaryIndex(index)
        }
    }, [currentAddress])
    useEffect(() => {
        if (userAddress.length) {
            setCurrentAddress([...userAddress])
        }
    }, [userAddress])
    useEffect(() => {
        setBounds({lat: primaryAddress.latitude, lng: primaryAddress.longitude})
    }, [primaryAddress])
    useEffect(() => {
        if (!Array.isArray(currentAddress)) {
            console.error('currentAddress is not an array', currentAddress);
            return;
        }

        const updatedAddress = currentAddress.map((item, index) => ({
            ...item,
            primary: index === primaryIndex,
        }));

        setCurrentAddress(updatedAddress);
        setPrimaryAddress(updatedAddress[primaryIndex]);
        setIsActive(true)
    }, [primaryIndex]);


    const citiesOptions = convertData(cities);
    const [cityError, setCityError] = useState("");
    const [geoError, setGeoError] = useState(false);

    const handleChangeActive = (e) => {
        e.preventDefault();
        setIsActive(true);
    };
    const [isAddressActive, setIsAddressActive] = useState(false);
    const throwError = (e) => {
        if (
            !e.target.classList.contains("icon--location") &&
            !e.target.classList.contains("button--tetriary")
        ) {
            if (!selectedCity) {
                setGeoError(true);

                setTimeout(() => {
                    setGeoError(false);
                }, 2000);
            }
        }
    };

    const handleSubmit = () => {
        const fetchData = async () => {
            try {
                const route = `user`;
                const method = "PUT";
                let payload = {
                    address: currentAddress[0].address,
                    city_id: currentAddress[0].city_id,
                    longitude: currentAddress[0].longitude,
                    latitude: currentAddress[0].latitude
                };
                if (userTypeAgency) {
                    payload = {addresses: {...currentAddress}}
                }

                const result = await makeRequest({route, method, payload});
                if (result) {
                    setIsActive(false);
                    showToast({
                        message: t("success"),
                        variant: "success",
                    });
                }
            } catch (error) {
                const res = error.response;

                // eslint-disable-next-line default-case
                switch (res.status) {
                    case 401: {
                        showToast({
                            message: t("noauth"),
                            variant: "error",
                        });
                        break;
                    }
                    case 422: {
                        showToast({
                            message: t("oops"),
                            variant: "error",
                        });
                        break;
                    }
                }
            }
        };

        fetchData();
    };
    const handleLocationClick = useHandleLocationClick();

    const onLocationClick = () => {
        handleLocationClick()
            .then((result) => {
                const latLng = new window.google.maps.LatLng(result.latitude, result.longitude);
                if (cityBounds && cityBounds.contains(latLng)) {
                    setBounds({lat: result.latitude, lng: result.longitude})
                    setAddress(`${result.city} ${result.road}, ${result.house_number}`);
                } else {
                    showToast({message: t("inuptadress", {City: primaryAddress.city}), variant: "error"})
                }
            })
            .catch(() => {
                showToast({
                    message: t("oops"),
                    variant: "error",
                });
            });
    };

    const handleChange = (e, id) => {
        const {name} = e;
        if (e === 'location-create') {
            setAddress(id)
        }
        onChange(id, name ? "city" : "address", name || e);
    };

    const changeCity = (index, city) => {
        let userAddress = [...currentAddress];
        userAddress[index] = {
            ...userAddress[index],
            city: city.name,
            city_id: city.id,
            address: "",
            latitude: "",
            longitude: "",
        };
        setCurrentAddress(userAddress);
    }
    const setAddress = async (newAddress) => {
        if (typeof newAddress === "string") {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({address: newAddress}, (results, status) => {
                if (status === "OK" && results[0]) {
                    newAddress = results[0];
                    let userAddress = [...currentAddress];
                    userAddress[primaryIndex] = {
                        ...userAddress[primaryIndex],
                        address: newAddress.formatted_address,
                        latitude: newAddress.geometry.location.lat(),
                        longitude: newAddress.geometry.location.lng(),
                    };
                    setCurrentAddress(userAddress);
                    setIsActive(true);
                }
            });
        } else {
            let userAddress = [...currentAddress];
            userAddress[primaryIndex] = {
                ...userAddress[primaryIndex],
                address: newAddress.formatted_address,
                latitude: newAddress.geometry.location.lat(),
                longitude: newAddress.geometry.location.lng(),
            };
            setCurrentAddress(userAddress);
            setIsActive(true);
        }
    };

    const removeAddress = (index) => {
        let userAddress = [...currentAddress.slice(0, index), ...currentAddress.slice(index + 1)];
        setCurrentAddress(userAddress);
        setIsActive(true);

    }
    const addAddress = () => {
        const mainAddress = currentAddress.find((address) => address?.primary)
        setCurrentAddress([...currentAddress, {
            address: "",
            city: mainAddress?.city,
            city_id: mainAddress?.city_id
        }]);
    }
    return (
        <>
            {userTypeAgency && (
                <h3 className={'m-0'}>{t("youraddress")}</h3>

            )}

            <div className={`info-address`}>
                {currentAddress.length && currentAddress.map((item, index) => {
                    return (
                        <div key={index} className="info-address-container">
                            <div className="info-container__top">


                                <SelectCountry
                                    data={citiesOptions}
                                    name={"create-city"}
                                    clazz={`${cityError ? "_error" : ""} size-l-forever justify-start select`}
                                    clazzSvg={`select__flag ${formCity?.icon || ""}`}
                                    arrowSize={"m"}
                                    type={"flag"}
                                    placeholder={t("yourcity")}
                                    defaultTitle={item.city}
                                    subTitle={t("yourcity")}
                                    withoutIcon={!item.city}
                                    setValue={setSelectedCity}
                                    currentActiveIndex={currentActive}
                                    setActiveCountry={setActiveCountry}
                                    activeCountry={activeCountry}
                                    setActive={setCurrentActive}
                                    error={cityError}
                                    errorMessage={<ErrorMessage message={cityError}/>}
                                    onChange={(e) => {
                                        changeCity(index, e)
                                    }}
                                    setError={setCityError}
                                    setIsAddressActive={setIsAddressActive}
                                />
                                {userTypeAgency && (
                                    <Checkbox
                                        checked={primaryIndex === index}
                                        id={index}
                                        title={t("main")}
                                        onClick={() => {
                                            setPrimaryIndex(index);
                                        }}
                                    />
                                )}
                            </div>

                            <div className="info-container">
                                <div className={`search filter__map-input ${
                                    item.city ? "" : "geo-error"
                                }`}
                                     onClick={(e) => {
                                         if (isActive) {
                                             throwError(e);
                                         }
                                     }}
                                >
                                    <span className={"filter__map-title"}>{t("youraddress")}</span>

                                    <label
                                        htmlFor="geosuggest__input-create"
                                        className="search__label header__search"
                                    >
                                        <GeoInput
                                            value={item.address}
                                            setValue={handleChange}
                                            name={"location-create"}
                                            id="geosuggest__input-create"
                                            clazz={`header__search-input ${geoError ? "_error" : ""}`}
                                            placeholder={t("fulladdress")}
                                            bounds={bounds}
                                            isAgency={true}
                                        />

                                        {geoError && <ErrorMessage message={t("cityfirst")}/>}
                                    </label>
                                </div>

                                <Button
                                    title={t("chekmyplace")}
                                    onClick={onLocationClick}
                                    name={"location"}
                                    square={true}
                                    size={"l"}
                                    clazz={isAddressActive ? "button--tetriary bg-color-500" : "button--tetriary"}
                                >
                                    <Icon size={"xl"} spritePath={"location"}/>
                                </Button>

                                {userTypeAgency &&
                                    primaryIndex !== index &&
                                    <button
                                        type={'button'}
                                        className={`tarifs-row-trash icon icon-xl icon--trash _red`}
                                        onClick={() => removeAddress(index)}
                                    />}
                            </div>
                        </div>
                    )
                })}

                <div className="d-flex justify-sb align-center">
                    {userTypeAgency &&
                        <Button
                            size={"s"}
                            clazz={"button_outline--green-accent justify-center float-right"}
                            onClick={addAddress}
                        >
                            <Icon spritePath={"plus"} size={"s"}/>

                            {t("addad")}
                        </Button>}

                    <Button
                        size={"s"}
                        onClick={!isActive ? () => handleChangeActive : () => handleSubmit()}
                        disabled={!isActive}
                        clazz={"button_outline--green-accent w-160 ml-auto"}
                    >
                        {t("save")}
                    </Button>
                </div>


                {(primaryAddress && cities[activeCountry]?.name) ? (
                    <Map
                        setMarkerActive={setIsAddressActive}
                        markerActive={isAddressActive}
                        region={cities[activeCountry].name}
                        city={primaryAddress.city}
                        setBounds={setBounds}
                        cityBounds={cityBounds}
                        setCityBounds={setCityBounds}
                        coords={{lat: primaryAddress.latitude, lng: primaryAddress.longitude}}
                        setAddress={setAddress}
                    />
                ) : <Loader height={300} decimal={'px'}/>}
            </div>
        </>
    )
};

export default AddressRow