import useLadyService from "../../../services/LadyService";
import { useEffect, useState } from "react";

import { privacyPolicyEn } from "./locales/en";
import { privacyPolicyRu } from "./locales/ru";
import { privacyPolicyCz } from "./locales/cz";
import { Helmet } from "react-helmet";
import {userDeclarationEN} from "../userDeclaration/locales/en";
import {userDeclarationCZ} from "../userDeclaration/locales/cz";
import {userDeclarationRU} from "../userDeclaration/locales/ru";

const PrivacyPolicy = () => {
  const { lang, t } = useLadyService();


  const defaultTranslate = !lang || lang === 'en' ? privacyPolicyEn : lang === 'cz' ? privacyPolicyCz : lang === 'ru' ? privacyPolicyRu : privacyPolicyEn
  const [translate, setTranslate] = useState(defaultTranslate);

  useEffect(() => {
    switch (lang) {
      case "ru":
        return setTranslate(privacyPolicyRu);

      case "cz":
        return setTranslate(privacyPolicyCz);

      default:
        return setTranslate(privacyPolicyEn);
    }
  }, [lang]);

  return (
    <>
      <Helmet>
        <title>{t("privacypolicytitle")}</title>
        <meta
          name="description"
          content={t("privacypolicydesc")}
        />
      </Helmet>
      <main>
        <section className="other-page__container">
          <h1>{translate.h1}</h1>

          <p>{translate.p1}</p>
          <p>{translate.p2}</p>
          <p>{translate.p3}</p>
          <p>{translate.p4}</p>
          <p>{translate.p5}</p>
          <p>{translate.p6}</p>
          <p>{translate.p7}</p>

          <h2>1. {translate.one.title}</h2>
          <p>1.1. {translate.one.p1}</p>
          <p>1.2. {translate.one.p2}</p>

          <h2>2. {translate.two.title}</h2>
          <p>2.1. {translate.two.p1}</p>
          <p>2.2. {translate.two.p2}</p>
          <p>2.3. {translate.two.p3}</p>

          <h2>3. {translate.three.title}</h2>
          <p>3.1. {translate.three.p1}</p>
          <ul>
            {translate.three.list.map((i) => (
              <li>{i}</li>
            ))}
          </ul>
          <p>3.2. {translate.three.p2}</p>

          <h2>4. {translate.four.title}</h2>
          <p>4.1. {translate.four.p1}</p>
          <p>4.2. {translate.four.p2}</p>
          <p>4.3. {translate.four.p3}</p>

          <h2>5. {translate.five.title}</h2>
          <p>5.1. {translate.five.p1}</p>
          <p>5.2. {translate.five.p2}</p>

          <h2>6. {translate.six.title}</h2>
          <p>6.1. {translate.six.p1}</p>
          <p>6.2. {translate.six.p2}</p>
          <ul>
            {translate.six.list.map((i) => (
              <li>{i}</li>
            ))}
          </ul>
          <p>6.3. {translate.six.p3}</p>
          <p>6.4. {translate.six.p4}</p>

          <h2>7. {translate.seven.title}</h2>
          <p>7.1. {translate.seven.p1}</p>
          <p> {translate.seven.p2}</p>
          <ul>
            {translate.seven.list.map((i) => (
              <li>{i}</li>
            ))}
          </ul>

          <h2>8. {translate.eight.title}</h2>
          <p>8.1. {translate.eight.p1}</p>
          <p>8.2. {translate.eight.p2}</p>

          <h2>8. {translate.nine.title}</h2>
          <p>8.1. {translate.nine.p1}</p>
          <p>8.2. {translate.nine.p2}</p>
        </section>
      </main>
    </>
  );
};

export default PrivacyPolicy;
