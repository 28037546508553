import {useState, useEffect} from "react";


import {ResContainer} from "../reservations/pageComponents";
import {makeRequest} from "@/services/makeRequest";

import useLadyService from "@/services/LadyService";
import Pagination from "../../pagination/Pagination";
import {useSelector} from "react-redux";
import {CustomSkeleton} from "../../ui";
import {useLocation, useNavigate} from "react-router-dom";
import useCheckAccess from "../../../hooks/useCheckAccess";
import {getUserPreviewsBlock} from "../../../stores/slices/userSlice";
import { TogglePreview } from "@/components/pages/accounts/components";


const skeletons = [...Array(12)].map((_, index) => {
  return <CustomSkeleton key={index} height={150}/>;
});

const PreviewsPage = () => {
  const {token, lang, t} = useLadyService();
  const navigate = useNavigate();

  const {hasAccess, AccessTitle} = useCheckAccess('  gotopreviews')

  const [data, setData] = useState([]);

  const {hash} = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  const [page, setPage] = useState(1);

  const newPreviewsBlock = useSelector(getUserPreviewsBlock);

  const [pagesList, setPagesList] = useState([]);

  useEffect(() => {
    if (!!data.length) {
      setData((prev) => {
        return [newPreviewsBlock.data, ...prev];
      });
    }
  }, [newPreviewsBlock]);

  const handlePage = (e, value) => {
    e.preventDefault();
    navigate(`/${lang}/lk/previews#${value}`);
    setPage(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const {previews, meta} = await makeRequest({
          route: `previews`,
          method: "GET",
          payload: {
            limit: 12,
            page,
          },
        });

        if (!!previews.length) {
          const {per_page, total} = meta;
          const result = Math.ceil(total / per_page);
          const arrayRes = Array.from({length: result}, (_, i) => i + 1);
          setPagesList(arrayRes);


          setData(previews);
          setIsLoading(false);
        }
        else{
          setIsLoading(false);
        }

        setIsLoading(false);
      } catch (error) {
        
      }
    };

    page !== 0 && token && fetchData();
  }, [token, page]);

  useEffect(() => {
    let str = hash;
    str = str.replace("#", "");
    setPage(Number(str) === 0 ? 1 : Number(str));
  }, [hash]);

  return !hasAccess ? AccessTitle : (
    <main>
      <section className={"container"}>
        <h1>{t("demos")}</h1>

        <TogglePreview />

        <div className="container res__grid">

          {isLoading ? skeletons
            : !!data?.length
              ? data?.map(i => <ResContainer key={i.id} data={i} setData={setData}/>)
              : <h3 className="text-center mt-12">{t("emptyrecord")}</h3>
          }

        </div>



        {pagesList.length > 1 && (
          <Pagination
            pages={pagesList}
            activePage={page}
            onPageChange={handlePage}
          />
        )}
      </section>
    </main>
  );
};

export default PreviewsPage;
