import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {useEffect} from "react";
import useLadyService from "../../services/LadyService";
import {Button} from "./index";

const ErrorHandler = () => {
    const { t } = useTranslation("translation");

    return (
        <div className="error-page__container text-center">
            <h1>{t("somethingwrong")}</h1>
            <div className={"d-flex"}>
                <Button clazz="button_outline--green-accent justify-center ml-auto" size="s" onClick={() => {window.location.reload()}}>{t("reload")}</Button>
                <Button clazz="button_outline--green-accent justify-center ml-6" size="s"  onClick={() => {window.location.href = '/'}}>{t("backtomain")}</Button>
            </div>
        </div>
    );
};

export default ErrorHandler;
