import React from "react";
import {Bar} from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    Tooltip,
    Legend,
    Title,
} from "chart.js";
import useLadyService from "../../../../../services/LadyService";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    Tooltip,
    Legend,
    Title
);

const IncomeExpensesChart = ({profileFilter, labels, incomeData, expenseData, statistic}) => {
    const {t} = useLadyService();

    const toTimestampGMT = (dateString) => {
        const [year, month, day] = dateString.split('.');
        const date = new Date(Date.UTC(year, month - 1, day)); // Используем UTC
        return Math.floor(date.getTime() / 1000);
    };

    const processPositions = (data) => {
        const datasetsMap = {position: []};

        labels.forEach((date) => {
            const unix = toTimestampGMT(date);
            const key = String(unix);

            datasetsMap.position.push(Number(data[key]?.position || 0));
        });

        return {datasetsMap};
    };

    const {datasetsMap} = processPositions(statistic);

    const positionData =  {
        label: t('position'),
        data: profileFilter ? datasetsMap.position : [],
        type: 'line',
        borderColor: '#165faf',
        borderWidth: 2,
        fill: false,
    };

    const data = {
        labels: labels,
        datasets: [
            positionData,
            {
                label: t('income'),
                data: incomeData,
                backgroundColor: "rgba(40, 182, 114, 1)",
            },
            {
                label: t('expenses'),
                data: expenseData,
                backgroundColor: "rgba(216, 53, 73, 1)",
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: `${t('income')} ${t('and')} ${t('expenses')}`,
            },
        },
        scales: {
            y: {
                beginAtZero: false,
                title: {
                    display: true,
                    text: t('sum'),
                },
                suggestedMin: Math.min(...expenseData),
                suggestedMax: Math.max(...incomeData),
            },
        },
    };

    return <Bar data={data} options={options}/>;
};

export default IncomeExpensesChart;
