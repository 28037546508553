import "./upAd.scss";

import { Button, Icon } from "@/components/ui";
import { useState } from "react";

import {
  MakeEliteAdOnePopup,
  PromotionTablePopup,
  RemoveEliteAdPopup,
  RemoveTopAdPopup,
  ConnectPromotionAdPopup,
} from "@/components/popups";

import moment from "moment";
import useLadyService from "@/services/LadyService";


const UpAd = (props) => {
  const {
    status,
    current_position,
    elite_to,
    is_elite,
    city,
    slug,
    city_id,
    name,
    id,
    main_photo,
    setElite,
    position_price,
    promotion_to_at,
    dynamicTop = false,
    setData,
  } = props;

  const { t } = useLadyService();

  const [isOpenConnect, setIsOpenConnect] = useState(false);
  const [isOpenElite, setIsOpenElite] = useState(false);
  const [isRemoveElite, setIsRemoveElite] = useState(false);
  const [isRemoveTop, setIsRemoveTop] = useState(false);

  const [price, setPrice] = useState(position_price);
  
  const [position, setPosition] = useState(current_position || "");
  const [promotion, setPromotion] = useState(promotion_to_at);

  const priceRes = dynamicTop ? position_price : price;
  const positionRes = dynamicTop ? current_position || "" : position;
  const promotionRes = dynamicTop ? promotion_to_at : promotion;

  const zeroPosition = !priceRes && positionRes === "";

  const date = moment.unix(promotionRes).format("DD.MM.YYYY");

  const handleOpenConnect = () => {
    setIsOpenConnect(true);
  };

  const handleOpenRemoveElite = () => {
    setIsRemoveElite(true);
  };

  const handleOpenRemoveTop = () => {
    setIsRemoveTop(true);
  };

  const handleOpenElite = () => {
    setIsOpenElite(true);
  };

  const [isOpenUp, setIsOpenUp] = useState(false);
  const [selectPosition, setSelectPosition] = useState(null);
  const [selectPrice, setSelectPrice] = useState(null);
  const [placesData, setPlacesData] = useState([]);


  return (
    <>
      <div className={`upad ${!status ? "not_active" : ""}`}>
        {!!city && (
          <span className="title_h4 mb-0">
            {t("positionin", {City: ""})}{" "}
            <button
              onClick={handleOpenConnect}
              className={`upad__link--city${
                zeroPosition || !status ? " disabled" : ""
              }`}
              disabled={zeroPosition || !status}
            >
              {city}
              <Icon size={"s"} spritePath={"arrow-up-right"} clazz={"_green"} />
            </button>
          </span>
        )}
        <div className={`upad__updown__about${!status ? " disabled" : ""}`}>
          <div
            className={`upad__updown${
              zeroPosition || !status ? " disabled" : ""
            }`}
          >
            <div
              className={`upad__updown__calc${
                zeroPosition || !status ? " disabled upad not_active" : ""
              }`}
              onClick={!zeroPosition || status ? handleOpenConnect : null}
            >
              <Button
                clazz={`button--secondary`}
                square={true}
                size={"2xs"}
                disabled={zeroPosition || !status}
              >
                <Icon size={"xs"} spritePath={"chevrone-up"} />
              </Button>
              <input
                value={positionRes}
                type={"number"}
                className={"upad__input fz-18 color-main fw-500"}
                disabled={zeroPosition || !status}
              />
              <Button
                clazz={"button--secondary"}
                square={true}
                size={"2xs"}
                disabled={zeroPosition || !status}
              >
                <Icon size={"xs"} spritePath={"chevrone-down"} />
              </Button>
            </div>

            {zeroPosition ? null : (
              <>
                {!!priceRes ? (
                  <div
                    className={"upad__status__top"}
                    onClick={handleOpenConnect}
                  >
                    TOP
                  </div>
                ) : (
                  <Button
                    clazz={`button_outline--spec-green`}
                    size={"xs"}
                    onClick={handleOpenConnect}
                    disabled={!status}
                  >
                    {t("upto")} {t("intop")}
                  </Button>
                )}
              </>
            )}
          </div>
          <div className="vertical-line"></div>

          <div className="upad__updown">
            {is_elite ? (
              <div className={`upad__status__elite`}>{t("Status")} ELITE</div>
            ) : (
              <Button
                clazz={`button_outline--spec-yellow`}
                onClick={handleOpenElite}
                size={"xs"}
                disabled={!status}
              >
                {t("buyelite")} ELITE
              </Button>
            )}

            {!is_elite ? (
              <span className="mt-8 fw-500 fz-13 text-center color-main">
                +50% {t("toviews")}
              </span>
            ) : (
              <button
                className={`mt-8 upad__updown__deactivate`}
                onClick={handleOpenRemoveElite}
                disabled={!status}
              >
                {t("deactive")}
              </button>
            )}
          </div>
        </div>
        {!!priceRes && (
          <div className={`connectad__day${!status ? " disabled" : ""}`}>
            <span className="p2 color-main">{t("dailycharges")}</span>
            <span className="title_h3 mb-0 color-main text-center currency--erocoin">{`${priceRes}`}</span>
            <span className="p2">
              {t("active")} {t("until")}:{" "}
              {!!promotionRes ? date : t("indefinitely")}
            </span>

            <button
              className={`upad__updown__deactivate`}
              onClick={handleOpenRemoveTop}
              disabled={!status}
            >
              {t("deactive")}
            </button>
          </div>
        )}
      </div>

      {isRemoveTop &&
        <RemoveTopAdPopup
          open={isRemoveTop}
          setOpen={setIsRemoveTop}
          position={positionRes}
          slug={slug}
          setPosition={setPosition}
          setPromotion={setPromotion}
          setPrice={setPrice}
          dynamicTop={dynamicTop}
          city_id={city_id}
          setData={setData}
        />
      }

      {isRemoveElite &&
        <RemoveEliteAdPopup
          open={isRemoveElite}
          setOpen={setIsRemoveElite}
          setElite={setElite}
          elite_to={elite_to}
          slug={slug}
          name={name}
          id={id}
        />
      }

      {isOpenConnect && (
        <PromotionTablePopup
          open={isOpenConnect}
          setOpen={setIsOpenConnect}
          position={positionRes}
          city={city}
          slug={slug}
          setPrice={setPrice}
          setPosition={setPosition}
          setPromotion={setPromotion}
          name={name}
          positionPrice={priceRes}
          dynamicTop={dynamicTop}
          city_id={city_id}
          setData={setData}
          setIsOpenUp={setIsOpenUp}
          setSelectPrice={setSelectPrice}
          setSelectPosition={setSelectPosition}
          placesData={placesData}
          setPlacesData={setPlacesData}
        />
      )}

      {isOpenUp && (
        <ConnectPromotionAdPopup
          open={isOpenUp}
          setOpen={setIsOpenUp}
          position={selectPosition}
          price={selectPrice}
          currentPrice={placesData[position - 1]?.price || 0}
          data={placesData}
          currentPosition={position}
          slugProfile={slug}
          setPrice={setPrice}
          setPromotion={setPromotion}
          setPosition={setPosition}
          name={name}
          city_id={city_id}
          dynamicTop={dynamicTop}
          setProfilesData={setData}
      />
            )}

      {isOpenElite &&
        <MakeEliteAdOnePopup
          open={isOpenElite}
          setOpen={setIsOpenElite}
          setElite={setElite}
          id={id}
          main_photo={main_photo}
          name={name}
          slug={slug}
        />
      }
    </>
  );
};

export default UpAd;
