import { useTranslation } from "react-i18next";
import { Button, ButtonLink, Icon, Select, SwitchTheme } from "@/components/ui";
import { useSelector } from "react-redux";
import { hideAdult, getAdultState } from "@/stores/slices/popupSlice";
import React, { useState } from "react";
import useLadyService from "@/services/LadyService";

import "./adult.scss";
import { SelectCountry } from "../../ui";
import {
  getCountriesData,
  getUserCurrentCity,
} from "../../../stores/slices/userSlice";
import Popup from "../Popup";

const Adult = () => {
  const { dispatch, city, scrollToTop, lang } = useLadyService();

  const { t } = useTranslation("translation");

  const adultPopupState = useSelector(getAdultState);

  const languages = {
    main_title: "",
    options: [
      { id: 1, link: `/en/${city}`, title: "EN", value: "en" },
      { id: 2, link: `/ru/${city}`, title: "RU", value: "ru" },
      { id: 3, link: `/cz/${city}`, title: "CZ", value: "cz" },
    ],
  };

  const handleAdult = () => {
    dispatch(hideAdult(false));
    scrollToTop();
  };
  const countriesData = useSelector(getCountriesData);
  const [currentActive, setCurrentActive] = useState("");
  const currentCity = useSelector(getUserCurrentCity);

  return (
    <Popup
      open={!!adultPopupState}
      id={"adultPopup"}
      wrapperClazz="adult-popup adult"
    >
      <div className="adult-popup__top">
        <Icon size={""} spritePath={"18-popup"} clazz={"adult-popup__svg"} />
        <p className="p2 text-center">{t("popuptext18")}</p>

        <div className="adult-popup__inner">
          <h3 className={"adult-popup__title"}>{t("selecttheme")}</h3>
          <div className="adult-popup__actions">
            <SwitchTheme textLight={t("light")} textDark={t("dark")} />
            <Select
              options={languages.options}
              clazz={"button button-xs"}
              arrowSize={"s"}
              size={"xs"}
              sorted={true}
              name={"language"}
            ></Select>
          </div>
        </div>

        <p className="p2 text-center">{t("popupcookies")}</p>

        <span className={"adult-popup__city"}>
          {t("yourcity")}:
          <SelectCountry
            data={countriesData}
            name={"city-create-adult"}
            clazz={`button button-xs select-city`}
            arrowSize={"m"}
            type={"flag"}
            defaultTitle={currentCity.title}
            currentActiveIndex={currentActive}
            isMain={true}
            activeCountry={currentCity.icon}
            setActive={setCurrentActive}
          />
        </span>
      </div>

      <div className="adult-popup__bottom">
        <h3 className={"adult-popup__title title"}>{t("areyouolder")}</h3>
        <p className="p2 text-center">
          {t("iamolder")} <a href={`/provisions`} target="_blank">{t("provisions")}</a>
        </p>
        <div className="adult-popup__box">
          <Button
            size={"m"}
            clazz={"button--primary"}
            buttonType={"submit"}
            onClick={handleAdult}
          >
            {t("yes")}
          </Button>
          <ButtonLink
            href={"https://www.google.com"}
            size={"m"}
            clazz={"button--secondary"}
            buttonType={"submit"}
          >
            {t("no")}
          </ButtonLink>
        </div>
      </div>
    </Popup>
  );
};

export default Adult;
