import React, { useEffect, useRef, useState } from "react";
import "./video.scss";

const Video = (props) => {
  const {
    src,
    iframe = false,
    loop,
    clazz,
    playsInline,
    autoPlay,
    onClick,
    muted = false,
    poster,
    controls = false,
    title,
    playButton = true,
  } = props;

  const [play, setPlay] = useState(false);

  const videoRef = useRef(null);
  useEffect(() => {
    if (play) {
      videoRef.current.play();
    }
  }, [play]);

  return (
    <div
      title={title}
      onClick={onClick}
      className={`video${clazz ? ` ${clazz}` : ""}`}
    >
      {play ? null : playButton && !iframe ? (<button onClick={() => setPlay(true)} className="video-button"></button>) : null}
      {iframe ? (<iframe src={iframe} allowFullScreen/>) : (
      <video
        ref={videoRef}
        poster={poster}
        loop={loop}
        playsInline={playsInline}
        autoPlay={autoPlay}
        muted={muted}
        src={src}
        controls={play ? controls : null}
        controlsList={"nodownload"}
      />)}
    </div>
  );
};

export default Video;
